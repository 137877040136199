import React, { createContext, ReactNode, useState, useEffect } from 'react';
import amplitude from 'amplitude-js';
import config from '@utils/config';

interface AnalyticsContextType {
  initialized: boolean;
  logEvent: ({
    event,
    properties,
  }: {
    event: string;
    properties?: Record<string, string>;
  }) => void;
  addProperties: (properties: Record<string, string>) => void;
  identifyUser: ({ userId }: { userId: string }) => void;
}

export const AnalyticsContext = createContext<AnalyticsContextType>(null);

const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const [initialized, setInitialized] = useState(false);
  const [extraProperties, setExtraProperties] = useState({});
  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (!initialized) {
      amplitude.getInstance().init(String(config.amplitudeApiKey));
      setInitialized(true);
    }
  }, [initialized]);

  const logEvent = ({
    event,
    properties = {},
  }: {
    event: string;
    properties?: Record<string, string>;
  }) => {
    amplitude
      .getInstance()
      .logEvent(event, { ...extraProperties, ...properties });
  };
  const addProperties = (properties: Record<string, string>) => {
    setExtraProperties({ ...extraProperties, ...properties, userId });
  };
  const identifyUser = ({ userId }: { userId: string }) => {
    setUserId(userId);
    amplitude.getInstance().setUserId(userId);
  };

  return (
    <AnalyticsContext.Provider
      value={{ initialized, logEvent, addProperties, identifyUser }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
