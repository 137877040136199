import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';
import { NavigateOptions } from 'react-router-dom';

export enum StepType {
  'welcome' = 'welcome',
  'auth.unauthenticated' = 'auth.unauthenticated',
  'auth.challenged' = 'auth.challenged',
  'vsku.field' = 'vsku.field',
  'vsku.document' = 'vsku.document',
  'vsku.selection' = 'vsku.selection',
  'vsku.esignPrepare' = 'vsku.esignPrepare',
  'vsku.esignDocument' = 'vsku.esignDocument',
  'vsku.mobile' = 'vsku.mobile',
  'mobile.challenged' = 'mobile.challenged',
  'aboutYou' = 'aboutYou',
  'smsNotification' = 'smsNotification',
  'reviewAndSubmit' = 'reviewAndSubmit',
  'completed' = 'completed',
  // Only used for testing purposess
  'test' = 'test',
}

// These are the steps that are viable in the wizard without authentication.
// Loading any other step while not authenticated will result in an error page.
export const UNAUTHENTICATED_STEPS = [
  StepType.welcome,
  StepType['auth.unauthenticated'],
  StepType['auth.challenged'],
  StepType.test,
  StepType['vsku.mobile'],
  StepType['mobile.challenged'],
];

export enum PreventAction {
  yes = 'yes',
  no = 'no',
}

export type HeaderParams = {
  disableBackButton?: boolean;
  hidePercentComplete?: boolean;
  hideBackButton?: boolean;
  onBack?: () => Promise<PreventAction>;
  // The component property (if supplied) will override the entire header display
  component?: React.ReactElement;
};

export type FooterNextVariant = 'contained' | 'outlined' | 'text';

export type FooterParams = {
  disableNextButton?: boolean;
  hideNextButton?: boolean;
  displayLinks?: boolean;
  nextLoading?: boolean;
  nextVariant?: FooterNextVariant;
  nextLabel?: React.ReactElement;
  onNext?: () => Promise<PreventAction>;
  disclaimer?: React.ReactElement;
  // The component property (if supplied) will override the entire footer display
  component?: React.ReactElement;
};

export type StepData = {
  header?: HeaderParams;
  footer?: FooterParams;
  stepType: StepType;
  stepParams?: Record<string, any>;
  data?: Record<string, any>;
};

export type WizardState = {
  isInitialized: boolean;
  totalSteps: number;
  stepData: Record<string, StepData>;
  claims: Record<string, string>;
  stepKeys: string[];
  navigateStep: string | null;
  navigateOptions: NavigateOptions;
  parameters?: Record<string, string>;
};

const initialState: WizardState = {
  isInitialized: false,
  totalSteps: 0,
  stepData: {},
  claims: {},
  stepKeys: [],
  navigateStep: null,
  navigateOptions: undefined,
};

// This returns a 4 digit hexadecimal number from 0000 to ffff
// It should have sufficient randomness to minimize collision, but we will be checking
// for collisions anyway because they would cause a catastrophic issue in navigation
const generateStepKey = (values: string[]): string => {
  const val = Math.floor(Math.random() * (16 * 16 * 16 * 16))
    .toString(16)
    .padStart(4, '0');
  // In the unlikely event of a collision, try again
  // There is a tiny possibility of a stack overflow here, but it is so small that it is not worth worrying about
  // assuming a sane number of steps in the wizard.
  if (values.includes(val)) {
    return generateStepKey(values);
  }
  return val;
};

export const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    resetWizard: state => {
      state.isInitialized = false;
    },
    initializeWizard: (
      state,
      action: PayloadAction<{
        stepData: StepData[];
        parameters: Record<string, string>;
      }>
    ): void => {
      state.isInitialized = true;
      console.log('initializeWizard', action.payload.stepData);

      state.stepKeys = action.payload.stepData.reduce(
        (acc: string[]) => [...acc, generateStepKey(acc)],
        []
      );
      state.stepData = action.payload.stepData.reduce(
        (acc: Record<string, StepData>, step, idx) => {
          const stepKey = state.stepKeys[idx];
          console;
          acc[stepKey] = step;
          return acc;
        },
        {}
      );
      state.totalSteps = state.stepKeys.length;
      state.parameters = action.payload.parameters;
    },
    setStepHeader: (
      state,
      action: PayloadAction<{ stepKey: string; header: HeaderParams }>
    ): void => {
      const { stepKey, header } = action.payload;
      const stepData = state.stepData[stepKey];
      if (!stepData) {
        console.warn(
          'Warning: Attempting to set data for non-existent step',
          stepKey
        );
      } else {
        stepData.header = header;
      }
    },
    setStepFooter: (
      state,
      action: PayloadAction<{ stepKey: string; footer: FooterParams }>
    ): void => {
      const { stepKey, footer } = action.payload;
      const stepData = state.stepData[stepKey];
      if (!stepData) {
        console.warn(
          'Warning: Attempting to set data for non-existent step',
          stepKey
        );
      } else {
        stepData.footer = footer;
      }
    },
    // Not sure if this will be used, but might as build it for safety
    setStepParam: (
      state,
      action: PayloadAction<{
        stepKey: string;
        paramName: string;
        paramValue: any;
      }>
    ): void => {
      const { stepKey, paramName, paramValue } = action.payload;
      const stepData = state.stepData[stepKey];
      if (!stepData) {
        console.warn(
          'Warning: Attempting to set data for non-existent step',
          stepKey
        );
      } else {
        stepData.stepParams[paramName] = paramValue;
      }
    },
    setStepData: (
      state,
      action: PayloadAction<{ stepKey: string; data: Record<string, any> }>
    ): void => {
      const { stepKey, data } = action.payload;
      const stepData = state.stepData[stepKey];
      if (!stepData) {
        console.warn(
          'Warning: Attempting to set data for non-existent step',
          stepKey
        );
      } else {
        stepData.data = data;
        const newClaims = data?.inputValues || {};
        state.claims = { ...state.claims, ...newClaims };
      }
    },
    insertStep: (
      state,
      action: PayloadAction<{ stepIndex: number; stepData: StepData }>
    ): void => {
      const { stepIndex, stepData } = action.payload;

      const stepKey = generateStepKey(state.stepKeys);
      state.stepData[stepKey] = stepData;
      state.stepKeys.splice(stepIndex, 0, stepKey);
      state.totalSteps = state.stepKeys.length;
    },
    removeStep: (
      state,
      action: PayloadAction<{ stepIndex: number; gotoNext?: boolean }>
    ): void => {
      const { stepIndex, gotoNext } = action.payload;
      const stepKey = state.stepKeys[stepIndex];
      state.stepKeys.splice(stepIndex, 1);
      delete state.stepData[stepKey];
      state.totalSteps = state.stepKeys.length;
      if (gotoNext) {
        state.navigateStep = state.stepKeys[stepIndex];
        state.navigateOptions = { replace: true };
      }
    },
    gotoNextStep: (
      state,
      action: PayloadAction<{ stepKey: string; replace?: boolean }>
    ): void => {
      const { stepKey, replace } = action.payload;

      const stepIndex = state.stepKeys.indexOf(stepKey);
      console.log(
        'gotoNextStep',
        stepKey,
        stepIndex,
        state.stepKeys[stepIndex + 1]
      );
      state.navigateStep = state.stepKeys[stepIndex + 1];
      if (replace) {
        state.navigateOptions = { replace: true };
      }
    },
    clearNavigateStep: (state): void => {
      state.navigateStep = null;
      state.navigateOptions = undefined;
    },
  },
});

export const {
  resetWizard,
  initializeWizard,
  setStepHeader,
  setStepFooter,
  setStepParam,
  setStepData,
  insertStep,
  removeStep,
  gotoNextStep,
  clearNavigateStep,
} = wizardSlice.actions;

export const selectWizard = (state: RootState) => state.wizard;

export default wizardSlice.reducer;
