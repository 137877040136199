import React from 'react';
import { selectEndpoint } from '@features/endpoint/endpointSlice';
import { useAppSelector } from '@hooks/useAppSelector';

import { FormattedMessage } from 'react-intl';
import {
  Box,
  Button,
  Card,
  CardContent,
  Link,
  Typography,
} from '@mui/material';
import Mesh from '@shared/icons/Mesh';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import {
  selectWizard,
  setStepFooter,
  setStepHeader,
} from '@features/verification/VerificationWizard/wizardSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { ContentProps } from '@features/verification/VerificationWizard/components/Step';
import { styled } from '@mui/system';
import { VerificationPoweredBy } from '@shared/components';

const StyledIconWrapper = styled('div')(({ theme }) => ({
  '& svg': {
    borderRadius: '4rem',
    background: theme.palette.background.default,
    height: '2.5rem',
    width: '2.5rem',
    padding: '0.5rem',
  },
}));

const BULLET_POINTS = [
  {
    header: (
      <FormattedMessage
        description='Verify your identity bullet point header'
        defaultMessage='Verify your identity'
      />
    ),
    body: (
      <FormattedMessage
        description='Verify your identity bullet point body'
        defaultMessage='Proving your business identity with Mesh is quick and easy'
      />
    ),
    icon: (
      <StyledIconWrapper>
        <HowToRegOutlinedIcon color='primary' />
      </StyledIconWrapper>
    ),
  },
  {
    header: (
      <FormattedMessage
        description='Your data belongs to you bullet point header'
        defaultMessage='Your data belongs to you'
      />
    ),
    body: (
      <FormattedMessage
        description='Your data belongs to you bullet point body'
        defaultMessage="Mesh doesn't sell data to advertisers, and only shares it with your permission"
      />
    ),
    icon: (
      <StyledIconWrapper>
        <VisibilityOffOutlinedIcon color='primary' />
      </StyledIconWrapper>
    ),
  },
  {
    header: (
      <FormattedMessage
        description='Your data is secure bullet point header'
        defaultMessage='Your data is secure'
      />
    ),
    body: (
      <FormattedMessage
        description='Your data is secure bullet point body'
        defaultMessage='We secure your information with bank-level 256-bit encryption.'
      />
    ),
    icon: (
      <StyledIconWrapper>
        <HttpsOutlinedIcon color='primary' />
      </StyledIconWrapper>
    ),
  },
];

const WelcomeHeader = ({ customerLogo }: { customerLogo: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        pt: '1rem',
      }}
    >
      <img src={customerLogo} height={24} />
      <Box
        sx={{
          height: '24px',
          width: '86px',
          pl: '1rem',
          display: 'flex',
        }}
      >
        <Mesh />
      </Box>
    </Box>
  );
};

export const VerificationWelcome = ({ stepKey }: ContentProps) => {
  const endpointConfig = useAppSelector(selectEndpoint);
  const { customerLogo, customerName } = endpointConfig.customer;
  const dispatch = useAppDispatch();
  const { isInitialized } = useAppSelector(selectWizard);

  React.useEffect(() => {
    if (isInitialized) {
      dispatch(
        setStepHeader({
          stepKey,
          header: {
            component: <WelcomeHeader customerLogo={customerLogo} />,
          },
        })
      );
      dispatch(
        setStepFooter({
          stepKey,
          footer: {
            disclaimer: <WelcomeDisclaimer />,
          },
        })
      );
    }
  }, [isInitialized, customerLogo]);

  return <WelcomeBody customerName={customerName} />;
};

const WelcomeBody = ({ customerName }: { customerName: string }) => {
  return (
    <>
      <Typography variant='h4' textAlign='center' color='emphasis.main'>
        <FormattedMessage
          description='Welcome header'
          defaultMessage='{customerName} uses Mesh to verify and onboard its business partners.'
          values={{
            customerName,
          }}
        />
      </Typography>
      {BULLET_POINTS.map((bulletPoint, index) => (
        <Box
          key={`bullet-point-${index}`}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            pt: '1.5rem',
          }}
        >
          {bulletPoint.icon}
          <Box sx={{ display: 'flex', flexDirection: 'column', pl: '1rem' }}>
            <Typography variant='h5'>{bulletPoint.header}</Typography>
            <Typography variant='body1'>{bulletPoint.body}</Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};

const WelcomeDisclaimer = () => {
  return (
    <FormattedMessage
      description='Legal disclaimer for submitting form'
      defaultMessage="By continuing, I agree to being verified under Mesh's <termsOfUse>Terms of Use</termsOfUse> and <privacy>Privacy Statement</privacy>, and consent to Mesh contacting me with information relating to my verification status."
      values={{
        privacy: val => (
          <Link target='_blank' href='https://www.mesh.id/privacy-statement'>
            {val}
          </Link>
        ),
        termsOfUse: val => (
          <Link target='_blank' href='https://www.mesh.id/terms-of-service'>
            {val}
          </Link>
        ),
      }}
    />
  );
};

const Welcome = ({ acceptTerms }: { acceptTerms: () => void }) => {
  const endpointConfig = useAppSelector(selectEndpoint);
  const { customerLogo, customerName } = endpointConfig.customer;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Card
        sx={{
          backgroundColor: 'white',
          height: '100%',
          width: '100%',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 4,
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <WelcomeHeader customerLogo={customerLogo} />
            <WelcomeBody customerName={customerName} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant='caption'
              sx={{
                textAlign: 'center',
                color: 'text.secondary',
                pb: '0.5rem',
              }}
            >
              <WelcomeDisclaimer />
            </Typography>
            <Button
              variant='contained'
              color='emphasis'
              fullWidth
              onClick={acceptTerms}
              size='large'
            >
              <FormattedMessage
                description='Button text to accept the terms and conditions'
                defaultMessage='Next'
              />
            </Button>

            <VerificationPoweredBy />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Welcome;
