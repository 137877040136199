// This config is generated - please do not edit it in any way.
// Check scripts/generateConfig.js if you need to modify it in any way
interface Config {
  stage: string;
  cognitoConfig: {
    UserPoolId: string;
    ClientId: string;
  };
  appSync: {
    endpoint: string;
    apiKey: string;
  };
  userSnapApiKey: string;
  amplitudeApiKey: string;
  splitApiKey: string;
  dropboxClientId: string;
  smartyKey: string;
}
const config: Config = JSON.parse(
  '{"stage":"test","cognitoConfig":{"UserPoolId":"us-east-1_FzE7B2RCh","ClientId":"4maf4mtvn0v9lk0tqt4924e9rd"},"appSync":{"endpoint":"https://verify-ui-beta.mesh.id/graphql","apiKey":"da2-leojlqnr3nacpbso5zhv6aconi"},"userSnapApiKey":"0f1873d5-b9f3-49dc-8586-3fe71c78c6a1","amplitudeApiKey":"6fa24fe4f01b49d1e34187ee202974bf","splitApiKey":"b34blo97ebpscob1opdp84eos7ltje2p9h8h","dropboxClientId":"184647dd5b7dea3501a20bf83fcb54f7","smartyKey":"192662172828028239"}'
);
export default config;
