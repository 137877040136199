import config from './config';

interface NestedObject {
  [key: string]:
    | string[]
    | string
    | number
    | boolean
    | NestedObject
    | NestedObject[];
}
interface MakeGraphQlArgs {
  query: string;
  variables: Record<
    string,
    string | number | boolean | NestedObject | NestedObject[]
  >;
  accessToken?: string;
}

export const makeGraphQlCall = async ({
  query,
  variables,
  accessToken,
}: MakeGraphQlArgs) => {
  const headers = accessToken
    ? { authorization: accessToken }
    : { 'x-api-key': config.appSync.apiKey };
  const response = await fetch(config.appSync.endpoint, {
    method: 'post',
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  console.log('got response', response);
  if (response.status !== 200) {
    throw new Error(`GraphQL request failed with status ${response.status}`);
  }
  const json = await response.json();
  return json.data;
};
