import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../config/store';

export type DocumentState = {
  isUploading: boolean;
  documentId: string;
  fileName: string;
  errorMessage: string;
};

const initialState: DocumentState = {
  isUploading: false,
  documentId: '',
  fileName: '',
  errorMessage: '',
};

export type StartUploading = {
  fileName: string;
  document: File;
};

export type StartProcessingImages = {
  images: File[];
};

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    startUploadingDocument: (
      state,
      action: PayloadAction<StartUploading>
    ): void => {
      state.isUploading = true;
      state.fileName = action.payload.fileName;
      state.errorMessage = '';
      state.documentId = '';
    },
    finishUploadingDocument: (state, action: PayloadAction<string>): void => {
      state.isUploading = false;
      state.documentId = action.payload;
    },
    startProcessingImages: (
      state,
      action: PayloadAction<StartProcessingImages>
    ): void => {
      state.isUploading = true;
      state.errorMessage = '';
      state.documentId = '';
    },
    errorUploadingDocument: (state, action: PayloadAction<string>): void => {
      state.errorMessage = action.payload;
      state.isUploading = false;
    },
  },
});

export const {
  startUploadingDocument,
  finishUploadingDocument,
  startProcessingImages,
  errorUploadingDocument,
} = documentSlice.actions;

export const selectDocument = (state: RootState) => state.document;

export default documentSlice.reducer;
