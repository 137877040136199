import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../config/store';
import { VerificationOrderCreated } from './types';

export type CreateVerificationOrderState = {
  isLoading: boolean;
  verificationOrderCreated: boolean;
  errorMessage: string;
  verificationOrder?: VerificationOrderCreated;
  response?: string;
};

const initialState: CreateVerificationOrderState = {
  isLoading: false,
  verificationOrderCreated: false,
  errorMessage: '',
};

export type startCreateVerificationOrderAction = PayloadAction<{
  verificationOrder: VerificationOrderCreated;
}>;

export const verificationOrderCreatedSlice = createSlice({
  name: 'verificationOrderCreated',
  initialState,
  reducers: {
    resetVerificationOrder: state => {
      state.verificationOrderCreated = false;
      state.isLoading = false;
      state.errorMessage = '';
      state.response = '';
    },
    startCreateVerificationOrder: (
      state,
      action: startCreateVerificationOrderAction
    ): void => {
      state.isLoading = true;
      state.verificationOrder = action.payload.verificationOrder;
    },
    finishCreateVerificationOrder: (
      state,
      action: PayloadAction<string>
    ): void => {
      state.verificationOrderCreated = true;
      state.isLoading = false;
      state.response = action.payload;
    },
    errorCreateVerificationOrder: (
      state,
      action: PayloadAction<string>
    ): void => {
      state.verificationOrderCreated = false;
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  startCreateVerificationOrder,
  finishCreateVerificationOrder,
  errorCreateVerificationOrder,
  resetVerificationOrder,
} = verificationOrderCreatedSlice.actions;

export const selectVerificationOrder = (state: RootState) =>
  state.createVerificationOrder;

export default verificationOrderCreatedSlice.reducer;
