import { styled } from '@mui/material/styles';
import useWizard from '../hooks/useWizard';
import { Box, Button, Fade, Slide, Typography } from '@mui/material';
import { Mesh } from '@shared/icons';
import * as React from 'react';
import { BoxProps } from '@mui/material/Box/Box';
import { FormattedMessage } from 'react-intl';
import { VerificationPoweredBy } from '@shared/components';

const CustomButton = styled('button')(({ theme, disabled }) => ({
  width: 48,
  height: 48,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: disabled
    ? theme.palette.grey[300]
    : theme.palette.primary.main,
  color: 'white',
  border: 'none',
  cursor: disabled ? 'initial' : 'pointer',
}));

const Footer = (props: BoxProps) => {
  const { buttonsController } = useWizard();
  const showNext = !buttonsController.next.isHidden();
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...(props.sx ?? {}),
      }}
    >
      <Slide direction='up' in={showNext} timeout={200}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Fade in={showNext} timeout={500}>
            <Button
              variant='contained'
              color='emphasis'
              fullWidth
              size='large'
              onClick={buttonsController.next.onClick}
              disabled={buttonsController.next.isDisabled()}
            >
              <FormattedMessage
                description='Next button label for verification wizard'
                defaultMessage='Next'
              />
            </Button>
          </Fade>
        </Box>
      </Slide>
      <VerificationPoweredBy />
    </Box>
  );
};

export default Footer;
