import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { UsersnapProvider } from '@context/userSnapContext';
import { selectAuthentication } from '@features/verification/steps/auth/authSlice';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectEndpoint } from '@features/endpoint/endpointSlice';

const FeedbackLoader = () => {
  const [container, setContainer] = useState(null);
  const auth = useAppSelector(selectAuthentication);
  const endpoint = useAppSelector(selectEndpoint);
  useEffect(() => {
    console.log('setting container');
    setContainer(document.getElementById('feedbackContainer'));
  }, []);
  console.log('got container', container);
  if (!container) {
    return null;
  }
  const {
    endpointId,
    customer: { customerId, customerName },
  } = endpoint;
  return (
    <UsersnapProvider
      initParams={{
        mountNode: container,
        user: { email: auth.emailAddress },
        custom: {
          endpointId,
          customerId,
          customerName,
        },
      }}
    />
  );
};

const MeshFeedback = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div id='feedbackContainer' />
      <FeedbackLoader />
    </Box>
  );
};

export default MeshFeedback;
