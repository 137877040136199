import * as React from 'react';
import { Box, Button, Fade, Stack, Typography, useTheme } from '@mui/material';

import { FieldVSku, VSkuType } from '@features/endpoint/endpointSlice';

import VerifyInput from '@shared/components/VerifyInput/VerifyInput';
import type {
  EndpointInput,
  EndpointSchema,
  EndpointValidateResults,
} from '@utils/schema';
import { buildDynamicSchema } from '@utils/schema';
import { useWizard } from '@shared/components';
import { useEffect } from 'react';
import { AnalyticsContext } from '@context/analyticsContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContentProps } from '@features/verification/VerificationWizard/components/Step';
import { useAppDispatch } from '@hooks/useAppDispatch';
import {
  FooterNextVariant,
  PreventAction,
  gotoNextStep,
  insertStep,
  removeStep,
  selectWizard,
  setStepData,
  setStepFooter,
} from '@features/verification/VerificationWizard/wizardSlice';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@shared/components/Breadcrumbs';
import { useAppSelector } from '@hooks/useAppSelector';
import { InfoIcon } from '@shared/icons';
import { skipButtonLabel } from '@features/verification/VerificationWizard/components/Footer';
import { isFormSkippable } from '@utils/formUtils';

export const VerificationFields = ({
  stepKey,
  currentStepData,
  stepIndex,
}: ContentProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [yupSchema, setYupSchema] = React.useState<EndpointSchema>();
  const [formData, setFormData] = React.useState<Record<string, any>>({});
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const { logEvent } = React.useContext(AnalyticsContext);
  const navigate = useNavigate();
  const { claims, parameters } = useAppSelector(selectWizard);
  const { stepParams, data } = currentStepData ?? {};
  const vsku = stepParams?.vsku as FieldVSku;
  const { vspecId, duplicated, breadcrumbs } = stepParams ?? {};

  const validationResults = React.useMemo<EndpointValidateResults>(() => {
    return yupSchema && yupSchema.validate(formData);
  }, [yupSchema, formData]);

  const claimData = React.useMemo(
    () =>
      vsku.inputClaims
        .filter(({ options: { copyClaim } }) => copyClaim)
        .reduce(
          (acc, claim) => ({
            ...acc,
            [claim.fieldName]: claims[claim.options.copyClaim],
          }),
          {}
        ),
    [claims, vsku]
  );

  const paramsData = React.useMemo(
    () =>
      parameters
        ? vsku.inputClaims
            .filter(({ options: { copyParam } }) => copyParam)
            .reduce(
              (acc, claim) => ({
                ...acc,
                [claim.fieldName]: parameters[claim.options.copyParam],
              }),
              {}
            )
        : {},
    [parameters, vsku]
  );

  const handleSubmit = async () => {
    if (yupSchema) {
      const validationResults = yupSchema.validate(formData);
      if (validationResults.isValid) {
        dispatch(
          setStepData({
            stepKey,
            data: {
              vspecId,
              vsku: vsku.vsku,
              stepType: VSkuType.field,
              primaryInputs: vsku.primaryInputs,
              inputValues: {
                ...validationResults.values,
                ...claimData,
                ...paramsData,
              },
              jurisdiction: vsku.jurisdiction,
            },
          })
        );
        return PreventAction.no;
      } else {
        setErrors(validationResults.errorMap);
        return PreventAction.yes;
      }
    }
  };

  React.useEffect(() => {
    // TODO: https://app.asana.com/0/1206509552588324/1207513787112621
    const allowSkip = !!vsku.allowSkip;
    const isValid = validationResults?.isValid;
    const form = validationResults?.values ?? {};
    const enableSkipButton = isFormSkippable({ form, isValid, allowSkip });
    const skipButton = {
      nextVariant: 'outlined' as FooterNextVariant,
      nextLabel: skipButtonLabel,
    };
    const submitButton = {
      hideNextButton: !isValid && !allowSkip,
      onNext: handleSubmit,
    };
    const footer = enableSkipButton ? skipButton : submitButton;
    dispatch(
      setStepFooter({
        stepKey,
        footer,
      })
    );
  }, [vsku.allowSkip, validationResults]);

  React.useEffect(() => {
    const fetchSchema = async () => {
      const schema = await buildDynamicSchema(vsku);
      setYupSchema(schema);
      logEvent({
        event: 'display_form',
        properties: { vsku: vsku.vsku },
      });
    };
    fetchSchema();
  }, [vsku]);

  useEffect(() => {
    console.log('checking for previous step data');
    if (data && formData !== data) {
      console.log('loading data from step', data);
      setFormData(data.inputValues);
    } else {
      setFormData(yupSchema?.defaultData ?? {});
    }
  }, [data, yupSchema]);

  const schemaFields: EndpointInput[] = React.useMemo(() => {
    if (yupSchema) {
      return yupSchema.getFields(formData);
    }
    return [];
  }, [yupSchema, formData]);

  const setFieldValue = React.useMemo(
    () =>
      ({ name, value }: { name: string; value: any }) => {
        setErrors({});
        setFormData({
          ...formData,
          [name]: value,
        });
      },
    [formData]
  );

  const handleDuplicate = async () => {
    if (yupSchema) {
      const result = await handleSubmit();
      if (result) {
        const { stepType, stepParams } = currentStepData;
        dispatch(
          insertStep({
            stepIndex: stepIndex + 1,
            stepData: {
              stepType,
              stepParams: { ...stepParams, duplicated: true },
            },
          })
        );
        dispatch(gotoNextStep({ stepKey }));
      }
    }
  };
  const handleRemove = () => {
    dispatch(removeStep({ stepIndex }));
    navigate(-1);
  };

  return (
    <Stack flexGrow={1}>
      <Typography
        color='emphasis.dark'
        variant='h4'
        textAlign='center'
        paddingBottom='0.5rem'
      >
        {intl.formatMessage({ id: vsku.nameId })}
      </Typography>
      <Typography variant='subtitle1' textAlign='center' paddingBottom='0.5rem'>
        <FormattedMessage id={vsku.descriptionId} />
      </Typography>
      {breadcrumbs && <Breadcrumbs fields={breadcrumbs} />}
      <VerifyBody
        schemaFields={schemaFields}
        formData={formData}
        errors={errors}
        vsku={vsku}
        duplicated={duplicated}
        validationResults={validationResults}
        setFieldValue={setFieldValue}
        handleDuplicate={handleDuplicate}
        handleRemove={handleRemove}
      />
    </Stack>
  );
};

const VerifyFields = ({
  vspecId,
  vsku,
  hasNext,
}: {
  vspecId: string;
  vsku: FieldVSku;
  hasNext?: boolean;
}): JSX.Element => {
  const [yupSchema, setYupSchema] = React.useState<EndpointSchema>();
  const [formData, setFormData] = React.useState<Record<string, any>>({});
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const { logEvent } = React.useContext(AnalyticsContext);
  const {
    overrideButtonsController,
    goToNextStep,
    setStepValue,
    duplicateCurrentStep,
    activeStep,
    activeStepValue,
  } = useWizard();

  const validationResults = React.useMemo<EndpointValidateResults>(() => {
    return yupSchema && yupSchema.validate(formData);
  }, [yupSchema, formData]);
  useEffect(() => {
    overrideButtonsController({
      next: {
        isHidden: () => !validationResults?.isValid,
        onClick: () => handleSubmit(),
      },
      previous: {},
    });
  }, [validationResults, hasNext]);

  React.useEffect(() => {
    const fetchSchema = async () => {
      const schema = await buildDynamicSchema(vsku);
      setYupSchema(schema);
      logEvent({
        event: 'display_form',
        properties: { vsku: vsku.vsku },
      });
    };
    fetchSchema();
  }, [vsku]);

  useEffect(() => {
    if (activeStepValue) {
      const { inputValues } = activeStepValue;
      setFormData(inputValues);
    } else {
      setFormData(yupSchema?.defaultData ?? {});
    }
  }, [activeStep, activeStepValue, yupSchema]);

  const schemaFields: EndpointInput[] = React.useMemo(() => {
    if (yupSchema) {
      return yupSchema.getFields(formData);
    }
    return [];
  }, [yupSchema, formData]);

  const setFieldValue = React.useMemo(
    () =>
      ({ name, value }: { name: string; value: any }) => {
        setErrors({});
        setFormData({
          ...formData,
          [name]: value,
        });
      },
    [formData]
  );

  const handleSubmit = () => {
    if (yupSchema) {
      const validationResults = yupSchema.validate(formData);
      if (validationResults.isValid) {
        setStepValue({
          vspecId,
          vsku: vsku.vsku,
          stepType: VSkuType.field,
          primaryInputs: vsku.primaryInputs,
          inputValues: validationResults.values,
          jurisdiction: vsku.jurisdiction,
        });
        goToNextStep();
      } else {
        setErrors(validationResults.errorMap);
      }
    }
  };
  const handleDuplicate = () => {
    if (yupSchema) {
      const validationResults = yupSchema.validate(formData);
      if (validationResults.isValid) {
        setStepValue({
          vspecId,
          vsku: vsku.vsku,
          stepType: VSkuType.field,
          primaryInputs: vsku.primaryInputs,
          inputValues: validationResults.values,
          jurisdiction: vsku.jurisdiction,
        });
        duplicateCurrentStep();
        goToNextStep();
      } else {
        setErrors(validationResults.errorMap);
      }
    }
  };

  const handleRemove = () => {
    console.warn('Not supported');
  };

  return (
    <VerifyBody
      schemaFields={schemaFields}
      formData={formData}
      errors={errors}
      vsku={vsku}
      validationResults={validationResults}
      setFieldValue={setFieldValue}
      handleDuplicate={handleDuplicate}
      handleRemove={handleRemove}
    />
  );
};
const VerifyBody = ({
  schemaFields,
  formData,
  errors,
  setFieldValue,
  vsku,
  duplicated,
  validationResults,
  handleDuplicate,
  handleRemove,
}: {
  schemaFields: EndpointInput[];
  formData: Record<string, any>;
  errors: Record<string, string>;
  vsku: FieldVSku;
  duplicated?: boolean;
  validationResults: EndpointValidateResults;
  setFieldValue: ({ name, value }: { name: string; value: string }) => void;
  handleDuplicate: () => void;
  handleRemove: () => void;
}) => {
  const intl = useIntl();
  return (
    <Stack sx={{ width: '100%' }} component='form' noValidate flexGrow={1}>
      {vsku.disclaimerId && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '1rem',
            bgcolor: 'info.light',
            borderRadius: 1,
          }}
        >
          <Box sx={{ mr: '1rem', alignSelf: 'center' }}>
            <InfoIcon />
          </Box>
          <Typography variant='body2' color='emphasis.dark'>
            <FormattedMessage
              id={vsku.disclaimerId}
              values={{
                b: chunks => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
        </Box>
      )}
      <Stack spacing={2} flexGrow={1} pt='1rem'>
        {schemaFields.map(input => (
          <VerifyInput
            key={`field_${input.inputName}`}
            input={input}
            value={formData[input.inputName]}
            setFieldValue={(value: any) =>
              setFieldValue({ name: input.inputName, value })
            }
            error={errors[input.inputName]}
          />
        ))}
      </Stack>
      {duplicated && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Button onClick={handleRemove}>
            <FormattedMessage
              description='Text to prompt user to remove a duplicated item'
              defaultMessage='Remove {name}'
              values={{
                name: intl.formatMessage({ id: vsku.nameId }),
              }}
            />
          </Button>
        </Box>
      )}
      <Fade
        in={vsku.allowMultiples && validationResults?.isValid}
        timeout={500}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Button onClick={handleDuplicate}>
            <FormattedMessage
              description='Text to prompt user to add another item to the verificationOrder'
              defaultMessage='Add another {name}'
              values={{
                name: intl.formatMessage({ id: vsku.nameId }),
              }}
            />
          </Button>
        </Box>
      </Fade>

      {vsku.footerId && (
        <Stack mt={2}>
          <Typography variant='caption'>
            <FormattedMessage id={vsku.footerId} />
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default VerifyFields;
