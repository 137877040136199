import * as React from 'react';
import { useIntl } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import type { EndpointInput } from '@utils/schema';
import { useMemo } from 'react';
import dayjs from 'dayjs';
const VerifyInputDate = ({
  input,
  value,
  error,
  setFieldValue,
}: {
  input: EndpointInput;
  value: string;
  error: string;
  setFieldValue: (value: string) => void;
}): JSX.Element => {
  const intl = useIntl();
  const helperText = useMemo(() => {
    if (error) {
      return intl.formatMessage({ id: error });
    }
    if (input.inputHelperText) {
      return intl.formatMessage({ id: input.inputHelperText });
    }
    return '';
  }, [input, error]);
  return (
    <DatePicker
      defaultValue={value && dayjs(value)}
      onChange={value => setFieldValue(value.toISOString())}
      label={intl.formatMessage({ id: input.inputDescription })}
      slotProps={{
        textField: {
          required: input.isRequired,
          error: !!error,
          helperText: helperText,
        },
      }}
    />
  );
};

export default VerifyInputDate;
