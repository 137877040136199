import { createTheme } from '@mui/material/styles';

const colors = {
  // emphasis color
  emphasisDark: '#001c65ff', // primary title, sidebar
  emphasisBlue: '#001c65de', //
  emphasisLight: '#001c6599',

  // primary accent color
  blueAccent: '#0071f6cc',
  lightBlueAccent: '#0071f64d',
  infoAlertBackground: '#E9F4FE',

  blueAccentSuperLight: '#F2F8FF',
  // secondary accent color

  purpleAccent: '#5041ffcc',

  white: '#fff',

  // Chip colors
  positivePrimary: '#4caf5044',
  positiveContrast: '#007905',
  warningPrimary: '#ed6c0244',
  warningContrast: '#b95300',
  negativePrimary: '#d32f2f33',
  negativeContrast: '#a62121',
};

const MeshTheme = createTheme({
  palette: {
    primary: {
      main: colors.blueAccent,
      light: colors.lightBlueAccent,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.purpleAccent,
    },
    background: {
      default: colors.blueAccentSuperLight,
      paper: colors.white,
    },
    text: {
      primary: 'rgba(0,0,0,0.8)',
      secondary: 'rgba(0,0,0,0.6)',
    },
    emphasis: {
      main: colors.emphasisBlue,
      light: colors.emphasisLight,
      dark: colors.emphasisDark,
      contrastText: colors.white,
    },
    white: {
      main: colors.white,
    },
    chipPositive: {
      main: colors.positivePrimary,
      contrastText: colors.positiveContrast,
    },
    chipWarning: {
      main: colors.warningPrimary,
      contrastText: colors.warningContrast,
    },
    chipNegative: {
      main: colors.negativePrimary,
      contrastText: colors.negativeContrast,
    },
    info: {
      light: colors.infoAlertBackground,
      contrastText: colors.lightBlueAccent,
      main: colors.infoAlertBackground,
    },
  },
  typography: {
    fontFamily: 'THICCCBOI',
    h1: {
      fontSize: '3rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h6: {
      // Repurposing h6 to be equivalent to body0 (slightly larger than body1)
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
    },
    subtitle1: {
      lineHeight: '1.5rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 440,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1rem',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Repurposing h6 to be equivalent to body0 (body text slightly larger than body1)
          h6: 'span',
        },
      },
    },
  },
});

export default MeshTheme;
