import { selectEndpoint } from '@features/endpoint/endpointSlice';
import { useAppSelector } from '@hooks/useAppSelector';
import { MeshFeedback, useWizard } from '@shared/components';

import React, { useContext, useEffect } from 'react';
import { selectAuthentication } from '../auth/authSlice';
import { AppContext } from '@context/app.context';
import { Box, Button, Fade, Slide, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ContentProps } from '@features/verification/VerificationWizard/components/Step';
import {
  PreventAction,
  setStepFooter,
  setStepHeader,
} from '@features/verification/VerificationWizard/wizardSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { Mesh } from '@shared/icons';
import { selectVerificationOrder } from '@features/verification-order/verificationOrderSlice';

export const StandAloneResult = () => {
  const endpointConfig = useAppSelector(selectEndpoint);
  const { isEmbedded } = useContext(AppContext);
  const { customerProfessionalId } = useAppSelector(selectAuthentication);
  const { response } = useAppSelector(selectVerificationOrder);
  const {
    redirectSettings,
    customer: { customerLogo },
  } = endpointConfig;

  const { nextHandler, hideNextButton } = React.useMemo(() => {
    const nextHandler = isEmbedded
      ? async () => {
          window.close();
          return PreventAction.no;
        }
      : async () => {
          const { redirectUri, includeUser } = redirectSettings || {};
          if (redirectUri) {
            const user = includeUser
              ? `?id=${customerProfessionalId}&orderId=${response}`
              : '';
            window.location.href = `${redirectUri}${user}`;
          }
          return PreventAction.no;
        };

    const hideNextButton = isEmbedded || redirectSettings ? false : true;
    return { nextHandler, hideNextButton };
  }, [isEmbedded, redirectSettings, customerProfessionalId]);

  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage(
        {
          event: 'verification_complete',
          data: {
            status: 'pending',
            orderId: response,
            businessId: customerProfessionalId,
          },
        },
        '*'
      );
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
      }}
    >
      <Box sx={{ flex: 'none' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',

              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <img src={customerLogo} height={24} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Stack sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
        <Typography
          color='emphasis.dark'
          variant='h3'
          textAlign='center'
          paddingBottom='1rem'
        >
          <FormattedMessage
            description='Result step title'
            defaultMessage='Thank you!'
          />
        </Typography>
        <Typography variant='subtitle1' textAlign='center' paddingBottom='1rem'>
          <FormattedMessage
            description='Result step subtitle'
            defaultMessage='We will email your verification results within one business day.'
          />
        </Typography>
        <MeshFeedback />
      </Stack>
      <Box sx={{ flex: 'none' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Slide direction='up' in={!hideNextButton} timeout={200}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Fade in={!hideNextButton} timeout={500}>
                <Button
                  variant='contained'
                  color='emphasis'
                  fullWidth
                  size='large'
                  onClick={nextHandler}
                >
                  <FormattedMessage
                    description='Next button label for verification wizard'
                    defaultMessage='Next'
                  />
                </Button>
              </Fade>
            </Box>
          </Slide>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '0.5rem',
            }}
          >
            <Typography
              sx={{
                pr: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              variant='body1'
            >
              <FormattedMessage
                description='Wizard footer verifications powered by'
                defaultMessage='Verifications powered by {logo}'
                values={{
                  logo: (
                    <Box
                      sx={{
                        height: '1rem',
                        marginLeft: '0.5rem',
                        display: 'inline-block',
                      }}
                    >
                      <Box sx={{ height: '15px', width: '55px' }}>
                        <Mesh />
                      </Box>
                    </Box>
                  ),
                }}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const VerificationResultStep = ({ stepKey }: ContentProps) => {
  const endpointConfig = useAppSelector(selectEndpoint);
  const dispatch = useAppDispatch();
  const { isEmbedded } = useContext(AppContext);
  const { customerProfessionalId } = useAppSelector(selectAuthentication);
  const { response } = useAppSelector(selectVerificationOrder);
  const { redirectSettings } = endpointConfig;

  useEffect(() => {
    const nextHandler = isEmbedded
      ? async () => {
          window.close();
          return PreventAction.no;
        }
      : async () => {
          const { redirectUri, includeUser } = redirectSettings || {};
          if (redirectUri) {
            const user = includeUser ? `?id=${customerProfessionalId}` : '';
            window.location.href = `${redirectUri}${user}`;
          }
          return PreventAction.no;
        };

    const hideNext = isEmbedded || redirectSettings ? false : true;
    dispatch(
      setStepHeader({
        stepKey,
        header: {
          hideBackButton: true,
          hidePercentComplete: true,
        },
      })
    );
    dispatch(
      setStepFooter({
        stepKey,
        footer: {
          hideNextButton: hideNext,
          onNext: nextHandler,
        },
      })
    );
  }, [isEmbedded, redirectSettings, customerProfessionalId]);

  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage(
        {
          event: 'verification_complete',
          data: {
            status: 'pending',
            orderId: response,
            businessId: customerProfessionalId,
          },
        },
        '*'
      );
    }
  }, []);

  return (
    <>
      <Typography
        color='emphasis.dark'
        variant='h4'
        textAlign='center'
        paddingBottom='1rem'
      >
        <FormattedMessage
          description='Result step title'
          defaultMessage='Thank you!'
        />
      </Typography>
      <Typography variant='subtitle1' textAlign='center' paddingBottom='1rem'>
        <FormattedMessage
          description='Result step subtitle'
          defaultMessage='We will email your verification results within one business day.'
        />
      </Typography>
      <MeshFeedback />
    </>
  );
};

const ResultStep = () => {
  const endpointConfig = useAppSelector(selectEndpoint);
  const { overrideButtonsController } = useWizard();
  const { isEmbedded } = useContext(AppContext);
  const { customerProfessionalId } = useAppSelector(selectAuthentication);
  const { response } = useAppSelector(selectVerificationOrder);
  const { redirectSettings } = endpointConfig;

  useEffect(() => {
    const nextHandler = isEmbedded
      ? () => {
          window.close();
        }
      : () => {
          const { redirectUri, includeUser } = redirectSettings || {};
          if (redirectUri) {
            const user = includeUser ? `?id=${customerProfessionalId}` : '';
            window.location.href = `${redirectUri}${user}`;
          }
        };

    const hideNext = isEmbedded || redirectSettings ? false : true;
    overrideButtonsController({
      next: {
        isHidden: () => hideNext,
        isDisabled: () => hideNext,
        onClick: nextHandler,
      },
      previous: {
        isHidden: () => true,
      },
    });
  }, [isEmbedded, redirectSettings, customerProfessionalId]);

  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage(
        {
          event: 'verification_complete',
          data: {
            status: 'pending',
            orderId: response,
            businessId: customerProfessionalId,
          },
        },
        '*'
      );
    }
  }, []);

  return <MeshFeedback />;
};

export default ResultStep;
