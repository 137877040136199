import * as React from 'react';
import { Box, ButtonBase, Grid, Link, Stack, Typography } from '@mui/material';

import { FormattedMessage, useIntl } from 'react-intl';
// import { ContentProps } from '@features/verification/VerificationWizard/components/Step';
import { useAppDispatch } from '@hooks/useAppDispatch';
import {
  StepData,
  gotoNextStep,
  setStepData,
  setStepFooter,
} from '@features/verification/VerificationWizard/wizardSlice';
import Breadcrumbs from '@shared/components/Breadcrumbs';

type SelectionOption = {
  headerId: string;
  subheaderId: string;
  descriptionId: string;
  icon: JSX.Element;
  claims: Record<string, string>;
};

interface ContentProps {
  stepIndex: number;
  stepKey: string;
  currentStepData: StepData;
}

export const VerificationSelection = ({
  stepKey,
  currentStepData,
  stepIndex,
}: ContentProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  // const condition = currentStepData?.stepParams?.condition as Record<string, string>
  const {
    stepParams: { breadcrumbs, options = [], vsku },
  } = currentStepData;

  React.useEffect(() => {
    dispatch(
      setStepFooter({
        stepKey,
        footer: {
          hideNextButton: true,
        },
      })
    );
  }, []);

  const makeSelection = (inputValues: Record<string, string>) => {
    dispatch(
      setStepData({
        stepKey,
        data: {
          inputValues,
        },
      })
    );
    dispatch(gotoNextStep({ stepKey }));
  };

  return (
    <>
      <Typography
        color='emphasis.dark'
        variant='h4'
        textAlign='center'
        paddingBottom='0.5rem'
      >
        {intl.formatMessage({ id: vsku.nameId })}
      </Typography>
      <Typography variant='subtitle1' textAlign='center' paddingBottom='1rem'>
        <FormattedMessage id={vsku.descriptionId} />
      </Typography>
      {breadcrumbs && <Breadcrumbs fields={breadcrumbs} />}
      <Stack spacing={1}>
        {options.map(
          ({
            claims,
            icon,
            headerId,
            subheaderId,
            descriptionId,
          }: SelectionOption) => {
            return (
              <ButtonBase
                key={`option-${headerId}`}
                onClick={() => makeSelection(claims)}
              >
                <Box
                  border={1}
                  borderColor='grey.400'
                  borderRadius='0.5rem'
                  width='100%'
                  padding={2}
                >
                  <Grid container spacing={1}>
                    <Grid item xs='auto'>
                      <Box
                        borderRadius='50%'
                        width={40}
                        height={40}
                        sx={{ backgroundColor: 'background.default' }}
                      >
                        <Box paddingTop={1} color='primary.main'>
                          {icon}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs
                      container
                      direction='column'
                      justifyContent='center'
                    >
                      {headerId && (
                        <Typography
                          variant='h4'
                          color='emphasis.dark'
                          textAlign='left'
                        >
                          {intl.formatMessage({ id: headerId })}
                        </Typography>
                      )}
                      {subheaderId && (
                        <Typography variant='h5' textAlign='left'>
                          {intl.formatMessage({ id: subheaderId })}
                        </Typography>
                      )}
                      {descriptionId && (
                        <Typography
                          variant='body2'
                          color='text.secondary'
                          textAlign='left'
                        >
                          {intl.formatMessage({ id: descriptionId })}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </ButtonBase>
            );
          }
        )}
      </Stack>
      <Typography sx={{ mt: 2, textAlign: 'center' }} variant='body2'>
        <Link
          style={{
            textDecoration: 'none',
            fontSize: '1rem',
            fontWeight: '600',
          }}
          href='mailto:support@mesh.id'
        >
          <FormattedMessage
            description='Help text disclaimer'
            defaultMessage='I need help'
          />
        </Link>
      </Typography>
    </>
  );
};
