import * as React from 'react';
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { useIntl, defineMessage, FormattedMessage } from 'react-intl';

import { type EndpointInput } from '@utils/schema';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  startAutoCompleteAddress,
  selectAddress,
  AddressDetails,
  startSelectAddress,
  AddressRequest,
  completeSelection,
} from './addressSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { AutocompleteAddressOption } from './addressSaga';
import { AddAddressModal } from './AddAddressModal';

const VerifyInputAddress = ({
  input,
  value,
  error,
  setFieldValue,
}: {
  input: EndpointInput;
  value: AddressDetails;
  error: string;
  setFieldValue: (value: AddressDetails) => void;
}): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [hasFocus, setFocus] = React.useState(false);
  const [options, setOptions] = React.useState<AutocompleteAddressOption[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [addressEntry, setAddressEntry] = React.useState(
    value?.fullAddress || ''
  );
  const [selectedAddress, setSelectedAddress] =
    React.useState<AutocompleteAddressOption>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const addressSlice = useAppSelector(selectAddress);
  React.useEffect(() => {
    if (hasFocus && !addressSlice.target) {
      const addNoMatch =
        addressEntry &&
        addressSlice.options.every(
          option => option.fullAddress !== addressEntry
        );
      if (addNoMatch) {
        const addOption: AutocompleteAddressOption = {
          id: 'address-add-manually',
          fullAddress: intl.formatMessage(
            {
              description:
                'Message to display when there are no options to display due to no matches',
              defaultMessage: 'Add "{addressText}" manually',
            },
            { addressText: addressEntry }
          ),
        };
        setOptions([...addressSlice.options, addOption]);
      } else {
        setOptions(addressSlice.options);
      }
    } else {
      setOptions([]);
    }
  }, [hasFocus, addressSlice.options, addressSlice.target]);

  React.useEffect(() => {
    if (addressSlice.target === input.inputName) {
      setLoading(addressSlice.isLoading);
    } else {
      setLoading(false);
    }
  }, [addressSlice.isLoading, addressSlice.target]);

  React.useEffect(() => {
    if (hasFocus && !addressSlice.target) {
      dispatch(startAutoCompleteAddress(addressEntry));
    }
  }, [hasFocus, addressEntry, addressSlice.target]);

  React.useEffect(() => {
    if (addressSlice.selected && addressSlice.target === input.inputName) {
      if (addressSlice.selectedAddress) {
        setFieldValue(addressSlice.selectedAddress);
        setLoading(false);
        dispatch(completeSelection());
      } else {
        setFieldValue(null);
        setLoading(false);
        dispatch(completeSelection());
      }
    }
  }, [addressSlice.selected, addressSlice.target]);

  return (
    <>
      <Autocomplete<AutocompleteAddressOption, false, false, false>
        fullWidth
        inputValue={addressEntry}
        value={selectedAddress}
        options={options}
        id={input.inputName}
        onChange={(
          evt: React.SyntheticEvent,
          newValue: AutocompleteAddressOption
        ) => {
          if (newValue?.id === 'address-add-manually') {
            setOpenModal(true);
            evt.preventDefault();
            return;
          } else {
            setSelectedAddress(newValue);
            dispatch(
              startSelectAddress({
                addressRequest: { fullAddress: newValue?.fullAddress },
                target: input.inputName,
              })
            );
          }
        }}
        onInputChange={(_: unknown, newInputValue: string) => {
          setAddressEntry(newInputValue);
        }}
        noOptionsText={intl.formatMessage({
          description:
            'Message to display when there are no options to display and also no text has been entered',
          defaultMessage: 'Please enter address',
        })}
        filterOptions={x => x}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.fullAddress}
          </li>
        )}
        getOptionLabel={option => {
          return option?.fullAddress || '';
        }}
        isOptionEqualToValue={(option, value) =>
          option.fullAddress === value.fullAddress
        }
        renderInput={params => (
          <TextField
            {...params}
            label={intl.formatMessage({ id: input.inputDescription })}
            error={!!error}
            variant='outlined'
            helperText={error && intl.formatMessage({ id: error })}
            required={input.isRequired}
            name={input.inputName}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <AddAddressModal
        displayModal={openModal}
        handleClose={() => setOpenModal(false)}
        submitAddress={(address: AddressRequest): void => {
          setAddressEntry(address.fullAddress);
          setSelectedAddress({ id: 'manual-address', ...address });
          dispatch(
            startSelectAddress({
              addressRequest: {
                fullAddress: address.fullAddress,
                components: address.components,
              },
              target: input.inputName,
            })
          );
        }}
      />
    </>
  );
};

export default VerifyInputAddress;
