import React from 'react';
import { VspecEndpoint } from '@features/endpoint/endpointSlice';
import { StepData, StepType } from '../wizardSlice';
import { defineMessages } from 'react-intl';

export const eSignWizard = (vspec: VspecEndpoint): StepData[] => [
  // Step 1 - Shows a preview of the collected data and prepare signing process
  {
    stepType: StepType['vsku.esignPrepare'],
    stepParams: {
      vspecId: vspec.vspecId,
      vsku: vspec.vsku,
    },
  },
  // Step 2 - Renders the iframe with the document to sign
  {
    stepType: StepType['vsku.esignDocument'],
    stepParams: {
      vspecId: vspec.vspecId,
      vsku: vspec.vsku,
      loadingTitleId: 'vsku.esignDocument.loadingTilte',
      finishTitleId: 'vsku.esignDocument.finishTitle',
      successSubtitleId: 'vsku.esignDocument.successSubtitle',
      errorSubtitleId: 'vsku.esignDocument.errorSubtitle',
    },
  },
];

defineMessages({
  esignDocumentLoadingTitle: {
    id: 'vsku.esignDocument.loadingTilte',
    description: 'Title shown when iframe is preparing for open',
    defaultMessage: 'Generating document to sign...',
  },
  esignDocumentFinishTitle: {
    id: 'vsku.esignDocument.finishTitle',
    description: 'Title shown when iframe was closed',
    defaultMessage: 'Signing process finished',
  },
  esignDocumentSuccessSubtitle: {
    id: 'vsku.esignDocument.successSubtitle',
    description: 'Subtitle shown when document was succesfully signed',
    defaultMessage: 'Document succesfully signed!',
  },
  esignDocumentErrorSubtitle: {
    id: 'vsku.esignDocument.errorSubtitle',
    description: 'Subtitle shown when an error occurs in document signing',
    defaultMessage: 'Error signing document!',
  },
});
