import React from 'react';
import { MuiOtpInput, MuiOtpInputProps } from 'mui-one-time-password-input';
import { styled } from '@mui/system';

const StyledOtpInput = styled('div')({
  'input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
    {
      '-webkit-appearance': 'none',
      margin: 0,
    },
});
export default class MeshOtpInput extends React.Component<MuiOtpInputProps> {
  render() {
    return (
      <StyledOtpInput>
        <MuiOtpInput
          sx={{ gap: '6px', maxWidth: '280px' }}
          length={6}
          autoFocus
          TextFieldsProps={{
            InputProps: {
              inputProps: {
                style: { MozAppearance: 'textfield' },
              },
            },
            type: 'number',
            inputMode: 'numeric',
            size: 'medium',
          }}
          {...this.props}
        />
      </StyledOtpInput>
    );
  }
}
