import React, { SVGProps } from 'react';

const Mesh: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 55 15'
      {...props}
    >
      <path
        fill='#000'
        fillRule='evenodd'
        d='M52.137 10.68v.107h.413v1.056h.283v-1.056h.413v-.215h-1.109v.107zm1.287.527v.636h.267l.002-.487.002-.487.134.485.134.485.137.002.138.002.034-.124c.02-.068.08-.288.136-.489.055-.2.103-.366.105-.368.002-.002.004.217.004.488v.493h.26v-1.271h-.215c-.168 0-.215.002-.215.01 0 .005-.033.124-.074.264l-.15.524a.283.283 0 01-.018.053 1.934 1.934 0 01-.069-.214 63.266 63.266 0 00-.15-.517l-.035-.12h-.427v.636z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#000'
        d='M16.598 12.052h2.127V8.478c0-1.72.331-2.323 1.266-2.323.8 0 1.056.513 1.056 2.067v3.83h2.127V8.448c0-1.674.346-2.293 1.266-2.293.8 0 1.056.513 1.056 2.067v3.83h2.127V8.116c0-1.146-.106-1.9-.317-2.413-.347-.814-1.101-1.237-2.157-1.237-1.04 0-1.72.317-2.337 1.101-.377-.739-1.056-1.1-2.097-1.1-.95 0-1.523.256-2.096.92l-.12-.725h-1.9v7.39zM36.317 8.87c.03-.302.03-.467.03-.648 0-2.428-1.251-3.74-3.544-3.74-2.277 0-3.574 1.402-3.574 3.875 0 2.58 1.343 3.891 3.997 3.891 1.161 0 2.02-.196 2.925-.648l-.633-1.478c-.663.392-1.372.573-2.187.573-1.312 0-2.02-.633-2.02-1.825h5.006zm-4.976-1.357c.045-1.04.573-1.584 1.523-1.584.965 0 1.417.498 1.463 1.584H31.34zM37.355 11.585c.8.467 1.614.663 2.775.663 2.066 0 3.077-.769 3.077-2.322 0-.71-.227-1.282-.68-1.629-.376-.317-.617-.437-1.643-.784-1.131-.392-1.297-.513-1.297-.92 0-.407.317-.619.89-.619.588 0 1.116.181 1.75.604l.708-1.343c-.769-.512-1.583-.754-2.579-.754-1.704 0-2.684.815-2.684 2.202 0 1.101.618 1.81 1.975 2.262 1.147.377 1.147.378 1.358.513.166.12.256.317.256.543 0 .513-.392.754-1.206.754-.755 0-1.388-.18-1.991-.588l-.709 1.418zM44.739 12.052h2.126v-3.59c0-1.688.362-2.291 1.388-2.291.904 0 1.206.527 1.206 2.066v3.815h2.127V8.116c0-1.146-.106-1.885-.347-2.398-.377-.814-1.161-1.237-2.277-1.237-.92 0-1.584.257-2.097.8V.5H44.74v11.552z'
      ></path>
      <path
        fill='#0071F6'
        fillRule='evenodd'
        d='M5.76.91a.665.665 0 00-.215.049L.628 2.967a.646.646 0 00-.404.597V8.11C.37 9.624 1.682 13.127 5.76 14.5c4.078-1.373 5.39-4.876 5.536-6.39V3.565c0-.261-.16-.497-.404-.597L5.976.96A.665.665 0 005.761.91zm3.627 4.802a.695.695 0 00-.077-1 .747.747 0 00-1.03.074L5.12 8.342 3.485 6.859a.747.747 0 00-1.034.034.695.695 0 00.036 1.003l2.082 1.886c.364.33.938.3 1.263-.067l3.556-4.003z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#D9D9D9'
        fillRule='evenodd'
        d='M5.762.91H5.76a.665.665 0 00-.216.049L.628 2.967a.646.646 0 00-.404.597V8.11C.37 9.624 1.682 13.127 5.76 14.5V9.785a.897.897 0 01-1.193-.003L2.486 7.896a.695.695 0 01-.036-1.003.747.747 0 011.034-.034L5.12 8.342l.64-.722V.91z'
        clipRule='evenodd'
      ></path>
      <path
        fill='url(#paint0_linear_160_1328)'
        fillRule='evenodd'
        d='M5.762.91H5.76a.665.665 0 00-.216.049L.628 2.967a.646.646 0 00-.404.597V8.11C.37 9.624 1.682 13.127 5.76 14.5V9.785a.897.897 0 01-1.193-.003L2.486 7.896a.695.695 0 01-.036-1.003.747.747 0 011.034-.034L5.12 8.342l.64-.722V.91z'
        clipRule='evenodd'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_160_1328'
          x1='1.762'
          x2='9.287'
          y1='1.73'
          y2='8.179'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#01DAFE'></stop>
          <stop offset='0.166' stopColor='#01CBFD'></stop>
          <stop offset='0.346' stopColor='#01A9FA'></stop>
          <stop offset='0.581' stopColor='#008CF8'></stop>
          <stop offset='0.823' stopColor='#0077F6'></stop>
          <stop offset='0.995' stopColor='#0071F6'></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Mesh;
