import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../config/store';

export type MobileNumberState = {
  isProcessing: boolean;
  phoneNumber: string;
  needsVerification: boolean;
  errorMessage: string;
  verified: boolean;
};

const initialState: MobileNumberState = {
  isProcessing: false,
  phoneNumber: '',
  needsVerification: false,
  errorMessage: '',
  verified: false,
};

export const mobileNumberSlice = createSlice({
  name: 'mobileNumber',
  initialState,
  reducers: {
    startSubmitPhoneNumber: (state, action: PayloadAction<string>): void => {
      state.isProcessing = true;
      state.phoneNumber = action.payload;
      state.errorMessage = '';
    },
    finishSubmitPhoneNumber: (state): void => {
      state.isProcessing = false;
      state.needsVerification = true;
      state.errorMessage = '';
    },
    startVerifyPhoneNumber: (state, action: PayloadAction<string>): void => {
      state.isProcessing = true;
      state.errorMessage = '';
    },
    finishVerifyPhoneNumber: (state): void => {
      state.isProcessing = false;
      state.needsVerification = false;
      state.verified = true;
      state.errorMessage = '';
    },
    cancelVerifyPhoneNumber: (state): void => {
      state.isProcessing = false;
      state.needsVerification = false;
      state.errorMessage = '';
    },
    resendVerifyPhoneNumber: (state): void => {
      state.errorMessage = '';
    },
    clearVerified: (state): void => {
      state.verified = false;
      state.errorMessage = '';
    },
    errorMobileNumber: (state, action: PayloadAction<string>): void => {
      state.isProcessing = false;
      state.errorMessage = action.payload;
    },
    startNotifyPhoneNumber: (state, action: PayloadAction<string>): void => {
      state.isProcessing = true;
      state.errorMessage = '';
    },
    finishNotifyPhoneNumber: (state): void => {
      state.isProcessing = false;
    },
  },
});

export const {
  startSubmitPhoneNumber,
  finishSubmitPhoneNumber,
  startVerifyPhoneNumber,
  finishVerifyPhoneNumber,
  cancelVerifyPhoneNumber,
  resendVerifyPhoneNumber,
  startNotifyPhoneNumber,
  finishNotifyPhoneNumber,
  clearVerified,
  errorMobileNumber,
} = mobileNumberSlice.actions;

export const selectMobileNumber = (state: RootState) => state.mobileNumber;

export default mobileNumberSlice.reducer;
