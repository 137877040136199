import React from 'react';
import { Card, CardContent } from '@mui/material';

import { Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/useAppSelector';
import { clearNavigateStep, selectWizard } from './wizardSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';

const VerificationWizard = (): JSX.Element => {
  const { navigateStep, navigateOptions } = useAppSelector(selectWizard);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (navigateStep) {
      dispatch(clearNavigateStep());
      navigate(navigateStep, navigateOptions);
    }
  }, [navigateStep, navigateOptions]);

  return (
    <Card sx={{ backgroundColor: 'white', height: '100%', width: '100%' }}>
      <CardContent sx={{ height: '100%' }}>
        <Outlet />
      </CardContent>
    </Card>
  );
};

export default VerificationWizard;
