import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';

import {
  StepData,
  setStepData,
  selectWizard,
  setStepFooter,
  PreventAction,
} from '@features/verification/VerificationWizard/wizardSlice';
import { selectEsign } from '@features/esign/esignSlice';
import { VSkuType } from '@features/endpoint/endpointSlice';

import { Box, CircularProgress, Typography } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';

import config from '@utils/config';

import HelloSign from 'hellosign-embedded';

const client = new HelloSign();

const CLIENT_ID = config.dropboxClientId;
const TEST_MODE = config.stage === 'prod' ? false : true;
interface ContentProps {
  stepIndex: number;
  stepKey: string;
  currentStepData: StepData;
}

const IconBox = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        pt: '1rem',
      }}
    >
      {children}
    </Box>
  );
};

export const VerificationSignDocument = ({
  stepKey,
  currentStepData,
}: ContentProps) => {
  const dispatch = useAppDispatch();
  const { claims } = useAppSelector(selectWizard);
  const { externalDocumentId } = claims;
  const { isLoading, embedUrl, id, fileName } = useAppSelector(selectEsign);
  const [signed, setSigned] = useState(false);
  const currentId = useMemo(() => (signed ? id : externalDocumentId), [signed]);
  const signError = useMemo(
    () => !isLoading && !currentId,
    [currentId, isLoading]
  );
  const intl = useIntl();
  const { stepParams } = currentStepData ?? {};
  const {
    vspecId,
    vsku,
    loadingTitleId,
    finishTitleId,
    successSubtitleId,
    errorSubtitleId,
  } = stepParams ?? {};

  useEffect(() => {
    client.on('sign', () => setSigned(true));
    client.on('error', () => setSigned(false));
    client.on('cancel', () => setSigned(false));
  }, []);

  useEffect(() => {
    if (!isLoading && !currentId) {
      if (embedUrl) {
        client.open(embedUrl, {
          clientId: CLIENT_ID,
          skipDomainVerification: TEST_MODE,
        });
      } else {
        setSigned(false);
      }
    }

    dispatch(
      setStepFooter({
        stepKey,
        footer: {
          hideNextButton: !currentId || isLoading,
          onNext: handleNext,
        },
      })
    );
  }, [isLoading, currentId]);

  const handleNext = async () => {
    dispatch(
      setStepData({
        stepKey,
        data: {
          vspecId,
          vsku: vsku.vsku,
          stepType: VSkuType.eSign,
          primaryInputs: vsku.primaryInputs,
          jurisdiction: vsku.jurisdiction,
          inputValues: {
            fileName,
            externalDocumentId: currentId,
          },
        },
      })
    );

    return PreventAction.no;
  };

  return (
    <>
      <Typography
        color='emphasis.dark'
        variant='h4'
        textAlign='center'
        paddingBottom='3rem'
      >
        {!isLoading
          ? intl.formatMessage({ id: finishTitleId })
          : intl.formatMessage({ id: loadingTitleId })}
      </Typography>
      {isLoading ? (
        <IconBox>
          <CircularProgress />
        </IconBox>
      ) : (
        <>
          <Typography variant='body1' textAlign='center' sx={{ pt: '0.5rem' }}>
            {signError
              ? intl.formatMessage({ id: errorSubtitleId })
              : intl.formatMessage({ id: successSubtitleId })}
          </Typography>

          <IconBox>
            {signError ? (
              <ErrorOutline color='error' fontSize='large' />
            ) : (
              <CheckCircleOutline color='success' fontSize='large' />
            )}
          </IconBox>
        </>
      )}
    </>
  );
};
