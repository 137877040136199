import * as React from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';

import { EndpointMaskType, type EndpointInput } from '@utils/schema';
import { IMaskInput } from 'react-imask';
import { useMemo } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
}

const maskTypeMap = {
  [EndpointMaskType.ssn]: '000-00-0000',
  [EndpointMaskType.ein]: '00-0000000',
  [EndpointMaskType.lastFourSsn]: '0000',
};

const StyledIconWrapper = styled('div')(({ theme }) => ({
  '& svg': {
    position: 'absolute',
    cursor: 'pointer',
    top: 'calc(50% - 0.75rem)',
    right: 0,
    color: theme.palette.grey[700],
    marginRight: '0.75rem',
  },
  width: '1.5rem',
}));

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    const [showMask, setShowMask] = React.useState(true);
    return (
      <>
        <IMaskInput
          {...other}
          mask={props.mask}
          inputRef={ref}
          type={showMask ? 'password' : 'text'}
          onAccept={(value, maskRef) =>
            onChange({
              target: { name: props.name, value: maskRef.unmaskedValue },
            })
          }
        />
        <StyledIconWrapper>
          {showMask && <VisibilityIcon onClick={() => setShowMask(false)} />}
          {!showMask && <VisibilityOffIcon onClick={() => setShowMask(true)} />}
        </StyledIconWrapper>
      </>
    );
  }
);
TextMaskCustom.displayName = 'TextMaskCustom';

const wrapMaskComponent = (mask: string) => {
  return (props: any) => <TextMaskCustom {...props} mask={mask} />; // eslint-disable-line react/display-name
};

const VerifyInputText = ({
  input,
  value = '',
  error,
  setFieldValue,
}: {
  input: EndpointInput;
  value: string;
  error: string;
  setFieldValue: (value: string) => void;
}): JSX.Element => {
  const intl = useIntl();
  const { maskType } = input;
  const inputComponent = useMemo(() => {
    const mask = maskTypeMap[input.maskType];
    if (mask) {
      return wrapMaskComponent(mask);
    }
  }, [maskType]);
  return (
    <TextField
      fullWidth
      label={intl.formatMessage({ id: input.inputDescription })}
      error={!!error}
      value={value}
      helperText={error && intl.formatMessage({ id: error })}
      id={input.inputName}
      required={input.isRequired}
      variant='outlined'
      InputProps={{ inputComponent }}
      onChange={evt => setFieldValue(evt.target.value)}
      name={input.inputName}
    />
  );
};

export default VerifyInputText;
