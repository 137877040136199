import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';

import {
  StepData,
  selectWizard,
  setStepData,
} from '@features/verification/VerificationWizard/wizardSlice';

import { Typography } from '@mui/material';
import { W9SignPrepare } from '@features/esign/w9/Prepare';
import { EsignPayload } from '@features/esign/esignSlice';

interface ContentProps {
  stepIndex: number;
  stepKey: string;
  currentStepData: StepData;
}

export const VerificationSignPrepare = ({
  stepKey,
  currentStepData,
}: ContentProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { claims } = useAppSelector(selectWizard);
  const { stepParams } = currentStepData ?? {};
  const { vsku } = stepParams ?? {};
  const { documentType, documentReference, disclaimerId } = vsku;

  const { email, firstName, lastName } = claims;
  const payload: EsignPayload = {
    documentType,
    documentReference,
    signer: {
      name: `${firstName} ${lastName}`,
      emailAddress: email,
    },
    vsku: vsku.vsku,
  };

  useEffect(() => {
    dispatch(
      setStepData({
        stepKey,
        data: {
          inputValues: {
            // Set to empty to start new signing in the next step
            externalDocumentId: '',
          },
        },
      })
    );
  }, []);

  return (
    <>
      <Typography
        color='emphasis.dark'
        variant='h4'
        textAlign='center'
        paddingBottom='1rem'
      >
        {intl.formatMessage({ id: vsku.titleId })}
      </Typography>
      <Typography variant='subtitle1' textAlign='center' paddingBottom='1rem'>
        <FormattedMessage id={vsku.descriptionId} />
      </Typography>
      <SwitchDocumentType
        payload={payload}
        stepKey={stepKey}
        disclaimerId={disclaimerId}
      />
    </>
  );
};

const SwitchDocumentType = ({
  payload,
  stepKey,
  disclaimerId,
}: {
  payload: EsignPayload;
  stepKey: string;
  disclaimerId: string;
}) => {
  switch (payload.documentType) {
    case 'W-9':
      return (
        <W9SignPrepare
          stepKey={stepKey}
          payload={payload}
          disclaimerId={disclaimerId}
        />
      );
    default:
      return <UnsupportedDocumentType />;
  }
};

const UnsupportedDocumentType = () => {
  return <p>Unsupported Document Type</p>;
};
