import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../config/store';

export interface Customer {
  customerId: string;
  customerName: string;
  customerLogo: string;
}

export interface RedirectSettings {
  redirectUri: string;
  includeUser: boolean;
}
export interface EndpointConfig {
  customer: Customer;
  endpointId: string;
  isDemo?: boolean;
  redirectSettings?: RedirectSettings;
  vspecs: VspecEndpoint[];
  parameters?: { key: string; value: string }[];
}

export type VspecEndpoint = {
  vspecId: string;
  vsku: VowVsku;
  type?: VSkuType;
};

export interface EndpointState {
  isLoading: boolean;
  endpointLoaded: boolean;
  endpointId: string;
  isDemo?: boolean;
  customer: Customer;
  redirectSettings?: RedirectSettings;
  errorMessage: string;
  vspecs: VspecEndpoint[];
  parameters?: Record<string, string>;
}

const initialState: EndpointState = {
  isLoading: true,
  endpointLoaded: false,
  endpointId: '',
  isDemo: false,
  errorMessage: '',
  customer: {
    customerId: '',
    customerName: '',
    customerLogo: '',
  },
  vspecs: [],
};

export type VskuField = {
  fieldName: string;
  nameId: string;
  errorId: string;
  label: string;
  isPrimary: boolean;
  isRequired: boolean;
  isDerived?: boolean;
  isHidden?: boolean;
  dataType: string;
  options: {
    copyParam?: string;
    copyClaim?: string;
    maskType?: string;
    multiple?: {
      min: number;
      max?: number;
    };
    requiredSet?: string[];
    values?: {
      value: string;
      label: string;
      valueStringId: string;
      labelStringId: string;
    }[];
  };
};

export type VowVsku =
  | FieldVSku
  | DocumentVSku
  | BusinessInfoWizardVSku
  | ESignVsku
  | MobileVsku;
export enum VSkuType {
  field = 'field',
  document = 'document',
  businessInfoWizard = 'businessInfoWizard',
  eSign = 'eSign',
  mobileVerification = 'mobileVerification',
}

export type FieldVSku = {
  vsku: string;
  type: VSkuType.field;
  nameId: string;
  jurisdiction: string;
  titleId: string;
  descriptionId: string;
  footerId: string;
  disclaimerId: string;
  inputClaims: VskuField[];
  outputResults: VskuField[];
  allowMultiples: boolean;
  allowSkip: boolean;
  requiredInputs: VskuField[];
  requiredOutputs: VskuField[];
  inputCategories: VskuField[];
  primaryInputs: string[];
};

export type DocumentVSku = {
  vsku: string;
  type: VSkuType.document;
  nameId: string;
  titleId: string;
  inputClaims: VskuField[];
  primaryInputs: string[];
  descriptionId: string;
  allowMultiples: boolean;
  allowSkip: boolean;
  jurisdiction: string;
};

export type BusinessInfoWizardVSku = {
  vsku: string;
  type: VSkuType.businessInfoWizard;
  nameId: string;
  titleId: string;
  inputClaims: VskuField[];
  primaryInputs: string[];
  descriptionId: string;
  allowMultiples: boolean;
  allowSkip: boolean;
  jurisdiction: string;
};

export type ESignVsku = {
  vsku: string;
  type: VSkuType.eSign;
  nameId: string;
  titleId: string;
  inputClaims: VskuField[];
  primaryInputs: string[];
  descriptionId: string;
  allowMultiples: boolean;
  allowSkip: boolean;
  jurisdiction: string;
};

export type MobileVsku = {
  vsku: string;
  type: VSkuType.mobileVerification;
  nameId: string;
  titleId: string;
  disclaimerId?: string;
  inputClaims: VskuField[];
  primaryInputs: string[];
  descriptionId: string;
  allowMultiples: boolean;
  allowSkip: boolean;
  jurisdiction: string;
};

export const endpointSlice = createSlice({
  name: 'endpoint',
  initialState,
  reducers: {
    startGetEndpoint: (state, action: PayloadAction<string>): void => {
      state.isLoading = true;
      state.endpointId = action.payload;
      state.endpointLoaded = false;
    },
    startGetEndpointByAlias: (state, action: PayloadAction<string>): void => {
      state.isLoading = true;
      state.endpointId = action.payload;
      state.endpointLoaded = false;
    },
    finishGetEndpoint: (state, action: PayloadAction<EndpointConfig>): void => {
      const { vspecs, redirectSettings, customer, endpointId, parameters } =
        action.payload;
      state.endpointLoaded = true;
      state.vspecs = vspecs;
      state.customer = customer;
      state.endpointId = endpointId;
      state.isDemo = action.payload.isDemo;
      state.isLoading = false;
      state.redirectSettings = redirectSettings;
      state.parameters = parameters?.reduce(
        (acc: Record<string, string>, { key, value }) => {
          acc[key] = value;
          return acc;
        },
        {}
      );
    },
    errorGetEndpoint: (state, action: PayloadAction<string>): void => {
      state.endpointLoaded = false;
      state.errorMessage = action.payload;
      state.endpointId = null;
      state.isLoading = false;
    },
  },
});

export const {
  startGetEndpoint,
  startGetEndpointByAlias,
  finishGetEndpoint,
  errorGetEndpoint,
} = endpointSlice.actions;

export const selectEndpoint = (state: RootState) => state.endpoint;

export default endpointSlice.reducer;
