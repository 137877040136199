import React, { useContext } from 'react';
import { AnalyticsContext } from '@context/analyticsContext';
import {
  MobileVsku,
  VSkuType,
  VskuField,
  VspecEndpoint,
  selectEndpoint,
} from '@features/endpoint/endpointSlice';
import {
  selectAuthentication,
  startAuthByUserId,
} from '@features/verification/steps/auth/authSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  gotoNextStep,
  initializeWizard,
  selectWizard,
  StepData,
  StepType,
} from '../wizardSlice';
import { buildBusinessInfoWizard } from './businessWizard';
import { eSignWizard } from './eSignWizard';
import { resetVerificationOrder } from '@features/verification-order/verificationOrderSlice';

export const InitializeWizard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const endpointConfig = useAppSelector(selectEndpoint);
  const { vspecs, parameters } = endpointConfig;
  const location = useLocation();

  const { isInitialized, stepData, stepKeys } = useAppSelector(selectWizard);
  const { firstName, lastName, birthdate, phoneNumber } =
    useAppSelector(selectAuthentication);
  const { logEvent, addProperties } = useContext(AnalyticsContext);
  const { endpointId, isDemo, customerId } = React.useMemo(() => {
    const {
      endpointId,
      isDemo,
      customer: { customerId },
    } = endpointConfig;
    return { endpointId, isDemo, customerId };
  }, [endpointConfig]);

  React.useEffect(() => {
    if (location?.search) {
      const params = queryString.parse(location.search);
      const id = typeof params.id === 'string' ? params.id : params.id?.[0];
      if (id) {
        dispatch(startAuthByUserId(id));
      }
    }
  }, [location]);

  React.useEffect(() => {
    if (!vspecs || vspecs.length === 0) {
      console.log('No vspecs');
      return;
    }
    console.log('initializing wizard', vspecs);

    addProperties({
      experience: 'mesh-professional',
      endpointId,
      isDemo: String(!!isDemo),
      customerId,
    });
    logEvent({
      event: 'page_loaded',
      properties: {
        experience: 'mesh-professional',
        endpointId,
        isDemo: String(!!isDemo),
        customerId,
      },
    });
    if (window.opener) {
      window.opener.postMessage({ event: 'page_loaded', data: {} }, '*');
    }
    // Ensure that the verificationOrder is reset when the wizard is initialized
    dispatch(resetVerificationOrder());
    dispatch(
      initializeWizard({
        stepData: [
          // Allow vspecs to vend multiple screens based upon the type of vsku
          {
            stepType: StepType['welcome'],
          },
          {
            stepType: StepType['auth.unauthenticated'],
          },
          ...vspecs.map(mapVspec).flat(),

          {
            stepType: StepType.reviewAndSubmit,
            stepParams: {
              vowId: endpointId,
              customerId,
            },
          },
        ],
        parameters,
      })
    );
  }, [vspecs]);

  React.useEffect(() => {
    if (isInitialized) {
      // Go to the first page
      navigate(stepKeys[0], { replace: true });
    }
  }, [isInitialized, stepKeys]);
  //

  return null;
};

export default InitializeWizard;

const mapVspec = (vspec: VspecEndpoint): StepData[] => {
  switch (vspec.vsku.type) {
    case VSkuType.field:
      return [
        {
          stepType: StepType['vsku.field'],
          stepParams: {
            vspecId: vspec.vspecId,
            vsku: vspec.vsku,
          },
        },
      ];
    case VSkuType.document:
      return [
        {
          stepType: StepType['vsku.document'],
          stepParams: {
            vspecId: vspec.vspecId,
            vsku: vspec.vsku,
          },
        },
      ];
    case VSkuType.mobileVerification:
      return [
        {
          stepType: StepType['vsku.mobile'],
          stepParams: {
            vspecId: vspec.vspecId,
            vsku: vspec.vsku,
          },
        },
      ];
    case VSkuType.businessInfoWizard:
      return buildBusinessInfoWizard(vspec);
    case VSkuType.eSign:
      return eSignWizard(vspec);
  }
};
