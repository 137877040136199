import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { Box, Button, Checkbox, Typography } from '@mui/material';

import {
  StepData,
  StepType,
  insertStep,
  removeStep,
  selectWizard,
  setStepFooter,
  setStepHeader,
} from '../wizardSlice';
import { useAppSelector } from '@hooks/useAppSelector';
import Mesh from '@shared/icons/Mesh';
import { selectEndpoint } from '@features/endpoint/endpointSlice';
import { ContentProps } from './Step';
import { skipButtonLabel } from './Footer';
import VerifyInput from '@shared/components/VerifyInput/VerifyInput';
import { EndpointType } from '@utils/schema';

const CustomHeader = () => {
  const endpointConfig = useAppSelector(selectEndpoint);
  const { customerLogo, customerName } = endpointConfig.customer;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        pt: '1rem',
      }}
    >
      <img src={customerLogo} height={24} />
      <Box
        sx={{
          height: '24px',
          width: '86px',
          pl: '1rem',
          display: 'flex',
        }}
      >
        <Mesh />
      </Box>
    </Box>
  );
};

const TestStep = ({ stepIndex, stepKey, currentStepData }: ContentProps) => {
  const dispatch = useAppDispatch();
  const { isInitialized, stepData, claims } = useAppSelector(selectWizard);
  const [enablePercent, setEnablePercent] = useState(
    currentStepData?.header?.hidePercentComplete !== undefined
      ? !currentStepData?.header?.hidePercentComplete
      : true
  );
  const [enableNext, setEnableNext] = useState(
    currentStepData?.footer?.disableNextButton !== undefined
      ? !currentStepData?.footer?.disableNextButton
      : true
  );
  const [enableLinks, setEnableLinks] = useState(
    currentStepData?.footer?.displayLinks !== undefined
      ? currentStepData?.footer?.displayLinks
      : false
  );
  const [allowSkip, setAllowSkip] = useState(false);

  const [customHeader, setCustomHeader] = useState(
    currentStepData?.header?.component !== undefined ? true : false
  );

  const togglePercent = () => {
    setEnablePercent(!enablePercent);
  };

  const toggleNext = () => {
    setEnableNext(!enableNext);
  };
  const toggleSkip = () => {
    setAllowSkip(!allowSkip);
  };

  const toggleLinks = () => {
    setEnableLinks(!enableLinks);
  };
  const toggleCustomHeader = () => {
    setCustomHeader(!customHeader);
  };

  useEffect(() => {
    if (isInitialized) {
      dispatch(
        setStepFooter({
          stepKey,
          footer: {
            hideNextButton: !enableNext,
            displayLinks: enableLinks,
            nextVariant: allowSkip ? 'outlined' : 'contained',
            nextLabel: allowSkip ? skipButtonLabel : undefined,
          },
        })
      );
    }
  }, [isInitialized, stepKey, enableNext, allowSkip, enableLinks]);

  useEffect(() => {
    if (isInitialized) {
      dispatch(
        setStepHeader({
          stepKey,
          header: {
            hidePercentComplete: !enablePercent,
            component: customHeader ? <CustomHeader /> : undefined,
          },
        })
      );
    }
  }, [isInitialized, stepKey, enablePercent, customHeader]);

  const duplicate = () => {
    dispatch(insertStep({ stepIndex, stepData: currentStepData }));
  };

  const remove = () => {
    dispatch(removeStep({ stepIndex }));
  };
  const [businessAddress, setBusinessAddress] = useState('');
  const [personalAddress, setPersonalAddress] = useState('');
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <h1>
          Step {stepIndex} - {stepKey}
        </h1>
        <VerifyInput
          input={{
            inputName: 'businessAddress',
            inputDescription: 'vsku.businessAddress.label',
            isRequired: true,
            inputType: EndpointType.AddressInput,
          }}
          value={businessAddress}
          setFieldValue={input => {
            console.log(`Address: ${input}`);
            setBusinessAddress(input);
          }}
          error={''}
        />
        <VerifyInput
          input={{
            inputName: 'personalAddress',
            inputDescription: 'Personal Address',
            isRequired: true,
            inputType: EndpointType.AddressInput,
          }}
          value={personalAddress}
          setFieldValue={input => {
            console.log(`Address: ${input}`);
            setPersonalAddress(input);
          }}
          error={''}
        />
        Percent Complete
        <Checkbox checked={enablePercent} onChange={togglePercent} />
        Custom Header
        <Checkbox checked={customHeader} onChange={toggleCustomHeader} />
        Next Button
        <Checkbox checked={enableNext} onChange={toggleNext} />
        Allow Skip
        <Checkbox checked={allowSkip} onChange={toggleSkip} />
        Footer Links
        <Checkbox checked={enableLinks} onChange={toggleLinks} />
        Current Step Type {StepType[currentStepData?.stepType]}
        <Button onClick={duplicate}>Duplicate</Button>
        <Button onClick={remove}>Remove</Button>
        <pre>{JSON.stringify(stepData, null, 2)}</pre>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
        <p>lorim ipsum</p>
      </Box>
    </>
  );
};

export default TestStep;
