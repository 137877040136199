import * as React from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { useIntl } from 'react-intl';

import { type EndpointInput } from '@utils/schema';

const VerifyInputPhone = ({
  input,
  value = '',
  error,
  setFieldValue,
}: {
  input: EndpointInput;
  value: string;
  error: string;
  setFieldValue: (value: string) => void;
}): JSX.Element => {
  const intl = useIntl();
  const [phoneNumber, setPhoneNumber] = React.useState(value);

  React.useEffect(() => {
    if (phoneNumber === '+1') {
      setFieldValue('');
    } else {
      setFieldValue(phoneNumber.replace(/ /g, ''));
    }
  }, [phoneNumber]);
  return (
    <MuiTelInput
      fullWidth
      error={!!error}
      helperText={error ? intl.formatMessage({ id: error }) : ''}
      label={intl.formatMessage({ id: input.inputDescription })}
      defaultCountry='US'
      value={phoneNumber}
      onChange={number => setPhoneNumber(number)}
      id={input.inputName}
      required={input.isRequired}
      name={input.inputName}
      autoComplete='phone'
    />
  );
};

export default VerifyInputPhone;
