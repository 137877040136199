import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  startSubmitPhoneNumber,
  finishSubmitPhoneNumber,
  startVerifyPhoneNumber,
  finishVerifyPhoneNumber,
  startNotifyPhoneNumber,
  finishNotifyPhoneNumber,
  errorMobileNumber,
  resendVerifyPhoneNumber,
} from './mobileNumberSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { defineMessages } from 'react-intl';
import {
  invalidCode,
  setUserAttributes,
  triggerPhoneNumberVerification,
  verifyPhoneNumber,
} from '../auth/authSaga';
import { selectAuthentication } from '../auth/authSlice';
import { makeGraphQlCall } from '@utils/graphql';

const messages = defineMessages({
  invalidPhoneNumber: {
    id: 'mobileNumber.invalidPhoneNumberError',
    defaultMessage: 'Invalid phone number',
    description: 'Error message when the user enters an invalid phone number',
  },
  tooManyAttempts: {
    id: 'mobileNumber.tooManyAttempts',
    defaultMessage: 'Too many attempts. Please try again later.',
    description:
      'Error message when the user sends too many text messages in a short time',
  },
  generic: {
    id: 'mobileNumber.genericError',
    defaultMessage:
      'There was an error. Please try again or contact support@mesh.id',
    description:
      'Generic error message when the user is trying to verify their phone number',
  },
});

const updateNotificationsMutation = `
mutation UpdateNotifications($input: NotificationInput = {notificationMethods: EMAIL, timezone: ""}) {
  updateNotifications(input: $input) {
    notifications {
      notificationMethod
      enabled
    }
    timezone
  }
}
`;

type UpdateNotificationsResponse = {
  updateNotifications: {
    notifications: [{ notificationMethod: string; enabled: boolean }];
    timezone: string;
  };
};

function* handleStartSubmit(action: PayloadAction<string>) {
  console.log('Starting submit mobile number', action.payload);
  try {
    const phoneNumber = action.payload.replace(/\s/g, '');
    yield call(setUserAttributes, { phoneNumber });
    yield call(triggerPhoneNumberVerification);
    yield put(finishSubmitPhoneNumber());
  } catch (e) {
    console.log('got error', e);
    if (e.message === 'Invalid phone number') {
      yield put(errorMobileNumber(messages.invalidPhoneNumber.id));
    } else if (e.code === 'LimitExceededException') {
      yield put(errorMobileNumber(messages.tooManyAttempts.id));
    } else {
      yield put(errorMobileNumber(messages.generic.id));
    }
  }
}
function* handleStartVerify(action: PayloadAction<string>) {
  try {
    console.log('starting verify mobile number', action.payload);
    yield call(verifyPhoneNumber, { confirmationCode: action.payload });
    yield put(finishVerifyPhoneNumber());
  } catch (e) {
    yield put(errorMobileNumber(invalidCode.id));
  }
}

function* handleStartNotify(action: PayloadAction<string>) {
  console.log('Starting notify mobile number', action.payload);
  try {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { accessToken } = selectAuthentication(yield select());
    const data: UpdateNotificationsResponse = yield call(makeGraphQlCall, {
      query: updateNotificationsMutation,
      variables: {
        input: {
          timezone: tz || 'American/Los_Angeles',
          notificationMethods: ['SMS', 'EMAIL'],
        },
      },
      accessToken: accessToken,
    });
    console.log('Got data', data);
    yield put(finishNotifyPhoneNumber());
  } catch (e) {
    console.log('got error', e);
    if (e.message === 'Invalid phone number') {
      yield put(errorMobileNumber(messages.invalidPhoneNumber.id));
    } else if (e.code === 'LimitExceededException') {
      yield put(errorMobileNumber(messages.tooManyAttempts.id));
    } else {
      yield put(errorMobileNumber(messages.generic.id));
    }
  }
}

function* handleResendVerify() {
  try {
    yield call(triggerPhoneNumberVerification);
  } catch (e) {
    yield put(errorMobileNumber(messages.generic.id));
  }
}

function* createMobileNumberSaga() {
  yield takeEvery(startSubmitPhoneNumber.type, handleStartSubmit);
  yield takeEvery(startVerifyPhoneNumber.type, handleStartVerify);
  yield takeEvery(resendVerifyPhoneNumber.type, handleResendVerify);
  yield takeEvery(startNotifyPhoneNumber.type, handleStartNotify);
}

export default createMobileNumberSaga;
