import * as React from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import {
  FieldVSku,
  MobileVsku,
  VSkuType,
} from '@features/endpoint/endpointSlice';

import VerifyInput from '@shared/components/VerifyInput/VerifyInput';
import type {
  EndpointInput,
  EndpointSchema,
  EndpointValidateResults,
} from '@utils/schema';
import { buildDynamicSchema } from '@utils/schema';
import { useEffect, useState } from 'react';
import { AnalyticsContext } from '@context/analyticsContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContentProps } from '@features/verification/VerificationWizard/components/Step';
import { useAppDispatch } from '@hooks/useAppDispatch';
import {
  PreventAction,
  StepType,
  gotoNextStep,
  insertStep,
  removeStep,
  selectWizard,
  setStepData,
  setStepFooter,
} from '@features/verification/VerificationWizard/wizardSlice';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@shared/components/Breadcrumbs';
import { useAppSelector } from '@hooks/useAppSelector';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import {
  clearVerified,
  selectMobileNumber,
  startSubmitPhoneNumber,
} from './mobileNumberSlice';

export const VerificationMobile = ({
  currentStepData,
  stepIndex,
  stepKey,
}: ContentProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const { logEvent } = React.useContext(AnalyticsContext);
  const { claims, parameters } = useAppSelector(selectWizard);
  const { stepParams, data } = currentStepData ?? {};
  const vsku = stepParams?.vsku as MobileVsku;
  const { vspecId, duplicated, breadcrumbs } = stepParams ?? {};

  const {
    phoneNumber: initialPhoneNumber,
    errorMessage,
    needsVerification,
    verified,
    isProcessing,
  } = useAppSelector(selectMobileNumber);

  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [authorizeSms, setAuthorizeSms] = React.useState(false);

  const hideNextButton = React.useMemo(
    () => !(matchIsValidTel(phoneNumber) && authorizeSms),
    [phoneNumber, authorizeSms]
  );

  const handleSubmit = React.useMemo(
    () => async () => {
      if (!hideNextButton) {
        console.log('submitting', phoneNumber);
        dispatch(startSubmitPhoneNumber(phoneNumber));
      }
      return PreventAction.yes;
    },
    [hideNextButton, dispatch]
  );

  useEffect(() => {
    if (needsVerification) {
      dispatch(
        insertStep({
          stepIndex: stepIndex + 1,
          stepData: {
            stepType: StepType['mobile.challenged'],
            stepParams: {
              vsku,
              vspecId,
              stepKey,
            },
            data: {},
          },
        })
      );
      dispatch(gotoNextStep({ stepKey }));
    }
  }, [needsVerification]);

  const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    dispatch(
      setStepFooter({
        stepKey,
        footer: {
          hideNextButton,
          nextLoading: isProcessing,
          onNext: handleSubmit,
        },
      })
    );
  }, [hideNextButton, isProcessing, handleSubmit]);

  return (
    <Stack flexGrow={1}>
      <Typography color='emphasis.dark' variant='h3' textAlign='center'>
        {intl.formatMessage({ id: vsku.nameId })}
      </Typography>
      <Typography variant='subtitle1' textAlign='center'>
        <FormattedMessage id={vsku.descriptionId} />
      </Typography>
      <Box mb={2.5} mt={4}>
        <Alert
          severity='info'
          variant='filled'
          icon={<InfoOutlined color='primary' fontSize='inherit' />}
        >
          <Typography color='emphasis.dark'>
            <FormattedMessage id={vsku.disclaimerId} />
          </Typography>
        </Alert>
      </Box>
      <Box mb={1}>
        <MuiTelInput
          fullWidth
          error={!!errorMessage}
          helperText={
            errorMessage ? intl.formatMessage({ id: errorMessage }) : ''
          }
          label={intl.formatMessage({
            description: 'Mobile number label',
            defaultMessage: 'Your mobile number',
          })}
          defaultCountry='US'
          onKeyDown={handleKey}
          value={phoneNumber}
          onChange={number => setPhoneNumber(number)}
          id='phoneNumber'
          required={true}
          name='phoneNumber'
          autoComplete='phone'
          autoFocus
        />
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={authorizeSms}
            onChange={event => setAuthorizeSms(event.target.checked)}
          />
        }
        label={
          <Typography variant='caption' color='text.secondary'>
            <FormattedMessage
              description='Mobile number confirmation message'
              defaultMessage='I agree my phone number will be verified. Standard message and data rates may apply.'
            />
          </Typography>
        }
      />
    </Stack>
  );
};
export default VerificationMobile;
