import { all } from 'redux-saga/effects';
import authSaga from '@features/verification/steps/auth/authSaga';
import mobileNumberSaga from '@features/verification/steps/mobile-number/mobileNumberSaga';
import endpointSaga from '@features/endpoint/endpointSaga';
import createVerificationOrderSaga from '@features/verification-order/verificationOrderSaga';
import esignSaga from '@features/esign/esignSaga';
import documentSaga from '@utils/document/documentSaga';
import addressSaga from '@shared/components/VerifyInput/fieldTypes/VerifyInputAddress/addressSaga';

export default function* rootSaga() {
  yield all([
    addressSaga(),
    authSaga(),
    createVerificationOrderSaga(),
    documentSaga(),
    endpointSaga(),
    esignSaga(),
    mobileNumberSaga(),
  ]);
}
