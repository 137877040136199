import * as React from 'react';
import { Button, IconButton, Stack, TextField } from '@mui/material';
import { useIntl } from 'react-intl';

import type { EndpointInput } from '@utils/schema';
import { RemoveIcon } from '@shared/icons';

const VerifyInputMultipleEmail = ({
  input,
  value = '',
  error,
  setFieldValue,
}: {
  input: EndpointInput;
  value: string;
  error: string;
  setFieldValue: (value: string) => void;
}): JSX.Element => {
  const intl = useIntl();
  const initialEmails = value?.split(';') ?? [''];
  const maxEmails = input.multiple?.max ?? 5;
  const [emails, setEmails] = React.useState(initialEmails);

  const handleAddEmail = () => {
    setEmails([...emails, '']);
  };

  const handleRemoveEmail = (index: number) => {
    const newEmails = emails.filter((_, i) => i !== index);
    setEmails(newEmails);
    setFieldValue(newEmails.join(';'));
  };

  const handleChangeEmail = (index: number, newValue: string) => {
    const newEmails = emails.map((email, i) =>
      i === index ? newValue : email
    );
    setEmails(newEmails);
    setFieldValue(newEmails.join(';'));
  };

  return (
    <Stack>
      {emails.map((email, index) => (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}
        >
          <TextField
            fullWidth
            label={intl.formatMessage({ id: input.inputDescription })}
            error={!!error}
            helperText={error && intl.formatMessage({ id: error })}
            id={input.inputName}
            required={input.isRequired}
            name={`${input.inputName}_${index}`}
            value={email}
            onChange={e => handleChangeEmail(index, e.target.value)}
            variant='outlined'
          />
          {index > 0 && (
            <IconButton
              onClick={() => handleRemoveEmail(index)}
              sx={{ p: '0.5rem 0 0.5rem 1rem' }}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </div>
      ))}
      {emails.length < maxEmails && (
        <Button onClick={handleAddEmail} sx={{ textTransform: 'none' }}>
          Add another insurance provider email
        </Button>
      )}
    </Stack>
  );
};

export default VerifyInputMultipleEmail;
