import React from 'react';

const InfoIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='none'
  >
    <path
      d='M10.0835 7.32894H11.9168V9.16227H10.0835V7.32894ZM10.0835 10.9956H11.9168V16.4956H10.0835V10.9956ZM11.0002 2.74561C5.94016 2.74561 1.8335 6.85227 1.8335 11.9123C1.8335 16.9723 5.94016 21.0789 11.0002 21.0789C16.0602 21.0789 20.1668 16.9723 20.1668 11.9123C20.1668 6.85227 16.0602 2.74561 11.0002 2.74561ZM11.0002 19.2456C6.95766 19.2456 3.66683 15.9548 3.66683 11.9123C3.66683 7.86977 6.95766 4.57894 11.0002 4.57894C15.0427 4.57894 18.3335 7.86977 18.3335 11.9123C18.3335 15.9548 15.0427 19.2456 11.0002 19.2456Z'
      fill='#0071F6'
      fillOpacity='0.8'
    />
  </svg>
);

export default InfoIcon;
