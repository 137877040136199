import * as React from 'react';

import VerifyInputText from './fieldTypes/VerifyInputText';
import VerifyInputBoolean from './fieldTypes/VerifyInputBoolean';
import VerifyInputDropdown from './fieldTypes/VerifyInputDropdown';
import type { EndpointInput } from '@utils/schema';
import { EndpointType } from '@utils/schema';
import VerifyInputDate from './fieldTypes/VerifyInputDate';
import VerifyInputMultipleEmail from './fieldTypes/VerifyInputMultipleEmail';
import VerifyInputPhone from './fieldTypes/VerifyInputPhone';
import VerifyInputAddress from './fieldTypes/VerifyInputAddress';

export const VerifyInput = ({
  input,
  value,
  error,
  setFieldValue,
}: {
  input: EndpointInput;
  value: any;
  error: string;
  setFieldValue: (value: any) => void;
}): JSX.Element => {
  switch (input.inputType) {
    case EndpointType.Hidden:
      return <></>;
    case EndpointType.TextInput:
      return (
        <VerifyInputText
          input={input}
          value={value}
          error={error}
          setFieldValue={setFieldValue}
        />
      );
    case EndpointType.BooleanInput:
      return (
        <VerifyInputBoolean
          input={input}
          value={value}
          setFieldValue={setFieldValue}
        />
      );
    case EndpointType.DropdownInput:
      return (
        <VerifyInputDropdown
          input={input}
          value={value}
          error={error}
          setFieldValue={setFieldValue}
        />
      );
    case EndpointType.DateInput:
      return (
        <VerifyInputDate
          input={input}
          value={value}
          error={error}
          setFieldValue={setFieldValue}
        />
      );
    case EndpointType.EmailInput:
      return (
        <VerifyInputMultipleEmail
          input={input}
          value={value}
          error={error}
          setFieldValue={setFieldValue}
        />
      );
    case EndpointType.PhoneInput:
      return (
        <VerifyInputPhone
          input={input}
          value={value}
          error={error}
          setFieldValue={setFieldValue}
        />
      );
    case EndpointType.AddressInput:
      return (
        <VerifyInputAddress
          input={input}
          value={value}
          error={error}
          setFieldValue={setFieldValue}
        />
      );
    default:
      return <div>Unsupported input type {input.inputType}</div>;
  }
};

export default VerifyInput;
