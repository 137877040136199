import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Card, CardContent, Container, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  selectEndpoint,
  startGetEndpoint,
  startGetEndpointByAlias,
} from './endpointSlice';
import { AppContext } from '@context/app.context';

const Endpoint = (props: React.PropsWithChildren): JSX.Element => {
  const navigate = useNavigate();
  const { alias, endpoint } = useLoaderData() as {
    alias: string;
    endpoint: string;
  };
  const dispatch = useAppDispatch();
  const endpointConfig = useAppSelector(selectEndpoint);
  const { setIsLoading } = useContext(AppContext);

  React.useEffect(() => {
    if (endpoint && typeof endpoint === 'string') {
      dispatch(startGetEndpoint(endpoint));
    }
  }, [endpoint]);

  React.useEffect(() => {
    if (alias && typeof alias === 'string') {
      dispatch(startGetEndpointByAlias(alias));
    }
  }, [alias]);

  useEffect(() => {
    setIsLoading(endpointConfig.isLoading);
  }, [endpointConfig.isLoading]);

  if (!endpointConfig.isLoading && !endpointConfig.endpointLoaded) {
    return (
      <Container component='main' maxWidth='xs'>
        <Card sx={{ marginTop: '2rem' }}>
          <CardContent>
            <Typography variant='h5' component='div' paddingBottom='1rem'>
              <FormattedMessage
                description='Card Header for an invalid endpoint error message'
                defaultMessage='Invalid submission link'
              />
            </Typography>
            <Typography>
              <FormattedMessage
                description='Error message for an invalid endpoint'
                defaultMessage='Please check the link and try again or email support@mesh.id for assistance'
              />
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );
  }
  return <>{props.children}</>;
};

export default Endpoint;
