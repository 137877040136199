declare global {
  var onUsersnapCXLoad: (api: any) => void; // eslint-disable-line no-var
}

import React, { useEffect, useState, useContext, ReactElement } from 'react';

import config from '@utils/config';

export const UsersnapContext = React.createContext<UserSnapType | null>(null);
type UserSnapType = any;
export const UsersnapProvider = ({
  initParams = {},
}: {
  initParams: {
    mountNode?: ReactElement;
    user?: { email: string };
    custom?: { endpointId: string; customerId: string; customerName: string };
  };
}) => {
  const [usersnapApi, setUsersnapApi] = useState<UserSnapType>(null);

  useEffect(() => {
    let usersnapApi: any = null;
    window.onUsersnapCXLoad = function (api: any) {
      api.init(initParams);
      usersnapApi = api;
      setUsersnapApi(api);
    };
    const script = document.createElement('script');
    script.defer = true;
    script.src = `https://widget.usersnap.com/embed/load/${config.userSnapApiKey}?onload=onUsersnapCXLoad`;
    document.head.appendChild(script);

    return () => {
      script.remove();
    };
  }, []);

  return <UsersnapContext.Provider value={usersnapApi} />;
};

export function useUsersnapApi() {
  return useContext(UsersnapContext);
}
