import { call, put, select, takeEvery } from 'redux-saga/effects';
import { jsPDF } from 'jspdf';
import { v4 as uuid } from 'uuid';
import { makeGraphQlCall } from '../../utils/graphql';
import {
  startUploadingDocument,
  finishUploadingDocument,
  errorUploadingDocument,
  type StartUploading,
  startProcessingImages,
  StartProcessingImages,
} from './documentSlice';
import { selectAuthentication } from '@features/verification/steps/auth/authSlice';
import { PayloadAction } from '@reduxjs/toolkit';

// These are the dimensions of a letter page in mm (the default unit for jsPDF)
const PAGE_WIDTH = 215;
const PAGE_HEIGHT = 279;
// We only support PDF's for now. If images are uploaded we convert them to PDFs
const CONTENT_TYPE = 'application/pdf';

const prepareDocumentUploadsMutation = `mutation PrepareDocumentUploads($uploads: [DocumentUploadInput!]) {
  prepareDocumentUploads(uploads: $uploads) {
    documentId
    uploadUrl
  }
}`;

const uploadDocument = async ({
  uploadUrl,
  contentType,
  document,
}: {
  uploadUrl: string;
  contentType: string;
  document: File;
}) => {
  await fetch(uploadUrl, {
    method: 'PUT',
    headers: {
      'content-type': contentType,
    },
    body: document,
  });
  return 'Done';
};

const readFile = (file: File): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    // Create file reader
    const reader = new FileReader();

    // Register event listeners
    reader.addEventListener('loadend', e => {
      const result = e.target?.result as string;
      const output = new Image();
      output.src = result;
      resolve(output);
    });
    reader.addEventListener('error', reject);

    // Read file
    reader.readAsDataURL(file);
  });
};

function* handleStartUploading(action: PayloadAction<StartUploading>) {
  try {
    const { accessToken } = selectAuthentication(yield select());
    const documentId = uuid();
    const contentType = CONTENT_TYPE;
    const { fileName, document } = action.payload;
    // First we create the uploadUrl
    const data: { prepareDocumentUploads: [{ uploadUrl: string }] } =
      yield call(makeGraphQlCall, {
        query: prepareDocumentUploadsMutation,
        variables: {
          uploads: [
            {
              documentId,
              fileName,
              contentType,
            },
          ],
        },
        accessToken: accessToken,
      });
    const [{ uploadUrl }] = data.prepareDocumentUploads;
    // Then we upload the file to the uploadUrl using the S3 url
    yield call(uploadDocument, { uploadUrl, contentType, document });
    // And finally we call it finished
    yield put(finishUploadingDocument(documentId));
  } catch (e) {
    yield put(errorUploadingDocument(e.message));
  }
}

function* handleStartProcessingImages(
  action: PayloadAction<StartProcessingImages>
) {
  const pdf = new jsPDF({
    orientation: 'portrait',
    format: 'letter',
  });
  const { images } = action.payload;
  for (let i = 0; i < images.length; i++) {
    const file = images[i];
    const imageData: HTMLImageElement = yield call(readFile, file);
    // Now perform some scaling so that we don't distort the image
    // We use the larger of the width or height scaling factor to not distort the image
    const scalingFactor = Math.max(
      imageData.naturalWidth / PAGE_WIDTH,
      imageData.naturalHeight / PAGE_HEIGHT
    );
    pdf.addImage({
      imageData,
      x: 0,
      y: 0,
      width: imageData.naturalWidth / scalingFactor,
      height: imageData.naturalHeight / scalingFactor,
      compression: 'SLOW',
    });

    if (i !== images.length - 1) {
      pdf.addPage();
    }
  }
  const output = pdf.output('blob');
  // This fileName isn't great, but it's recognizable when a user selects multiple images
  const fileName = `${images.map(i => i.name).join('|')}.pdf`;
  const document = new File([output], fileName, {
    type: CONTENT_TYPE,
    lastModified: new Date().getTime(),
  });
  yield put(
    startUploadingDocument({
      fileName,
      document,
    })
  );
}

function* documentSaga() {
  yield takeEvery(startUploadingDocument.type, handleStartUploading);
  yield takeEvery(startProcessingImages.type, handleStartProcessingImages);
}

export default documentSaga;
