import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

export const EmbeddedTester = () => {
  const [linkType, setLinkType] = useState<'alias' | 'vowId'>('alias');
  const [target, setTarget] = useState<string>('');
  const [messages, setMessages] = useState<string[]>([]);
  const [useOverlay, setUseOverlay] = useState<boolean>(true);
  const [backgroundColor, setBackgroundColor] =
    useState<string>('background.default');

  const handleSetColor = (event: SelectChangeEvent) => {
    setBackgroundColor(event.target.value as string);
  };
  const openWorkflow = useMemo(() => {
    return () => {
      setMessages([]);
      init_mesh_verification({
        target,
        linkType,
        id: '',
        useOverlay,
        host: window.location.origin,
        onEvent({ event, data }: { event: string; data: any }) {
          console.log('got event', event, data);
          setMessages(messages => [
            ...messages,
            JSON.stringify({ event, data }),
          ]);
        },
      });
    };
  }, [linkType, target, messages, useOverlay]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${window.location.origin}/assets/mesh-verification.js`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor,
      }}
    >
      <Card>
        <CardContent>
          <Stack direction='column' spacing={4}>
            <FormControl>
              <FormLabel id='linkType-label'>Alias or vowId?</FormLabel>
              <RadioGroup
                row
                aria-labelledby='linkType-label'
                name='linkType'
                value={linkType}
                onChange={e => setLinkType(e.target.value as 'alias' | 'vowId')}
              >
                <FormControlLabel
                  value='alias'
                  control={<Radio />}
                  label='Alias'
                />
                <FormControlLabel
                  value='vowId'
                  control={<Radio />}
                  label='VowId'
                />
              </RadioGroup>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useOverlay}
                  onChange={e => setUseOverlay(e.target.checked)}
                />
              }
              label='Display overlay?'
            />
            <FormControl fullWidth>
              <InputLabel id='background-color-label'>
                Background Color
              </InputLabel>
              <Select
                labelId='background-color-label'
                id='background-color-select'
                value={backgroundColor}
                label='Background Color'
                onChange={handleSetColor}
              >
                <MenuItem value='background.default'>
                  Mesh Default Background (pale blue)
                </MenuItem>
                <MenuItem value='palegreen'>Pale Green</MenuItem>
                <MenuItem value='blue'>Blue</MenuItem>
                <MenuItem value='white'>White</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id='target-field'
              label={linkType}
              variant='outlined'
              value={target}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTarget(event.target.value);
              }}
            />

            <Button variant='contained' color='primary' onClick={openWorkflow}>
              Open Workflow
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography>
            Messages received from the popup will be displayed here
          </Typography>
          <Stack direction='column' spacing={4} id='messages'>
            {messages.map((message, idx) => (
              <Box key={`message-${idx}`}>
                <Typography>{message}</Typography>
              </Box>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
