import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../config/store';
import { AutocompleteAddressOption } from './addressSaga';

export type AddressComponents = {
  address1: string;
  address2?: string;
  city: string;
  primaryNumber?: string;
  streetName?: string;
  state: string;
  postalCode: string;
  lastLine?: string;
  externalId?: string;
  country: string;
  coordinates?: {
    latitude: number;
    longitude: number;
    precision: string;
  };
};

export type AddressRequest = {
  fullAddress: string;
  components?: AddressComponents;
};

export type AddressDetails = AddressRequest & {
  encodedAddress: string;
};

export type AddressState = {
  isLoading: boolean;
  selected: boolean;
  addressText: string;
  options: AutocompleteAddressOption[];
  selectedAddress?: AddressDetails;
  errorMessage: string;
  target: string;
};

export type StartSelectAddressPayload = {
  addressRequest: AddressRequest;
  target: string;
};

const initialState: AddressState = {
  isLoading: false,
  selected: false,
  addressText: '',
  options: [],
  selectedAddress: null,
  errorMessage: '',
  target: '',
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    startAutoCompleteAddress: (state, action: PayloadAction<string>): void => {
      state.addressText = action.payload;
      state.errorMessage = '';
    },
    finishAutoCompleteAddress: (
      state,
      action: PayloadAction<AutocompleteAddressOption[]>
    ): void => {
      state.options = action.payload;
      state.errorMessage = '';
    },
    errorAutoCompleteAddress: (state, action: PayloadAction<string>): void => {
      state.errorMessage = action.payload;
    },
    startSelectAddress: (
      state,
      action: PayloadAction<StartSelectAddressPayload>
    ): void => {
      state.isLoading = true;
      state.selected = false;
      state.selectedAddress = null;
      state.errorMessage = '';
      state.options = [];
      state.target = action.payload.target;
    },
    finishSelectAddress: (
      state,
      action: PayloadAction<AddressDetails>
    ): void => {
      state.isLoading = false;
      state.selected = true;
      state.selectedAddress = action.payload;
      state.errorMessage = '';
    },
    errorSelectAddress: (state, action: PayloadAction<string>): void => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      state.selected = false;
      state.target = '';
    },
    completeSelection: (state): void => {
      state.selected = false;
      state.target = '';
    },
  },
});

export const {
  startAutoCompleteAddress,
  finishAutoCompleteAddress,
  errorAutoCompleteAddress,
  startSelectAddress,
  finishSelectAddress,
  errorSelectAddress,
  completeSelection,
} = addressSlice.actions;

export const selectAddress = (state: RootState) => state.address;

export default addressSlice.reducer;
