import * as React from 'react';
import { Box, Typography, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { defineMessages, useIntl } from 'react-intl';
import { selectWizard } from '@features/verification/VerificationWizard/wizardSlice';
import { useAppSelector } from '@hooks/useAppSelector';

interface BrumdcrumbsProps {
  fields: string[];
}

const Breadcrumbs = ({ fields = [] }: BrumdcrumbsProps): JSX.Element => {
  const { claims } = useAppSelector(selectWizard);
  const intl = useIntl();
  const values = fields.map(field => claims[field]).filter(v => v);

  if (!values || values.length === 0) {
    return null;
  }
  return (
    <Box
      sx={{ backgroundColor: 'grey.100' }}
      padding={1}
      borderRadius={1}
      marginBottom={1}
    >
      <MuiBreadcrumbs separator='›' aria-label='breadcrumb'>
        {values.map((value, index) =>
          values.length - 1 === index ? (
            <Typography
              key={`breadcrumb-${value}`}
              variant='body2'
              color='emphasis.dark'
              fontWeight='bold'
            >
              {intl.formatMessage({ id: `breadcrumbs.${value}` })}
            </Typography>
          ) : (
            <Typography
              key={`breadcrumb-${value}`}
              variant='body2'
              color='emphasis.dark'
            >
              {intl.formatMessage({ id: `breadcrumbs.${value}` })}
            </Typography>
          )
        )}
      </MuiBreadcrumbs>
    </Box>
  );
};

defineMessages({
  individual: {
    id: 'breadcrumbs.individual',
    description: 'Breadcrumb for individual',
    defaultMessage: 'Individual',
  },
  soleProprietor: {
    id: 'breadcrumbs.soleProprietor',
    description: 'Breadcrumb for soleProprietor',
    defaultMessage: 'Sole proprietor',
  },
  singleMemberLlc: {
    id: 'breadcrumbs.singleMemberLlc',
    description: 'Breadcrumb for singleMemberLlc',
    defaultMessage: 'Single-member LLC',
  },
  socialSecurityNumber: {
    id: 'breadcrumbs.socialSecurityNumber',
    description: 'Breadcrumb for socialSecurityNumber',
    defaultMessage: 'SSN',
  },
  employerIdentificationNumber: {
    id: 'breadcrumbs.employerIdentificationNumber',
    description: 'Breadcrumb for employerIdentificationNumber',
    defaultMessage: 'EIN',
  },
  business: {
    id: 'breadcrumbs.business',
    description: 'Breadcrumb for business',
    defaultMessage: 'Business',
  },
  limitedLiabilityCompany: {
    id: 'breadcrumbs.limitedLiabilityCompany',
    description: 'Breadcrumb for limitedLiabilityCompany',
    defaultMessage: 'LLC',
  },
  partnership: {
    id: 'breadcrumbs.partnership',
    description: 'Breadcrumb for partnership',
    defaultMessage: 'Partnership',
  },
  cCorporation: {
    id: 'breadcrumbs.cCorporation',
    description: 'Breadcrumb for cCorporation',
    defaultMessage: 'C Corporation',
  },
  sCorporation: {
    id: 'breadcrumbs.sCorporation',
    description: 'Breadcrumb for sCorporation',
    defaultMessage: 'S Corporation',
  },
  trustOrEstate: {
    id: 'breadcrumbs.trustOrEstate',
    description: 'Breadcrumb for trustOrEstate',
    defaultMessage: 'Trust or Estate',
  },
  /*
  
  



  : {
    id: 'breadcrumbs.',
    description: 'Breadcrumb for ',
    defaultMessage: '',
  },
  : {
    id: 'breadcrumbs.',
    description: 'Breadcrumb for ',
    defaultMessage: '',
  },
  : {
    id: 'breadcrumbs.',
    description: 'Breadcrumb for ',
    defaultMessage: '',
  },
  */
});

export default Breadcrumbs;
