import * as React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import MoneyIcon from '@mui/icons-material/Money';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import { VskuField, VspecEndpoint } from '@features/endpoint/endpointSlice';
import { StepData, StepType } from '../wizardSlice';
import { defineMessages } from 'react-intl';
import { EndpointMaskType } from '@utils/schema';

const FIELDS: Record<string, VskuField> = {
  businessName: {
    nameId: 'vsku.businessName.label',
    errorId: 'vsku.businessName.label.error',
    isRequired: true,
    fieldName: 'businessName',
    label: 'Legal Name',
    isPrimary: true,
    isDerived: null,
    dataType: 'string',
    options: {
      values: null,
    },
  },
  socialSecurityNumber: {
    nameId: 'vsku.socialSecurityNumber.label',
    errorId: 'vsku.socialSecurityNumber.label.error',
    isRequired: true,
    fieldName: 'taxIdentificationNumber',
    label: 'SSN',
    isPrimary: false,
    isDerived: null,
    dataType: 'string',
    options: {
      values: null,
      maskType: EndpointMaskType.ssn,
    },
  },
  employeeIdentificationNumber: {
    nameId: 'vsku.employeeIdentificationNumber.label',
    errorId: 'vsku.employeeIdentificationNumber.label.error',
    isRequired: true,
    fieldName: 'taxIdentificationNumber',
    label: 'EIN',
    isPrimary: false,
    isDerived: null,
    dataType: 'string',
    options: {
      values: null,
      maskType: EndpointMaskType.ein,
    },
  },
  businessAddress: {
    nameId: 'vsku.businessAddress.label',
    errorId: 'vsku.businessAddress.label.error',
    isRequired: true,
    fieldName: 'businessAddress',
    label: 'Business Address',
    isPrimary: false,
    isDerived: null,
    dataType: 'address',
    options: {
      values: null,
    },
  },
  disregardedEntityName: {
    nameId: 'vsku.disregardedEntityName.label',
    errorId: 'vsku.disregardedEntityName.label.error',
    isRequired: false,
    fieldName: 'disregardedEntityName',
    label: 'Disregarded Entity Name',
    isPrimary: false,
    isDerived: null,
    dataType: 'string',
    options: {
      values: null,
    },
  },

  taxIdentificationNumberType: {
    nameId: 'vsku.taxIdentificationNumberType.label',
    errorId: 'vsku.taxIdentificationNumberType.label.error',
    fieldName: 'taxIdentificationNumberType',
    label: 'Tax Identification Number Type',
    isPrimary: false,
    isRequired: true,
    isHidden: true,
    dataType: 'string',
    options: {
      copyClaim: 'taxIdentificationNumberType',
    },
  },
  businessType: {
    nameId: 'vsku.businessType.label',
    errorId: 'vsku.businessType.label.error',
    fieldName: 'businessType',
    label: 'Business Type',
    isPrimary: false,
    isRequired: true,
    isHidden: true,
    dataType: 'string',
    options: {
      copyClaim: 'businessType',
    },
  },
  taxClassification: {
    nameId: 'vsku.taxClassification.label',
    errorId: 'vsku.taxClassification.label.error',
    fieldName: 'taxClassification',
    label: 'Tax Classification',
    isPrimary: false,
    isRequired: false,
    isHidden: true,
    dataType: 'string',
    options: {
      copyClaim: 'taxClassification',
    },
  },
};

export const buildBusinessInfoWizard = (vspec: VspecEndpoint): StepData[] => [
  // Step 1 - Base Business Type
  {
    stepType: StepType['vsku.selection'],
    stepParams: {
      vsku: {
        nameId: 'v-bus-info-wizard@1.0-name',
        descriptionId: 'v-bus-info-wizard@1.0-description',
      },
      options: [
        {
          icon: <PersonIcon />,
          headerId: 'vsku.businessInfoWizard.individual.header',
          subheaderId: 'vsku.businessInfoWizard.individual.subheader',
          descriptionId: 'vsku.businessInfoWizard.individual.description',
          claims: {
            baseBusinessType: 'individual',
            taxClassification: null,
          },
        },
        {
          icon: <ApartmentIcon />,
          headerId: 'vsku.businessInfoWizard.business.header',
          subheaderId: 'vsku.businessInfoWizard.business.subheader',
          descriptionId: 'vsku.businessInfoWizard.business.description',
          claims: {
            baseBusinessType: 'business',
            taxIdentificationNumberType: 'employerIdentificationNumber',
            disregardedEntityName: null,
          },
        },
        {
          icon: <HomeWorkIcon />,
          headerId: 'vsku.businessInfoWizard.trustOrEstate.header',
          descriptionId: 'vsku.businessInfoWizard.trustOrEstate.description',
          claims: {
            baseBusinessType: 'trustOrEstate',
            businessType: 'trustOrEstate',
            taxIdentificationNumberType: 'employerIdentificationNumber',
            taxClassification: null,
          },
        },
      ],
    },
  },
  // Step 2 - Business Type
  {
    stepType: StepType['vsku.selection'],
    stepParams: {
      breadcrumbs: ['baseBusinessType'],
      condition: {
        baseBusinessType: 'individual',
      },
      vsku: {
        nameId: 'v-bus-info-wizard@1.0-name',
        descriptionId: 'v-bus-info-wizard@1.0-description',
      },
      options: [
        {
          icon: <PersonIcon />,
          headerId: 'vsku.businessInfoWizard.soleProprietor.header',
          descriptionId: 'vsku.businessInfoWizard.soleProprietor.description',
          claims: {
            businessType: 'soleProprietor',
          },
        },
        {
          icon: <ViewHeadlineIcon />,
          headerId: 'vsku.businessInfoWizard.singleMemberLlc.header',
          descriptionId: 'vsku.businessInfoWizard.singleMemberLlc.description',
          claims: {
            businessType: 'singleMemberLlc',
          },
        },
      ],
    },
  },
  {
    stepType: StepType['vsku.selection'],
    stepParams: {
      breadcrumbs: ['baseBusinessType'],
      condition: {
        baseBusinessType: 'business',
      },
      vsku: {
        nameId: 'v-bus-info-wizard@1.0-name',
        descriptionId: 'v-bus-info-wizard@1.0-description',
      },
      options: [
        {
          icon: <PersonIcon />,
          headerId: 'vsku.businessInfoWizard.limitedLiabilityCompany.header',
          claims: {
            businessType: 'limitedLiabilityCompany',
          },
        },
        {
          icon: <ApartmentIcon />,
          headerId: 'vsku.businessInfoWizard.partnership.header',
          claims: {
            businessType: 'partnership',
            taxClassification: null,
          },
        },
        {
          icon: <ApartmentIcon />,
          headerId: 'vsku.businessInfoWizard.cCorporation.header',
          claims: {
            businessType: 'cCorporation',
            taxClassification: null,
          },
        },
        {
          icon: <PersonIcon />,
          headerId: 'vsku.businessInfoWizard.sCorporation.header',
          claims: {
            businessType: 'sCorporation',
            taxClassification: null,
          },
        },
      ],
    },
  },
  // Step 3 - Tax Classification
  {
    stepType: StepType['vsku.selection'],
    stepParams: {
      breadcrumbs: ['baseBusinessType', 'businessType'],
      condition: {
        businessType: 'limitedLiabilityCompany',
      },
      vsku: {
        nameId: 'v-bus-info-wizard@1.0-name',
        descriptionId: 'vsku.businessInfoWizard.taxClassification.description',
      },
      options: [
        {
          icon: <PersonIcon />,
          headerId: 'vsku.businessInfoWizard.partnership.header',
          descriptionId: 'vsku.businessInfoWizard.partnership.description',
          claims: {
            taxClassification: 'partnership',
          },
        },
        {
          icon: <ApartmentIcon />,
          headerId: 'vsku.businessInfoWizard.cCorporation.header',
          // descriptionId: 'vsku.businessInfoWizard.cCorporation.description',
          claims: {
            taxClassification: 'cCorporation',
          },
        },
        {
          icon: <ApartmentIcon />,
          headerId: 'vsku.businessInfoWizard.sCorporation.header',
          // descriptionId: 'vsku.businessInfoWizard.sCorporation.description',
          claims: {
            taxClassification: 'sCorporation',
          },
        },
      ],
    },
  },
  // Step 4 - Tax Identification Number Type
  {
    stepType: StepType['vsku.selection'],
    stepParams: {
      breadcrumbs: ['baseBusinessType', 'businessType'],
      condition: {
        baseBusinessType: 'individual',
      },
      vsku: {
        nameId: 'v-bus-info-wizard@1.0-name',
        descriptionId: 'vsku.businessInfoWizard.taxType.description',
      },
      options: [
        {
          label: 'SSN',
          icon: <MoneyIcon />,
          headerId: 'vsku.businessInfoWizard.socialSecurityNumber.header',
          // descriptionId:
          //   'vsku.businessInfoWizard.socialSecurityNumber.description',
          claims: {
            taxIdentificationNumberType: 'socialSecurityNumber',
          },
        },
        {
          label: 'EIN',
          icon: <AssignmentIcon />,
          headerId:
            'vsku.businessInfoWizard.employerIdentificationNumber.header',
          // descriptionId:
          //   'vsku.businessInfoWizard.employerIdentificationNumber.description',
          claims: {
            taxIdentificationNumberType: 'employerIdentificationNumber',
          },
        },
      ],
    },
  },
  // Step 5 - Business Info Fields
  {
    stepType: StepType['vsku.field'],
    stepParams: {
      breadcrumbs: [
        'baseBusinessType',
        'businessType',
        'taxClassification',
        'taxIdentificationNumberType',
      ],
      condition: {
        baseBusinessType: 'individual',
        taxIdentificationNumberType: 'socialSecurityNumber',
      },
      vspecId: vspec.vspecId,
      vsku: {
        ...vspec.vsku,
        inputClaims: [
          FIELDS.businessName,
          FIELDS.socialSecurityNumber,
          FIELDS.businessAddress,
          FIELDS.disregardedEntityName,
          FIELDS.taxIdentificationNumberType,
          FIELDS.businessType,
        ],
      },
    },
  },
  {
    stepType: StepType['vsku.field'],
    stepParams: {
      breadcrumbs: [
        'baseBusinessType',
        'businessType',
        'taxClassification',
        'taxIdentificationNumberType',
      ],
      condition: {
        baseBusinessType: 'individual',
        taxIdentificationNumberType: 'employerIdentificationNumber',
      },
      vspecId: vspec.vspecId,
      vsku: {
        ...vspec.vsku,
        inputClaims: [
          FIELDS.businessName,
          FIELDS.employeeIdentificationNumber,
          FIELDS.businessAddress,
          FIELDS.disregardedEntityName,
          FIELDS.taxIdentificationNumberType,
          FIELDS.businessType,
        ],
      },
    },
  },
  {
    stepType: StepType['vsku.field'],
    stepParams: {
      breadcrumbs: ['baseBusinessType', 'businessType', 'taxClassification'],
      condition: {
        baseBusinessType: 'business',
      },
      vspecId: vspec.vspecId,
      vsku: {
        ...vspec.vsku,
        inputClaims: [
          FIELDS.businessName,
          FIELDS.employeeIdentificationNumber,
          FIELDS.businessAddress,
          FIELDS.disregardedEntityName,
          FIELDS.taxIdentificationNumberType,
          FIELDS.businessType,
          FIELDS.taxClassification,
        ],
      },
    },
  },
  {
    stepType: StepType['vsku.field'],
    stepParams: {
      breadcrumbs: ['baseBusinessType'],
      condition: {
        baseBusinessType: 'trustOrEstate',
      },
      vspecId: vspec.vspecId,
      vsku: {
        ...vspec.vsku,
        inputClaims: [
          FIELDS.businessName,
          FIELDS.employeeIdentificationNumber,
          FIELDS.businessAddress,
          FIELDS.disregardedEntityName,
          FIELDS.taxIdentificationNumberType,
          FIELDS.businessType,
        ],
      },
    },
  },
];

defineMessages({
  businessInfoHeader: {
    id: 'v-bus-info-wizard@1.0-name',
    description: 'Header for businessInfoWizard',
    defaultMessage: 'Your business',
  },
  businessInfoTitle: {
    id: 'v-bus-info-wizard@1.0-title',
    description: 'Title for businessInfoWizard',
    defaultMessage: 'Business Info',
  },
  businessInfoDescription: {
    id: 'v-bus-info-wizard@1.0-description',
    description: 'Description for businessInfoWizard',
    defaultMessage:
      'Which of the following options best describes your business?',
  },
  taxTypeDescription: {
    id: 'vsku.businessInfoWizard.taxType.description',
    description: 'Description for taxType businessInfoWizard',
    defaultMessage: 'How does your business file its federal income taxes?',
  },
  businessDetailsDescription: {
    id: 'vsku.businessInfoWizard.businessDetails.description',
    description: 'Description for businessDetails businessInfoWizard',
    defaultMessage:
      'Provide your business details exactly as shown on your federal income tax return',
  },
  taxClassificationDescription: {
    id: 'vsku.businessInfoWizard.taxClassification.description',
    description: 'Description for taxClassification businessInfoWizard',
    defaultMessage: 'Enter the tax classification with the IRS',
  },

  individualHeader: {
    id: 'vsku.businessInfoWizard.individual.header',
    description: 'Header for individual within businessInfoWizard',
    defaultMessage: 'Individual',
  },
  individualSubHeader: {
    id: 'vsku.businessInfoWizard.individual.subheader',
    description: 'Subheader for individual within businessInfoWizard',
    defaultMessage: 'Sole proprietor, Single-member LLC',
  },
  individualDescription: {
    id: 'vsku.businessInfoWizard.individual.description',
    description: 'Description for individual within businessInfoWizard',
    defaultMessage:
      'Select if you operate your business (and file federal income tax for it) under your legal name.',
  },
  businessHeader: {
    id: 'vsku.businessInfoWizard.business.header',
    description: 'Header for  within businessInfoWizard',
    defaultMessage: 'Business',
  },
  businessSubHeader: {
    id: 'vsku.businessInfoWizard.business.subheader',
    description: 'Subheader for business within businessInfoWizard',
    defaultMessage: 'Corporation, LLC, Partnership',
  },
  businessDescription: {
    id: 'vsku.businessInfoWizard.business.description',
    description: 'Description for business within businessInfoWizard',
    defaultMessage:
      'Select if your business files federal taxes under its legal name with an Employee Identification Number (EIN)',
  },
  trustOrEstateHeader: {
    id: 'vsku.businessInfoWizard.trustOrEstate.header',
    description: 'Header for trustOrEstate within businessInfoWizard',
    defaultMessage: 'Trust or Estate',
  },
  trustOrEstateDescription: {
    id: 'vsku.businessInfoWizard.trustOrEstate.description',
    description: 'Description for trustOrEstate within businessInfoWizard',
    defaultMessage:
      'Select for an estate, or domestic trust (per IRS Regulations section 301.7701-7).',
  },
  soleProprietorHeader: {
    id: 'vsku.businessInfoWizard.soleProprietor.header',
    description: 'Header for soleProprietor within businessInfoWizard',
    defaultMessage: 'Sole proprietor',
  },
  soleProprietorDescription: {
    id: 'vsku.businessInfoWizard.soleProprietor.description',
    description: 'Description for soleProprietor within businessInfoWizard',
    defaultMessage:
      'Select if the business is unincorporated and owned entirely by one person.',
  },

  singleMemberLlcHeader: {
    id: 'vsku.businessInfoWizard.singleMemberLlc.header',
    description: 'Header for singleMemberLlc within businessInfoWizard',
    defaultMessage: 'Single-member LLC',
  },
  singleMemberLlcDescription: {
    id: 'vsku.businessInfoWizard.singleMemberLlc.description',
    description: 'Description for singleMemberLlc within businessInfoWizard',
    defaultMessage:
      'Select if the business is a Limited Liability Company (LLC) with only one member.',
  },
  limitedLiabilityCompanyHeader: {
    id: 'vsku.businessInfoWizard.limitedLiabilityCompany.header',
    description: 'Header for limitedLiabilityCompany within businessInfoWizard',
    defaultMessage: 'Limited Liability Company (LLC)',
  },
  partnershipHeader: {
    id: 'vsku.businessInfoWizard.partnership.header',
    description: 'Header for partnership within businessInfoWizard',
    defaultMessage: 'Partnership',
  },
  partnershipDescription: {
    id: 'vsku.businessInfoWizard.partnership.description',
    description: 'Description for partnership within businessInfoWizard',
    defaultMessage:
      'This is the default tax classification for LLCs. A multi-member LLC is classified as a partnership for federal income tax purposes, unless it filed Form 8832 and electred to be treated as a corporation.',
  },
  cCorporationHeader: {
    id: 'vsku.businessInfoWizard.cCorporation.header',
    description: 'Header for cCorporation within businessInfoWizard',
    defaultMessage: 'C Corporation',
  },
  cCorporationDescription: {
    id: 'vsku.businessInfoWizard.cCorporation.description',
    description: 'Description for cCorporation within businessInfoWizard',
    defaultMessage:
      'Lorum ipsum dolor sir amet consectetur Sember sed dui ipsum vitae integer',
  },
  sCorporationHeader: {
    id: 'vsku.businessInfoWizard.sCorporation.header',
    description: 'Header for sCorporation within businessInfoWizard',
    defaultMessage: 'S Corporation',
  },
  sCorporationDescription: {
    id: 'vsku.businessInfoWizard.sCorporation.description',
    description: 'Description for sCorporation within businessInfoWizard',
    defaultMessage:
      'Lorum ipsum dolor sir amet consectetur Sember sed dui ipsum vitae integer',
  },
  socialSecurityNumberHeader: {
    id: 'vsku.businessInfoWizard.socialSecurityNumber.header',
    description: 'Header for socialSecurityNumber within businessInfoWizard',
    defaultMessage: 'SSN',
  },
  socialSecurityNumberDescription: {
    id: 'vsku.businessInfoWizard.socialSecurityNumber.description',
    description:
      'Description for socialSecurityNumber within businessInfoWizard',
    defaultMessage:
      'Lorum ipsum dolor sir amet consectetur Sember sed dui ipsum vitae integer',
  },
  employerIdentificationNumberHeader: {
    id: 'vsku.businessInfoWizard.employerIdentificationNumber.header',
    description:
      'Header for employerIdentificationNumber within businessInfoWizard',
    defaultMessage: 'EIN',
  },
  employerIdentificationNumberDescription: {
    id: 'vsku.businessInfoWizard.employerIdentificationNumber.description',
    description:
      'Description for employerIdentificationNumber within businessInfoWizard',
    defaultMessage:
      'Lorum ipsum dolor sir amet consectetur Sember sed dui ipsum vitae integer',
  },
});
