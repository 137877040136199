import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@config/store';

export interface Authentication {
  authStep: AuthorizationSteps;
  isProcessing: boolean;
  customerProfessionalId: string;
  emailAddress: string;
  accessToken: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  errorMessage: string;
}

export enum AuthorizationSteps {
  Unauthorized,
  Challenged,
  Authorized,
}

const initialState: Authentication = {
  authStep: AuthorizationSteps.Unauthorized,
  isProcessing: false,
  customerProfessionalId: '',
  emailAddress: '',
  accessToken: '',
  phoneNumber: '',
  firstName: '',
  lastName: '',
  birthdate: '',
  errorMessage: '',
};

interface AuthCompleted {
  emailAddress: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  birthdate?: string;
  accessToken: string;
  customerProfessionalId: string;
}

export interface ChallengeRequired {
  emailAddress: string;
  customerProfessionalId: string;
}

export interface UserAttributes {
  emailAddress: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  birthdate?: string;
}

export interface AttributeUpdates {
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  birthdate?: string;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLogout: (state): void => {
      state.authStep = AuthorizationSteps.Unauthorized;
      state.isProcessing = true;
      state.errorMessage = '';
    },
    finishLogout: (state): void => {
      state.isProcessing = false;
      state.errorMessage = '';
    },
    startAuthByEmail: (state, action: PayloadAction<string>): void => {
      state.isProcessing = true;
      state.errorMessage = '';
    },
    startAuthByUserId: (state, action: PayloadAction<string>): void => {
      state.customerProfessionalId = action.payload;
      state.isProcessing = true;
      state.errorMessage = '';
    },
    requireChallenge: (
      state,
      action: PayloadAction<ChallengeRequired>
    ): void => {
      state.emailAddress = action.payload.emailAddress;
      state.customerProfessionalId = action.payload.customerProfessionalId;
      state.authStep = AuthorizationSteps.Challenged;
      state.isProcessing = false;
      state.errorMessage = '';
    },
    startResendChallengeCode: (state): void => {
      state.isProcessing = true;
      state.errorMessage = '';
    },
    finishResendChallengeCode: (state): void => {
      state.isProcessing = false;
      state.errorMessage = '';
    },
    startChallenge: (state, action: PayloadAction<string>): void => {
      state.isProcessing = true;
      state.errorMessage = '';
    },
    finishAuth: (state, action: PayloadAction<AuthCompleted>): void => {
      state.accessToken = action.payload.accessToken;
      state.emailAddress = action.payload.emailAddress;
      state.customerProfessionalId = action.payload.customerProfessionalId;
      state.phoneNumber = action.payload.phoneNumber || '';
      state.firstName = action.payload.firstName || '';
      state.lastName = action.payload.lastName || '';
      state.birthdate = action.payload.birthdate || '';
      state.isProcessing = false;
      state.errorMessage = '';
      state.authStep = AuthorizationSteps.Authorized;
    },
    setErrorMessage: (state, action: PayloadAction<string>): void => {
      state.errorMessage = action.payload;
      state.isProcessing = false;
    },
    startAttributeUpdate: (
      state,
      action: PayloadAction<AttributeUpdates>
    ): void => {
      state.isProcessing = true;
      state.errorMessage = '';
    },
    finishAttributeUpdate: (
      state,
      action: PayloadAction<AttributeUpdates>
    ): void => {
      state.isProcessing = false;
      if (action.payload.phoneNumber) {
        state.phoneNumber = action.payload.phoneNumber;
      }
      if (action.payload.firstName) {
        state.firstName = action.payload.firstName;
      }
      if (action.payload.lastName) {
        state.lastName = action.payload.lastName;
      }
      if (action.payload.birthdate) {
        state.birthdate = action.payload.birthdate;
      }
    },
  },
});

export const {
  startLogout,
  finishLogout,
  startAuthByEmail,
  startAuthByUserId,
  startResendChallengeCode,
  finishResendChallengeCode,
  startChallenge,
  finishAuth,
  setErrorMessage,
  requireChallenge,
  startAttributeUpdate,
  finishAttributeUpdate,
} = authSlice.actions;

export const selectAuthentication = (state: RootState) => state.auth;

export default authSlice.reducer;
