import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Mesh } from '@shared/icons';
import * as React from 'react';
import { Component } from 'react';

class VerificationPoweredBy extends Component {
  render() {
    return (
      <Box
        sx={{
          paddingTop: '0.5rem',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
          variant='body1'
          color='text.secondary'
        >
          <FormattedMessage
            description='Wizard footer verifications powered by'
            defaultMessage='Verifications powered by {logo}'
            values={{
              logo: <Mesh width={'55px'} />,
            }}
          />
        </Typography>
      </Box>
    );
  }
}
export default VerificationPoweredBy;
