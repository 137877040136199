import * as React from 'react';
import type { SelectChangeEvent } from '@mui/material';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useIntl } from 'react-intl';
import type { EndpointInput, EndpointOption } from '@utils/schema';

const VerifyInputText = ({
  input,
  value = '',
  error,
  setFieldValue,
}: {
  input: EndpointInput;
  value: string;
  error: string;
  setFieldValue: (value: string) => void;
}): JSX.Element => {
  const intl = useIntl();
  const handleChange = (event: SelectChangeEvent) => {
    setFieldValue(event.target.value as string);
  };
  const options: EndpointOption[] = React.useMemo(() => {
    if ('options' in input && input.options) {
      return input.options;
    }
    return [];
  }, [input]);
  return (
    <FormControl fullWidth error={!!error} required={input.isRequired}>
      <InputLabel id={`${input.inputName}-label`}>
        {intl.formatMessage({ id: input.inputDescription })}
      </InputLabel>
      <Select
        id={input.inputName}
        labelId={`${input.inputName}-label`}
        value={value}
        label={intl.formatMessage({ id: input.inputDescription })}
        onChange={handleChange}
      >
        {options.map(({ value, label }: EndpointOption) => (
          <MenuItem
            key={`select-${input.inputName}-item-${label}`}
            value={value}
          >
            {intl.formatMessage({ id: label })}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {error && intl.formatMessage({ id: error })}
      </FormHelperText>
    </FormControl>
  );
};

export default VerifyInputText;
