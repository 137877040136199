import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { makeGraphQlCall } from '@utils/graphql';

import {
  startSigning,
  finishSigning,
  errorSigning,
  EsignPayload,
  EsignResponse,
} from './esignSlice';

const signW9Mutation = `
mutation SignW9($input: SignW9Input!) {
  signW9(input: $input) {
    id
    url
  }
}
`;

function* handleStartSigning(action: PayloadAction<EsignPayload>) {
  console.log('Starting signing');
  let data: { signW9: EsignResponse };
  try {
    switch (action.payload.documentType) {
      case 'W-9':
        data = yield call(makeGraphQlCall, {
          query: signW9Mutation,
          variables: {
            input: {
              signer: action.payload.signer,
              business: action.payload.data,
              documentReference: action.payload.documentReference,
              vsku: action.payload.vsku,
            },
          },
        });
        yield put(finishSigning(data.signW9));
        break;

      default:
        throw new Error('Unsupported document type');
    }
  } catch (e) {
    console.log('Error signing', e);
    yield put(errorSigning(e.message));
  }
}

function* esignSaga() {
  yield takeEvery(startSigning.type, handleStartSigning);
}

export default esignSaga;
