import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  Authentication,
  AuthorizationSteps,
  selectAuthentication,
  startAuthByEmail,
  startChallenge,
  startLogout,
} from '@features/verification/steps/auth/authSlice';
import { useWizard, MeshOtpInput } from '@shared/components';
import * as React from 'react';
import { useEffect } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContentProps } from '@features/verification/VerificationWizard/components/Step';
import { useNavigate } from 'react-router-dom';
import {
  PreventAction,
  removeStep,
  setStepFooter,
} from '@features/verification/VerificationWizard/wizardSlice';
import { AnalyticsContext } from '@context/analyticsContext';
import { ExperimentContext } from '@context/experimentContext';

export const VerificationChallenged = ({
  stepIndex,
  stepKey,
}: ContentProps) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuthentication);
  const navigate = useNavigate();
  const { logEvent, identifyUser } = React.useContext(AnalyticsContext);
  const { targetUser } = React.useContext(ExperimentContext);
  const [otp, setOtp] = React.useState('');

  useEffect(() => {
    dispatch(
      setStepFooter({
        stepKey,
        footer: {
          hideNextButton: otp.length < 6,
          nextLoading: auth.isProcessing,
          onNext: async () => {
            dispatch(startChallenge(otp));
            return PreventAction.yes;
          },
        },
      })
    );
  }, [auth.isProcessing, otp]);

  useEffect(() => {
    if (auth.authStep === AuthorizationSteps.Challenged) {
      logEvent({ event: 'one_time_password' });
    }
    if (auth.authStep === AuthorizationSteps.Authorized) {
      identifyUser({ userId: auth.emailAddress });
      targetUser({ userId: auth.emailAddress });
      logEvent({ event: 'authorized' });
    }
  }, [auth.authStep, auth.emailAddress]);

  useEffect(() => {
    if (!auth.isProcessing && auth.authStep === AuthorizationSteps.Authorized) {
      dispatch(removeStep({ stepIndex, gotoNext: true }));
    }
  }, [auth.authStep, auth.isProcessing, stepIndex, stepKey]);

  const handleResendOtpCode = () => {
    setOtp('');
    dispatch(startAuthByEmail(auth.emailAddress));
  };

  const handleChangeEmail = () => {
    navigate(-1);
  };
  const handleSubmit = (code: string) => {
    dispatch(startChallenge(code));
  };
  return (
    <>
      <Typography
        color='emphasis.dark'
        variant='h4'
        textAlign='center'
        paddingBottom='1rem'
      >
        <FormattedMessage
          description='Verification wizard step label authenticate'
          defaultMessage='Authenticate'
        />
      </Typography>
      <ChallengedBody
        auth={auth}
        otp={otp}
        setOtp={setOtp}
        onResend={handleResendOtpCode}
        onChangeEmail={handleChangeEmail}
        onSubmit={handleSubmit}
      />
    </>
  );
};

const Challenged = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuthentication);
  const { overrideButtonsController } = useWizard();
  const [otp, setOtp] = React.useState('');
  const handleChangeEmail = () => {
    dispatch(startLogout());
  };
  const handleSubmit = (code: string) => {
    dispatch(startChallenge(code));
  };

  useEffect(() => {
    console.log('override challenged');
    overrideButtonsController({
      next: {
        onClick: () => handleSubmit(otp),
        isHidden: () => otp?.length < 6,
      },
      previous: {
        onClick: handleChangeEmail,
        isDisabled: () => false,
      },
    });
  }, [otp]);

  const handleResendOtpCode = () => {
    setOtp('');
    dispatch(startAuthByEmail(auth.emailAddress));
  };

  return ChallengedBody({
    auth,
    otp,
    setOtp,
    onResend: handleResendOtpCode,
    onChangeEmail: handleChangeEmail,
    onSubmit: handleSubmit,
  });
};

const ChallengedBody = ({
  auth,
  otp,
  setOtp,
  onResend,
  onSubmit,
}: {
  auth: Authentication;
  otp: string;
  setOtp: (otp: string) => void;
  onResend: () => void;
  onChangeEmail: () => void;
  onSubmit: (otp: string) => void;
}) => {
  const intl = useIntl();

  const handleChangeOtp = (newValue: string) => {
    setOtp(newValue);
    if (newValue.length >= 6) {
      onSubmit(newValue);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 2,
        }}
        component='form'
        noValidate
        onSubmit={e => {
          e.preventDefault();
          onSubmit(otp);
        }}
      >
        <Typography variant='subtitle1' textAlign={'center'}>
          <FormattedMessage
            description='Verification code disclaimer'
            defaultMessage='Please, enter the verification code we sent you at <b>{emailAddress}</b>'
            values={{
              emailAddress: auth.emailAddress,
              b: val => <b>{val}</b>,
            }}
          />
        </Typography>
        <MeshOtpInput value={otp} onChange={handleChangeOtp} />
        {auth.errorMessage && (
          <Typography variant='subtitle2' color='error'>
            {intl.formatMessage({ id: auth.errorMessage })}
          </Typography>
        )}
      </Box>
      <Typography variant='subtitle1' display={'flex'} gap={1}>
        <FormattedMessage
          description='verification code text'
          defaultMessage='<link>Resend</link> verification code'
          values={{
            link: val => (
              <Link
                component='button'
                onClick={onResend}
                style={{ textDecoration: 'none' }}
              >
                {val}
              </Link>
            ),
          }}
        />
      </Typography>
    </Box>
  );
};

export default Challenged;
