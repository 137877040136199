import React, { SVGProps } from 'react';

const RemoveIcon: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      {...props}
    >
      <path
        d='M12 2.91211C6.47 2.91211 2 7.38211 2 12.9121C2 18.4421 6.47 22.9121 12 22.9121C17.53 22.9121 22 18.4421 22 12.9121C22 7.38211 17.53 2.91211 12 2.91211ZM17 16.5021L15.59 17.9121L12 14.3221L8.41 17.9121L7 16.5021L10.59 12.9121L7 9.32211L8.41 7.91211L12 11.5021L15.59 7.91211L17 9.32211L13.41 12.9121L17 16.5021Z'
        fill='black'
        fillOpacity='0.54'
      />
    </svg>
  );
};

export default RemoveIcon;
