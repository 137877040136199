import type { Schema, SchemaFieldDescription, ValidationError } from 'yup';
import * as yup from 'yup';
import { matchIsValidTel } from 'mui-tel-input';
import { FieldVSku } from '@features/endpoint/endpointSlice';

const { object } = yup;
export interface EndpointValidateResults {
  isValid: boolean;
  values: { [key: string]: string };
  errorMap: Record<string, string>;
}

export interface EndpointSchema {
  validate: (data: Record<string, any>) => EndpointValidateResults;
  getFields: (data: Record<string, any>) => EndpointInput[];
  defaultData: Record<string, any>;
}

export interface EndpointOption {
  label: string;
  value: string;
}

export interface EndpointMultiple {
  min: number;
  max?: number;
}
export enum EndpointMaskType {
  ssn = 'ssn',
  ein = 'ein',
  lastFourSsn = 'last-four-ssn',
}

export interface EndpointInput {
  inputName: string;
  inputDescription: string;
  inputHelperText?: string;
  inputType: EndpointType;
  options?: EndpointOption[];
  isRequired: boolean;
  multiple?: EndpointMultiple;
  maskType?: EndpointMaskType;
}

export enum EndpointType {
  Hidden,
  TextInput,
  DateInput,
  DropdownInput,
  BooleanInput,
  EmailInput,
  PhoneInput,
  AddressInput,
}

const getEndpointType = (field: SchemaFieldDescription): EndpointType => {
  if (
    'meta' in field &&
    field.meta &&
    'hidden' in field.meta &&
    field.meta.hidden === true
  ) {
    return EndpointType.Hidden;
  }
  if (field.type === 'boolean') {
    return EndpointType.BooleanInput;
  }
  if (field.type === 'date') {
    return EndpointType.DateInput;
  }
  if (
    'meta' in field &&
    field.meta &&
    (field.meta as { dataType: string }).dataType === 'email'
  ) {
    return EndpointType.EmailInput;
  }
  if (
    'meta' in field &&
    field.meta &&
    (field.meta as { dataType: string }).dataType === 'phone'
  ) {
    return EndpointType.PhoneInput;
  }
  if (
    'meta' in field &&
    field.meta &&
    (field.meta as { dataType: string }).dataType === 'address'
  ) {
    return EndpointType.AddressInput;
  }
  if (
    'meta' in field &&
    field.meta &&
    'dropdownOptions' in field.meta &&
    Array.isArray(field.meta.dropdownOptions)
  ) {
    return EndpointType.DropdownInput;
  }
  // Default input type
  return EndpointType.TextInput;
};

const buildValidate =
  (schema: Schema) =>
  (data: Record<string, any>): EndpointValidateResults => {
    try {
      console.log('validating data', data);
      const isValid = schema.validateSync(data, { abortEarly: false });
      console.log('isValid', isValid);
      return {
        isValid: true,
        values: isValid,
        errorMap: {},
      };
    } catch (e) {
      const errorMap = Object.fromEntries(
        e.inner.map((err: ValidationError) => [err.path, err.message])
      );
      return {
        isValid: false,
        values: {},
        errorMap,
      };
    }
  };

const buildGetFields =
  (schema: Schema) =>
  (data: Record<string, any>): EndpointInput[] => {
    const description = schema.describe({
      value: data,
    });
    if ('fields' in description && description.fields) {
      const fields = Object.entries(description.fields).map(
        ([inputName, field]) => {
          const inputDescription = 'label' in field ? field.label : 'ref label';
          const options: EndpointOption[] =
            'meta' in field &&
            field.meta &&
            'dropdownOptions' in field.meta &&
            Array.isArray(field.meta.dropdownOptions) &&
            field.meta.dropdownOptions.map(
              ({ value, label }: { value: string; label: string }) => ({
                label,
                value,
              })
            );
          const inputType = getEndpointType(field);
          const isRequired =
            'tests' in field
              ? field.tests.some(
                  ({ name }: { name: string }) => name === 'required'
                )
              : false;
          const multiple = 'meta' in field && field.meta?.multiple;
          const maskType = 'meta' in field && field.meta?.maskType;
          return {
            inputName,
            inputDescription,
            inputType,
            options,
            isRequired,
            multiple,
            maskType,
          };
        }
      );
      return fields;
    }
    return [];
  };

const buildSchema = (schema: Schema): EndpointSchema => {
  const validate = buildValidate(schema);
  const getFields = buildGetFields(schema);
  const defaultData = schema.cast({});
  console.log('got default', defaultData);

  return {
    validate,
    getFields,
    defaultData,
  };
};

export const buildDynamicSchema = async (
  vsku?: FieldVSku
): Promise<EndpointSchema> => {
  if (vsku) {
    const inputClaims = vsku.inputClaims;
    const requiredSets: string[][] = [];
    const arrayOfEntries = inputClaims
      .filter(({ isDerived }) => !isDerived)
      .filter(({ isHidden }) => !isHidden)
      .map(claim => {
        let yupType: any; // TODO: Make this work without any
        console.log('building claim', claim);
        const labelStringId = claim.nameId;
        const requiredError = claim.errorId;
        switch (claim.dataType) {
          case 'string':
            yupType = yup.string().label(labelStringId).default('');
            if (claim.isRequired && requiredError) {
              yupType = yupType.required(requiredError);
            }
            if (claim.options?.maskType) {
              yupType = yupType.meta({
                maskType: claim.options.maskType as EndpointMaskType,
              });
            }
            if (claim.options?.requiredSet && requiredError) {
              requiredSets.push(claim.options.requiredSet);
            }
            break;
          case 'boolean':
            yupType = yup.boolean().label(labelStringId).default(false);
            if (claim.isRequired && requiredError) {
              yupType = yupType.required(requiredError);
            }
            break;
          case 'enum':
            yupType = yup
              .string()
              .label(labelStringId)
              .default('')
              .meta({
                dropdownOptions: claim.options?.values.map(
                  ({ value, labelStringId }) => ({
                    value,
                    label: labelStringId,
                  })
                ),
              })
              .oneOf(
                claim.options?.values?.map(item => item?.value),
                requiredError
              );
            if (claim.isRequired && requiredError) {
              yupType = yupType.required(requiredError);
            }
            break;
          case 'date':
            yupType = yup
              .date()
              .label(labelStringId)
              .required(requiredError)
              .nullable()
              .default(null);
            break;
          case 'email':
            yupType = yup
              .string()
              .label(labelStringId)
              .default('')
              .meta({ dataType: 'email', multiple: claim.options?.multiple })
              .test('valid-email', requiredError, value => {
                const emails = value?.split(';') ?? [];
                return emails.every(
                  email =>
                    email?.length > 0 &&
                    email.includes('@') &&
                    email.includes('.')
                );
              });
            if (claim.isRequired && requiredError) {
              yupType = yupType.required(requiredError);
            }
            break;
          case 'phone':
            yupType = yup
              .string()
              .label(labelStringId)
              .default('')
              .meta({ dataType: 'phone' })
              .test('valid-phone', requiredError, value => {
                console.log(
                  'validating phone',
                  value,
                  matchIsValidTel(value),
                  !value || matchIsValidTel(value)
                );
                return !value || value === '+1' || matchIsValidTel(value);
              });
            if (claim.isRequired && requiredError) {
              yupType = yupType.required(requiredError);
            }
            break;
          case 'address':
            yupType = yup
              .mixed()
              .label(labelStringId)
              .default('')
              .nullable()
              .meta({ dataType: 'address' })
              .test('valid-address', requiredError, value => {
                if (claim.isRequired && requiredError) {
                  console.log('validating address', value);
                  return !!value;
                }
                return true;
              });
            break;
          default:
            yupType = yup.string().label(labelStringId).default('');
            if (claim.isRequired && requiredError) {
              yupType = yupType.required(requiredError);
            }
            break;
        }
        return [claim.fieldName, yupType];
      });

    const newEntries = Object.fromEntries(arrayOfEntries);
    console.log('Built schema', newEntries);
    let yupObject = yup.object().shape(newEntries);
    if (requiredSets.length > 0) {
      requiredSets.forEach(set => {
        console.log('adding set', set);
        yupObject = yupObject.test(
          `required-set-${set.join(' ')}`,
          `One of ${set.join()} must have a value`,
          value => {
            console.log('validating required sets', value);
            return set.some((field: string) => value[field]);
          }
        );
      });
    }
    return buildSchema(yupObject);
  }
  return buildSchema(object({}));
};
