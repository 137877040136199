import * as React from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';

import { DocumentVSku, VSkuType } from '@features/endpoint/endpointSlice';

import { useWizard } from '@shared/components';
import { useEffect } from 'react';
import { AnalyticsContext } from '@context/analyticsContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { Close, UploadFileOutlined, CheckCircle } from '@mui/icons-material';

const FILE_TYPES = ['JPG', 'PNG', 'GIF', 'PDF'];

const FileIcon = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        borderRadius: '4rem',
        width: '2.5rem',
        height: '2.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <UploadFileOutlined color='primary' />
    </Box>
  );
};

enum UploadedType {
  none,
  pdf,
  image,
  mixed,
  multiplePdfs,
}

import { defineMessages } from 'react-intl';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  selectDocument,
  startProcessingImages,
  startUploadingDocument,
} from '@utils/document/documentSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { ContentProps } from '@features/verification/VerificationWizard/components/Step';
import {
  PreventAction,
  gotoNextStep,
  setStepData,
  setStepFooter,
} from '@features/verification/VerificationWizard/wizardSlice';
// Define Vskus attributes copies
defineMessages({
  multiplePdfsError: {
    id: 'uploadDocument.errors.multiplePdfs',
    description: 'Error message displayed when uploading multiple PDFs',
    defaultMessage: 'Cannot upload more than one PDF per document',
  },
  mixedError: {
    id: 'uploadDocument.errors.mixed',
    description:
      'Error message displayed when uploading a mix of PDFs and images',
    defaultMessage: 'Cannot mix PDF and images while uploading files',
  },
});

const getErrors = (newFileType: UploadedType) => {
  switch (newFileType) {
    case UploadedType.mixed:
      return { file: 'uploadDocument.errors.mixed' };
    case UploadedType.multiplePdfs:
      return { file: 'uploadDocument.errors.multiplePdfs' };
    default:
      return {};
  }
};

export const VerificationUploadDocument = ({
  stepKey,
  currentStepData,
}: ContentProps): JSX.Element => {
  const [files, setFiles] = React.useState([]);
  const [alreadyUploadedFileName, setAlreadyUploadedFileName] =
    React.useState<string>(null);
  const [uploadedType, setUploadedType] = React.useState<UploadedType>(
    UploadedType.none
  );
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const document = useAppSelector(selectDocument);
  const { isUploading, documentId, fileName } = document;

  const { stepParams } = currentStepData ?? {};
  const { vsku, vspecId } = stepParams ?? {};

  const handleSubmit = React.useMemo(
    () => async () => {
      switch (uploadedType) {
        case UploadedType.pdf:
          dispatch(
            startUploadingDocument({
              fileName: files[0].name,
              document: files[0],
            })
          );
          break;
        case UploadedType.image:
          dispatch(
            startProcessingImages({
              images: files,
            })
          );
          break;
        default:
          console.log('Unsupported uploaded type', uploadedType);
          break;
      }
      return PreventAction.yes;
    },
    [files, uploadedType]
  );

  useEffect(() => {
    if (!isUploading && documentId && files.length > 0) {
      console.log('Done uploading', documentId, fileName);
      dispatch(
        setStepData({
          stepKey,
          data: {
            vspecId,
            stepType: VSkuType.document,
            vsku: vsku.vsku,
            primaryInputs: vsku.primaryInputs,
            inputValues: {
              documentId,
              fileName: fileName,
            },
            jurisdiction: vsku.jurisdiction,
          },
        })
      );
      dispatch(gotoNextStep({ stepKey }));
    }
  }, [isUploading, documentId, stepKey]);

  const handleChange = React.useMemo(
    () => (newFiles: { name: string; type: string }[]) => {
      setFiles([...files, ...newFiles]);
    },
    [files]
  );
  useEffect(() => {
    const newFileType = files.reduce((acc, file) => {
      if (file.type === 'application/pdf') {
        if (acc === UploadedType.image) {
          return UploadedType.mixed;
        }
        if (acc === UploadedType.pdf) {
          return UploadedType.multiplePdfs;
        }
        if (acc === UploadedType.none) {
          return UploadedType.pdf;
        }
      }
      if (file.type.startsWith('image/')) {
        if (acc === UploadedType.pdf) {
          return UploadedType.mixed;
        }
        if (acc === UploadedType.none) {
          return UploadedType.image;
        }
      }
      return acc;
    }, UploadedType.none);
    setUploadedType(newFileType);
    setErrors(getErrors(newFileType));
  }, [files]);
  useEffect(() => {
    const hideNextButton =
      !alreadyUploadedFileName &&
      ![UploadedType.pdf, UploadedType.image].includes(uploadedType);
    dispatch(
      setStepFooter({
        stepKey,
        footer: {
          hideNextButton,
          nextLoading: isUploading,
          onNext: alreadyUploadedFileName
            ? async () => PreventAction.no
            : handleSubmit,
        },
      })
    );
  }, [alreadyUploadedFileName, uploadedType, isUploading, handleSubmit]);

  useEffect(() => {
    if (currentStepData) {
      const { inputValues } = currentStepData.data ?? {};
      const { fileName } = inputValues ?? {};
      setAlreadyUploadedFileName(fileName);
    } else {
      setAlreadyUploadedFileName(null);
    }
  }, [currentStepData]);

  return (
    <>
      <Typography
        color='emphasis.dark'
        variant='h4'
        textAlign='center'
        paddingBottom='1rem'
      >
        {intl.formatMessage({ id: vsku.nameId })}
      </Typography>
      <Typography variant='subtitle1' textAlign='center' paddingBottom='1rem'>
        <FormattedMessage id={vsku.descriptionId} />
      </Typography>
      <UploadDocumentBody
        isUploading={false}
        alreadyUploadedFileName={alreadyUploadedFileName}
        setAlreadyUploadedFileName={setAlreadyUploadedFileName}
        handleChange={handleChange}
        files={files}
        errors={errors}
        setFiles={setFiles}
      />
    </>
  );
};

const UploadDocument = ({
  vspecId,
  vsku,
  hasNext,
}: {
  vspecId: string;
  vsku: DocumentVSku;
  hasNext?: boolean;
}): JSX.Element => {
  const [files, setFiles] = React.useState([]);
  const [alreadyUploadedFileName, setAlreadyUploadedFileName] =
    React.useState<string>(null);
  const [uploadedType, setUploadedType] = React.useState<UploadedType>(
    UploadedType.none
  );
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const {
    overrideButtonsController,
    goToNextStep,
    setStepValue,
    activeStep,
    activeStepValue,
  } = useWizard();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const document = useAppSelector(selectDocument);
  const { isUploading, documentId, fileName } = document;

  const handleSubmit = React.useMemo(
    () => async () => {
      switch (uploadedType) {
        case UploadedType.pdf:
          dispatch(
            startUploadingDocument({
              fileName: files[0].name,
              document: files[0],
            })
          );
          break;
        case UploadedType.image:
          dispatch(
            startProcessingImages({
              images: files,
            })
          );
          break;
        default:
          console.log('Unsupported uploaded type', uploadedType);
          break;
      }
      return false;
    },
    [files, uploadedType]
  );

  useEffect(() => {
    if (!isUploading && documentId && files.length > 0) {
      console.log('Done uploading', documentId, fileName);
      setStepValue({
        vspecId,
        stepType: VSkuType.document,
        vsku: vsku.vsku,
        primaryInputs: vsku.primaryInputs,
        inputValues: {
          documentId,
          fileName: fileName,
        },
        jurisdiction: vsku.jurisdiction,
      });
      // analytics log step
      goToNextStep();
    }
  }, [isUploading, documentId]);

  const handleChange = React.useMemo(
    () => (newFiles: { name: string; type: string }[]) => {
      setFiles([...files, ...newFiles]);
    },
    [files]
  );
  useEffect(() => {
    const newFileType = files.reduce((acc, file) => {
      if (file.type === 'application/pdf') {
        if (acc === UploadedType.image) {
          return UploadedType.mixed;
        }
        if (acc === UploadedType.pdf) {
          return UploadedType.multiplePdfs;
        }
        if (acc === UploadedType.none) {
          return UploadedType.pdf;
        }
      }
      if (file.type.startsWith('image/')) {
        if (acc === UploadedType.pdf) {
          return UploadedType.mixed;
        }
        if (acc === UploadedType.none) {
          return UploadedType.image;
        }
      }
      return acc;
    }, UploadedType.none);
    setUploadedType(newFileType);
    setErrors(getErrors(newFileType));
  }, [files]);
  useEffect(() => {
    overrideButtonsController({
      next: {
        isHidden: () => {
          return (
            !alreadyUploadedFileName &&
            ![UploadedType.pdf, UploadedType.image].includes(uploadedType)
          );
        },
        onClick: () =>
          alreadyUploadedFileName ? goToNextStep() : handleSubmit(),
      },
      previous: {},
    });
  }, [hasNext, alreadyUploadedFileName, uploadedType, handleSubmit]);

  useEffect(() => {
    if (activeStepValue) {
      const { inputValues } = activeStepValue;
      const { fileName } = inputValues;
      setAlreadyUploadedFileName(fileName);
    } else {
      setAlreadyUploadedFileName(null);
    }
  }, [activeStep, activeStepValue]);

  return UploadDocumentBody({
    isUploading,
    alreadyUploadedFileName,
    setAlreadyUploadedFileName,
    handleChange,
    files,
    errors,
    setFiles,
  });
};

const UploadDocumentBody = ({
  isUploading,
  alreadyUploadedFileName,
  setAlreadyUploadedFileName,
  handleChange,
  files,
  errors,
  setFiles,
}: {
  isUploading: boolean;
  alreadyUploadedFileName: string;
  setAlreadyUploadedFileName: (fileName: string) => void;
  handleChange: (newFiles: { name: string; type: string }[]) => void;
  files: { name: string; type: string }[];
  errors: Record<string, string>;
  setFiles: (files: { name: string; type: string }[]) => void;
}) => {
  const intl = useIntl();
  if (isUploading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        component='form'
        noValidate
      >
        <Typography>
          <FormattedMessage
            description='Message for uploading files'
            defaultMessage='Uploading...'
          />
        </Typography>
        <CircularProgress />
      </Box>
    );
  }

  if (alreadyUploadedFileName) {
    return (
      <Box sx={{ width: '100%' }} component='form' noValidate>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <CheckCircle color='success' />
            <Typography
              sx={{
                ml: '0.5rem',
                textOverflow: 'ellipsis',
                maxWidth: '85%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {alreadyUploadedFileName}
            </Typography>
          </Box>
          <IconButton
            color='primary'
            onClick={() => setAlreadyUploadedFileName(null)}
          >
            <Close />
          </IconButton>
        </Box>
        <Typography textAlign='center'>
          <FormattedMessage
            description='Message for already uploaded files'
            defaultMessage='File has already been uploaded.'
          />
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }} component='form' noValidate>
      <FileUploader
        handleChange={handleChange}
        name='file'
        multiple
        types={FILE_TYPES}
        fileOrFiles={files}
        hoverTitle={intl.formatMessage({
          defaultMessage: 'Drop documents here',
          description: 'Message displayed within drag and drop file upload box',
        })}
      >
        <Box
          sx={{
            border: '1px dashed',
            borderRadius: '4px',
            borderColor: 'grey.200',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FileIcon />
          <Box sx={{ mt: '1rem', mb: '0.5rem' }}>
            <Link>
              <FormattedMessage
                description='Upload message'
                defaultMessage='Click to upload or drag and drop'
              />
            </Link>
          </Box>
        </Box>
      </FileUploader>
      <Box sx={{ height: '2rem' }}>
        {errors['file'] && (
          <Typography variant='body2' textAlign='center' sx={{ color: 'red' }}>
            {intl.formatMessage({ id: errors['file'] })}
          </Typography>
        )}
      </Box>
      <Stack spacing={1.5}>
        {files.map((file: { name: string }, index: number) => (
          <Box
            key={`file-${index}`}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <FileIcon />
              <Typography
                sx={{
                  ml: '0.5rem',
                  textOverflow: 'ellipsis',
                  maxWidth: '85%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {file.name}
              </Typography>
            </Box>
            <IconButton
              color='primary'
              onClick={() => setFiles(files.filter((_, i) => i !== index))}
            >
              <Close />
            </IconButton>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default UploadDocument;
