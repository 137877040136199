import * as React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl, FormattedMessage } from 'react-intl';

import { AddressRequest } from './addressSlice';

const stateDropdown = [
  {
    id: 'vsku.state.AL.label',
    labelId: 'vsku.state.AL.label',
    value: 'AL',
  },
  {
    id: 'vsku.state.AK.label',
    labelId: 'vsku.state.AK.label',
    value: 'AK',
  },

  {
    id: 'vsku.state.AZ.label',
    labelId: 'vsku.state.AZ.label',
    value: 'AZ',
  },
  {
    id: 'vsku.state.AR.label',
    labelId: 'vsku.state.AR.label',
    value: 'AR',
  },
  {
    id: 'vsku.state.CA.label',
    labelId: 'vsku.state.CA.label',
    value: 'CA',
  },
  {
    id: 'vsku.state.CO.label',
    labelId: 'vsku.state.CO.label',
    value: 'CO',
  },
  {
    id: 'vsku.state.CT.label',
    labelId: 'vsku.state.CT.label',
    value: 'CT',
  },
  {
    id: 'vsku.state.DE.label',
    labelId: 'vsku.state.DE.label',
    value: 'DE',
  },
  {
    id: 'vsku.state.DC.label',
    labelId: 'vsku.state.DC.label',
    value: 'DC',
  },
  {
    id: 'vsku.state.FL.label',
    labelId: 'vsku.state.FL.label',
    value: 'FL',
  },
  {
    id: 'vsku.state.GA.label',
    labelId: 'vsku.state.GA.label',
    value: 'GA',
  },
  {
    id: 'vsku.state.HI.label',
    labelId: 'vsku.state.HI.label',
    value: 'HI',
  },
  {
    id: 'vsku.state.ID.label',
    labelId: 'vsku.state.ID.label',
    value: 'ID',
  },
  {
    id: 'vsku.state.IL.label',
    labelId: 'vsku.state.IL.label',
    value: 'IL',
  },
  {
    id: 'vsku.state.IN.label',
    labelId: 'vsku.state.IN.label',
    value: 'IN',
  },
  {
    id: 'vsku.state.IA.label',
    labelId: 'vsku.state.IA.label',
    value: 'IA',
  },
  {
    id: 'vsku.state.KS.label',
    labelId: 'vsku.state.KS.label',
    value: 'KS',
  },
  {
    id: 'vsku.state.KY.label',
    labelId: 'vsku.state.KY.label',
    value: 'KY',
  },
  {
    id: 'vsku.state.LA.label',
    labelId: 'vsku.state.LA.label',
    value: 'LA',
  },
  {
    id: 'vsku.state.ME.label',
    labelId: 'vsku.state.ME.label',
    value: 'ME',
  },
  {
    id: 'vsku.state.MD.label',
    labelId: 'vsku.state.MD.label',
    value: 'MD',
  },
  {
    id: 'vsku.state.MA.label',
    labelId: 'vsku.state.MA.label',
    value: 'MA',
  },
  {
    id: 'vsku.state.MI.label',
    labelId: 'vsku.state.MI.label',
    value: 'MI',
  },
  {
    id: 'vsku.state.MN.label',
    labelId: 'vsku.state.MN.label',
    value: 'MN',
  },
  {
    id: 'vsku.state.MS.label',
    labelId: 'vsku.state.MS.label',
    value: 'MS',
  },
  {
    id: 'vsku.state.MO.label',
    labelId: 'vsku.state.MO.label',
    value: 'MO',
  },
  {
    id: 'vsku.state.MT.label',
    labelId: 'vsku.state.MT.label',
    value: 'MT',
  },
  {
    id: 'vsku.state.NE.label',
    labelId: 'vsku.state.NE.label',
    value: 'NE',
  },
  {
    id: 'vsku.state.NV.label',
    labelId: 'vsku.state.NV.label',
    value: 'NV',
  },
  {
    id: 'vsku.state.NH.label',
    labelId: 'vsku.state.NH.label',
    value: 'NH',
  },
  {
    id: 'vsku.state.NJ.label',
    labelId: 'vsku.state.NJ.label',
    value: 'NJ',
  },
  {
    id: 'vsku.state.NM.label',
    labelId: 'vsku.state.NM.label',
    value: 'NM',
  },
  {
    id: 'vsku.state.NY.label',
    labelId: 'vsku.state.NY.label',
    value: 'NY',
  },
  {
    id: 'vsku.state.NC.label',
    labelId: 'vsku.state.NC.label',
    value: 'NC',
  },
  {
    id: 'vsku.state.ND.label',
    labelId: 'vsku.state.ND.label',
    value: 'ND',
  },
  {
    id: 'vsku.state.OH.label',
    labelId: 'vsku.state.OH.label',
    value: 'OH',
  },
  {
    id: 'vsku.state.OK.label',
    labelId: 'vsku.state.OK.label',
    value: 'OK',
  },
  {
    id: 'vsku.state.OR.label',
    labelId: 'vsku.state.OR.label',
    value: 'OR',
  },
  {
    id: 'vsku.state.PA.label',
    labelId: 'vsku.state.PA.label',
    value: 'PA',
  },
  {
    id: 'vsku.state.PR.label',
    labelId: 'vsku.state.PR.label',
    value: 'PR',
  },
  {
    id: 'vsku.state.RI.label',
    labelId: 'vsku.state.RI.label',
    value: 'RI',
  },
  {
    id: 'vsku.state.SC.label',
    labelId: 'vsku.state.SC.label',
    value: 'SC',
  },
  {
    id: 'vsku.state.SD.label',
    labelId: 'vsku.state.SD.label',
    value: 'SD',
  },
  {
    id: 'vsku.state.TN.label',
    labelId: 'vsku.state.TN.label',
    value: 'TN',
  },
  {
    id: 'vsku.state.TX.label',
    labelId: 'vsku.state.TX.label',
    value: 'TX',
  },
  {
    id: 'vsku.state.UT.label',
    labelId: 'vsku.state.UT.label',
    value: 'UT',
  },
  {
    id: 'vsku.state.VT.label',
    labelId: 'vsku.state.VT.label',
    value: 'VT',
  },
  {
    id: 'vsku.state.VA.label',
    labelId: 'vsku.state.VA.label',
    value: 'VA',
  },
  {
    id: 'vsku.state.WA.label',
    labelId: 'vsku.state.WA.label',
    value: 'WA',
  },
  {
    id: 'vsku.state.WV.label',
    labelId: 'vsku.state.WV.label',
    value: 'WV',
  },
  {
    id: 'vsku.state.WI.label',
    labelId: 'vsku.state.WI.label',
    value: 'WI',
  },
  {
    id: 'vsku.state.WY.label',
    labelId: 'vsku.state.WY.label',
    value: 'WY',
  },
];

export const AddAddressModal = ({
  displayModal,
  handleClose,
  submitAddress,
}: {
  displayModal: boolean;
  handleClose: () => void;
  submitAddress: (address: AddressRequest) => void;
}) => {
  const intl = useIntl();
  const [street1, setStreet1] = React.useState('');
  const [street2, setStreet2] = React.useState('');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');
  const [errors, setErrors] = React.useState({} as Record<string, string>);

  const handleSubmit = () => {
    submitAddress({
      fullAddress: `${street1} ${street2}, ${city}, ${state} ${postalCode}`,
      components: {
        address1: street1,
        address2: street2,
        city,
        state,
        postalCode,
        lastLine: `${city}, ${state} ${postalCode}`,
        country: 'US',
      },
    });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={displayModal} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        <FormattedMessage
          description='Add Address Dialog Title'
          defaultMessage='Add Address Manually'
        />
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={intl.formatMessage({
                description: 'Street 1 Field Label',
                defaultMessage: 'Street 1',
              })}
              value={street1}
              onChange={evt => setStreet1(evt.target.value)}
              required
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={intl.formatMessage({
                description: 'Street 2 Field Label',
                defaultMessage: 'Street 2',
              })}
              value={street2}
              onChange={evt => setStreet2(evt.target.value)}
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label={intl.formatMessage({
                description: 'City Field Label',
                defaultMessage: 'City',
              })}
              value={city}
              onChange={evt => setCity(evt.target.value)}
              required
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!errors['state']} required>
              <InputLabel id='add-address-state-dropdown-label'>
                {intl.formatMessage({
                  description: 'State Field Label',
                  defaultMessage: 'State',
                })}
              </InputLabel>
              <Select
                labelId='add-address-state-dropdown-label'
                value={state}
                label={intl.formatMessage({
                  description: 'State Field Label',
                  defaultMessage: 'State',
                })}
                onChange={evt => setState(evt.target.value)}
              >
                {stateDropdown.map(
                  ({ value, labelId }: { value: string; labelId: string }) => (
                    <MenuItem
                      key={`select-add-address-state-dropdown-item-${labelId}`}
                      value={value}
                    >
                      {intl.formatMessage({ id: labelId })}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>
                {errors['state'] && intl.formatMessage({ id: errors['state'] })}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={intl.formatMessage({
                description: 'Postal Code Field Label',
                defaultMessage: 'Zip',
              })}
              value={postalCode}
              onChange={evt => setPostalCode(evt.target.value)}
              required
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleSubmit}
              fullWidth
              variant='contained'
              color='primary'
            >
              <FormattedMessage
                description='Add Address Dialog Title'
                defaultMessage='Add Address'
              />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
