import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { CheckCircleOutline, RadioButtonUnchecked } from '@mui/icons-material';

export type PreviewPanelItem = {
  checked: boolean;
  text: string;
  color?: string;
};

export type PreviewPanelProps = {
  title: string;
  items: PreviewPanelItem[];
};

const PreviewPanel = ({ title, items }: PreviewPanelProps) => {
  return (
    <>
      <Typography color='emphasis.dark' variant='h5' paddingBottom={1}>
        {title}
      </Typography>
      <Stack>
        {items.map(({ checked, text, color }: PreviewPanelItem, index) => (
          <Box
            key={`panel-preview-${index}`}
            sx={{
              display: 'flex',
              paddingBottom: 1,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            {checked ? (
              <CheckCircleOutline sx={{ fontSize: 13 }} color='primary' />
            ) : (
              <RadioButtonUnchecked sx={{ fontSize: 13, color: 'grey.500' }} />
            )}
            <Typography color={color ? color : 'grey.700'} variant='body2'>
              {text}
            </Typography>
          </Box>
        ))}
      </Stack>
    </>
  );
};

export default PreviewPanel;
