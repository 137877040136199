import { styled } from '@mui/material/styles';
import { Box, LinearProgress } from '@mui/material';
import * as React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectEndpoint } from '@features/endpoint/endpointSlice';
import { HeaderParams, PreventAction } from '../wizardSlice';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const CustomButton = styled('button')(({ theme, disabled }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 400,
  color: disabled ? theme.palette.grey[300] : theme.palette.primary.main,
  backgroundColor: 'transparent',
  border: 'none',
  cursor: disabled ? 'initial' : 'pointer',
}));

const Header = ({
  percentComplete,
  disableBackButton,
  hidePercentComplete,
  hideBackButton,
  onBack,
  component,
}: HeaderParams & { percentComplete: number }) => {
  const endpointConfig = useAppSelector(selectEndpoint);
  const navigate = useNavigate();

  const { customerLogo } = endpointConfig.customer;
  const goBack = useMemo(
    () => async () => {
      if (onBack) {
        const result = await onBack();
        if (result === PreventAction.yes) {
          return;
        }
      }
      navigate(-1);
    },
    [navigate, onBack]
  );

  // If an override component is passed, use that
  if (component) {
    return component;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          flexDirection: 'row',
        }}
      >
        {hideBackButton ? (
          <CustomButton disabled />
        ) : (
          <CustomButton onClick={goBack} disabled={disableBackButton}>
            <ArrowBackIosIcon sx={{ fontSize: '1.2rem' }} />
          </CustomButton>
        )}
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <img src={customerLogo} height={24} />
        </Box>

        <CustomButton disabled />
      </Box>
      {!hidePercentComplete && (
        <LinearProgress variant='determinate' value={percentComplete} />
      )}
    </Box>
  );
};
export default Header;
