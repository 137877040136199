import React, { useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  StepData,
  StepType,
  UNAUTHENTICATED_STEPS,
  gotoNextStep,
  selectWizard,
} from '../wizardSlice';
import { useAppSelector } from '@hooks/useAppSelector';
import Header from './Header';
import Footer from './Footer';
import TestStep from './TestStep';
import { VerificationWelcome } from '@features/verification/steps/welcome/Welcome';
import { VerificationUnauthorized } from '@features/verification/steps/auth/steps/Unauthorized';
import { VerificationChallenged } from '@features/verification/steps/auth/steps/Challenged';
import { VerificationFields } from '@features/verification/steps/verify-form/vskuTypes/VerifyFields';
import { VerificationReviewAndSubmit } from '@features/verification/steps/review-and-submit/ReviewAndSubmit';
import { VerificationResultStep } from '@features/verification/steps/result/ResultStep';
import { VerificationUploadDocument } from '@features/verification/steps/verify-form/vskuTypes/UploadDocument';
import { VerificationSelection } from '@features/verification/steps/verify-form/vskuTypes/Selection';
import { VerificationSignPrepare } from '@features/verification/steps/verify-form/vskuTypes/EsignPrepare';
import { VerificationSignDocument } from '@features/verification/steps/verify-form/vskuTypes/EsignDocument';
import { VerificationMobile } from '@features/verification/steps/mobile-number/VerificationMobile';
import { MobileChallenged } from '@features/verification/steps/mobile-number/MobileChallenged';
import { useAppDispatch } from '@hooks/useAppDispatch';
import InvalidStep from './InvalidStep';
import {
  AuthorizationSteps,
  selectAuthentication,
} from '@features/verification/steps/auth/authSlice';

export interface ContentProps {
  stepIndex: number;
  stepKey: string;
  currentStepData: StepData;
}

const Content = ({ stepIndex, stepKey, currentStepData }: ContentProps) => {
  const { stepType } = currentStepData;

  switch (stepType) {
    case StepType.welcome:
      return (
        <VerificationWelcome
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
    case StepType['auth.unauthenticated']:
      return (
        <VerificationUnauthorized
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
    case StepType['auth.challenged']:
      return (
        <VerificationChallenged
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
    case StepType['vsku.field']:
      return (
        <VerificationFields
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
    case StepType['vsku.document']:
      return (
        <VerificationUploadDocument
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
    case StepType['vsku.selection']:
      return (
        <VerificationSelection
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
    case StepType['vsku.esignPrepare']:
      return (
        <VerificationSignPrepare
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
    case StepType['vsku.esignDocument']:
      return (
        <VerificationSignDocument
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
    case StepType['vsku.mobile']:
      return (
        <VerificationMobile
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
    case StepType['mobile.challenged']:
      return (
        <MobileChallenged
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );

    case StepType.reviewAndSubmit:
      return (
        <VerificationReviewAndSubmit
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
    case StepType.completed:
      return (
        <VerificationResultStep
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );

    default:
      return (
        <TestStep
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      );
  }
};

export const Step = () => {
  const { step: stepKey } = useParams<{ step: string }>();

  const { stepData, stepKeys, totalSteps, isInitialized, claims } =
    useAppSelector(selectWizard);
  const auth = useAppSelector(selectAuthentication);
  const dispatch = useAppDispatch();
  const stepIndex = useMemo(() => {
    return stepKeys.indexOf(stepKey);
  }, [stepKeys, stepKey]);

  const navigate = useNavigate();
  const hasNext = useMemo(() => {
    return stepKeys.indexOf(stepKey) < totalSteps - 1;
  }, [stepKey, stepKeys, totalSteps]);

  useEffect(() => {
    if (!isInitialized && stepKey !== undefined) {
      navigate('../', { replace: true });
    }
  }, [stepKey, isInitialized]);

  const currentStepData = useMemo(() => stepData[stepKey], [stepData, stepKey]);
  const invalidState = useMemo(
    () =>
      auth.authStep !== AuthorizationSteps.Authorized &&
      currentStepData &&
      !UNAUTHENTICATED_STEPS.includes(currentStepData?.stepType),
    [currentStepData, auth]
  );

  useEffect(() => {
    const condition = currentStepData?.stepParams?.condition as Record<
      string,
      string
    >;
    if (
      condition &&
      Object.entries(condition).some(([key, value]) => claims[key] !== value)
    ) {
      dispatch(gotoNextStep({ stepKey, replace: true }));
    }
  }, [currentStepData, claims]);

  if (!currentStepData || invalidState) {
    console.warn('No current step data', stepKey, stepIndex, stepData);
    if (Object.keys(stepData).length === 0) {
      // Wizard still initializing, render nothing
      return null;
    }
    // We got into an invalid screen - render an error step
    return <InvalidStep />;
  }

  const { header, footer } = currentStepData;
  const percentComplete = ((stepIndex + 1) / totalSteps) * 100;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ flex: 'none', pb: '1.5rem' }}>
        <Header {...header} percentComplete={percentComplete} />
      </Box>
      <Stack sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
        <Content
          key={`step-${stepKey}`}
          stepIndex={stepIndex}
          stepKey={stepKey}
          currentStepData={currentStepData}
        />
      </Stack>
      <Box sx={{ flex: 'none', pt: '0.5rem' }}>
        <Footer
          {...footer}
          hasNext={hasNext}
          stepIndex={stepIndex}
          stepKey={stepKey}
        />
      </Box>
    </Box>
  );
};
