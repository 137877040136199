import React, { useContext } from 'react';
import { Box, Stack, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { resetWizard } from '../wizardSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { FormattedMessage } from 'react-intl';
import { AnalyticsContext } from '@context/analyticsContext';
import { ErrorIcon } from '@shared/icons';

export const InvalidStep = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { logEvent } = useContext(AnalyticsContext);

  const onRestart = () => {
    logEvent({ event: 'error_page' });
    dispatch(resetWizard());
    navigate('../', { replace: true });
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Stack spacing={2} direction='column' alignItems='center'>
          <ErrorIcon />
          <Typography
            color='emphasis.dark'
            variant='h4'
            textAlign='center'
            paddingBottom='0.5rem'
          >
            <FormattedMessage
              defaultMessage='Oops!'
              description='Error header for invalid step'
            />
          </Typography>
          <Typography variant='h6' textAlign='center'>
            <FormattedMessage
              description='Error text for invalid step'
              defaultMessage="Something went wrong. It might just be a hiccup. <b>Please try again or contact us for assistance. We're happy to assist you.</b>"
              values={{
                b: val => <b>{val}</b>,
              }}
            />
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ flex: 'none', pt: '0.5rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant='outlined'
              color='emphasis'
              href='mailto:support@mesh.id'
              fullWidth
            >
              Contact Us
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              color='emphasis'
              onClick={onRestart}
              fullWidth
            >
              Try again
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InvalidStep;
