import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import SmartySDK from 'smartystreets-javascript-sdk';

import { makeGraphQlCall } from '@utils/graphql';
import { selectAuthentication } from '@features/verification/steps/auth/authSlice';

import {
  errorAutoCompleteAddress,
  finishAutoCompleteAddress,
  startAutoCompleteAddress,
  startSelectAddress,
  finishSelectAddress,
  errorSelectAddress,
  type AddressDetails,
  AddressRequest,
  AddressComponents,
  StartSelectAddressPayload,
} from './addressSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import config from '@utils/config';

const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usAutocompletePro.Lookup;
const credentials = new SmartyCore.SharedCredentials(config.smartyKey);
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
  'us-autocomplete-pro-cloud',
]);
const client = clientBuilder.buildUsAutocompleteProClient();

const getAddressDetailsQuery = `query GetAddressDetails($fullAddress: String!, $components: AddressComponentsInput) {
  getAddressDetails(fullAddress: $fullAddress, components: $components) {
    encodedAddress
    fullAddress
    components {
      address1
      address2
      city
      streetName
      state
      primaryNumber
      postalCode
      lastLine
      externalId
      country
      coordinates {
        latitude
        longitude
        precision
      }
    }
  }
}
`;

export interface AutocompleteAddressOption {
  components?: AddressComponents;
  fullAddress: string;
  id: string;
}

const performAddressLookup = async (
  address: string
): Promise<AutocompleteAddressOption[]> => {
  if (!address) {
    return [];
  }
  const lookup = new Lookup(address);
  lookup.maxResults = 10;
  lookup.preferRatio = 33;
  const results = await client.send(lookup);
  const { result } = results;
  return result.map(({ streetLine, secondary, city, state, zipcode }, idx) => ({
    components: {
      address1: streetLine,
      address2: secondary,
      city,
      state,
      postalCode: zipcode,
      country: 'US',
    },
    fullAddress: [
      streetLine,
      ...(secondary ? [' ', secondary] : []),
      ', ',
      city,
      ', ',
      state,
      ' ',
      zipcode,
    ]
      .filter(v => v)
      .join(''),
    id: `address-option-${idx}`,
  }));
};

function* handleStartAutoCompleteAddress(action: PayloadAction<string>) {
  try {
    const results: AutocompleteAddressOption[] = yield call(
      performAddressLookup,
      action.payload
    );
    yield put(finishAutoCompleteAddress(results));
  } catch (e) {
    yield put(errorAutoCompleteAddress(e.message));
  }
}

function* handleStartSelectAddress(
  action: PayloadAction<StartSelectAddressPayload>
) {
  if (
    !action.payload ||
    !action.payload.addressRequest ||
    !action.payload.addressRequest.fullAddress
  ) {
    yield put(finishSelectAddress(null));
    return;
  }
  try {
    const { addressRequest } = action.payload;
    const { accessToken } = selectAuthentication(yield select());
    const data: { getAddressDetails: AddressDetails } = yield call(
      makeGraphQlCall,
      {
        query: getAddressDetailsQuery,
        variables: addressRequest,
        accessToken: accessToken,
      }
    );
    console.log('Results', data);
    yield put(finishSelectAddress(data.getAddressDetails));
  } catch (e) {
    yield put(errorSelectAddress(e.message));
  }
}

function* addressSaga() {
  yield takeLatest(
    startAutoCompleteAddress.type,
    handleStartAutoCompleteAddress
  );
  yield takeEvery(startSelectAddress.type, handleStartSelectAddress);
}

export default addressSaga;
