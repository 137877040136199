import { useAppDispatch } from '@hooks/useAppDispatch';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  Authentication,
  AuthorizationSteps,
  selectAuthentication,
  startAuthByEmail,
  startLogout,
} from '@features/verification/steps/auth/authSlice';
import { useWizard } from '@shared/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { ContentProps } from '@features/verification/VerificationWizard/components/Step';
import {
  PreventAction,
  StepType,
  gotoNextStep,
  insertStep,
  setStepData,
  setStepFooter,
} from '@features/verification/VerificationWizard/wizardSlice';
import { AnalyticsContext } from '@context/analyticsContext';
import { ExperimentContext } from '@context/experimentContext';

export const VerificationUnauthorized = ({
  stepIndex,
  stepKey,
}: ContentProps) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuthentication);
  const [email, setEmail] = useState(auth.emailAddress);
  const [submitting, setSubmitting] = useState(false);
  const { logEvent, identifyUser } = React.useContext(AnalyticsContext);
  const { targetUser } = React.useContext(ExperimentContext);

  const handleSubmit = useMemo(
    () => async () => {
      if (auth.authStep === AuthorizationSteps.Unauthorized) {
        setSubmitting(true);
        dispatch(startAuthByEmail(email));
        dispatch(
          setStepData({
            stepKey,
            data: {
              inputValues: { email },
            },
          })
        );
      } else {
        console.log('Error: got submit without being unauthorized');
      }
      return PreventAction.yes;
    },
    [submitting, email, auth.authStep]
  );

  useEffect(() => {
    if (auth.authStep === AuthorizationSteps.Unauthorized) {
      logEvent({ event: 'enter_email' });
    }
  }, [auth.authStep]);

  useEffect(() => {
    if (submitting && !auth.isProcessing) {
      setSubmitting(false);
      if (auth.authStep === AuthorizationSteps.Authorized) {
        identifyUser({ userId: auth.emailAddress });
        targetUser({ userId: auth.emailAddress });
        logEvent({ event: 'authorized' });
        dispatch(gotoNextStep({ stepKey }));
      }
      if (auth.authStep === AuthorizationSteps.Challenged) {
        dispatch(
          insertStep({
            stepIndex: stepIndex + 1,
            stepData: {
              stepType: StepType['auth.challenged'],
              stepParams: {},
              data: {},
            },
          })
        );
        dispatch(gotoNextStep({ stepKey }));
      }
    }
  }, [submitting, auth.authStep, auth.isProcessing, stepIndex, stepKey]);

  useEffect(() => {
    if (
      !submitting &&
      !auth.isProcessing &&
      auth.authStep !== AuthorizationSteps.Unauthorized
    ) {
      console.log('logging out');
      dispatch(startLogout());
    }
  }, [submitting, auth.authStep, auth.isProcessing]);

  useEffect(() => {
    const validEmail =
      email?.length > 0 && email.includes('@') && email.includes('.');
    dispatch(
      setStepFooter({
        stepKey,
        footer: {
          onNext: handleSubmit,
          hideNextButton: !validEmail,
          nextLoading: submitting,
        },
      })
    );
  }, [handleSubmit, email, submitting]);
  return (
    <>
      <Typography
        color='emphasis.dark'
        variant='h4'
        textAlign='center'
        paddingBottom='0.5rem'
      >
        <FormattedMessage
          description='Verification wizard step label authenticate'
          defaultMessage='Start with your email'
        />
      </Typography>
      <Typography variant='subtitle1' textAlign='center' paddingBottom='2rem'>
        <FormattedMessage
          description='Verification wizard step descirtion label authenticate'
          defaultMessage='Confirm your work email address'
        />
      </Typography>
      <UnauthorizedBody
        auth={auth}
        email={email}
        setEmail={setEmail}
        onSubmit={handleSubmit}
      />
    </>
  );
};

const Unauthorized = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuthentication);
  const [email, setEmail] = useState(auth.emailAddress);
  const { overrideButtonsController } = useWizard();

  const handleSubmit = () => {
    dispatch(startAuthByEmail(email));
  };

  useEffect(() => {
    console.log('override Unauthorized');
    const validEmail =
      email?.length > 0 && email.includes('@') && email.includes('.');
    overrideButtonsController({
      next: {
        onClick: handleSubmit,
        isHidden: () => !validEmail,
        isDisabled: () => false,
      },
      previous: {
        isDisabled: () => true,
      },
    });
  }, [email]);
  return UnauthorizedBody({
    auth,
    email,
    setEmail,
    onSubmit: handleSubmit,
  });
};

const UnauthorizedBody = ({
  auth,
  email,
  setEmail,
  onSubmit,
}: {
  auth: Authentication;
  email: string;
  setEmail: (email: string) => void;
  onSubmit: () => void;
}) => {
  const intl = useIntl();
  const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      onSubmit();
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        gap: 1,
        paddingTop: 1,
      }}
      component='form'
      noValidate
      onSubmit={e => e.preventDefault()}
    >
      <TextField
        fullWidth
        error={!!auth.errorMessage}
        helperText={
          auth.errorMessage ? intl.formatMessage({ id: auth.errorMessage }) : ''
        }
        label={intl.formatMessage({
          description:
            'Placeholder message for email address field during authentication',
          defaultMessage: 'Email Address',
        })}
        onKeyDown={handleKey}
        value={email}
        onChange={e => setEmail(e.target.value)}
        id='email'
        required
        name='email'
        autoComplete='email'
        autoFocus
      />
      <Typography
        variant='body2'
        sx={{ fontSize: '11px' }}
        color='text.secondary'
      >
        <FormattedMessage
          description='Email required disclaimer'
          defaultMessage='This will be used by Mesh for important emails and to help you maintain your Verified status.'
        />
      </Typography>
    </Box>
  );
};

export default Unauthorized;
