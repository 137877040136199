export const isValueEmpty = (value: any): boolean =>
  value === undefined ||
  value === null ||
  (typeof value === 'string' && value.trim() === '') ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === 'object' && Object.keys(value).length === 0);

export const isFormEmpty = (form: Record<string, any>): boolean =>
  Object.values(form).every(isValueEmpty);

export const isFormSkippable = ({
  form,
  isValid,
  allowSkip,
}: {
  form: Record<string, any>;
  isValid: boolean;
  allowSkip: boolean;
}): boolean => {
  if (!allowSkip) {
    return false;
  }

  // If the form is not empty and valid, probably the user wants to submit it
  // TODO: https://app.asana.com/0/1206509552588324/1207513787112621
  if (isValid && !isFormEmpty(form)) {
    return false;
  }

  return true;
};
