import React, { ReactNode, useContext } from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';
import { OverlayLoader } from '@shared/components';
import { AppContext } from '@context/app.context';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectEndpoint } from '@features/endpoint/endpointSlice';

const HostedView = ({
  customerLogo,
  children,
}: {
  customerLogo: string;
  children: ReactNode;
}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          height: {
            xs: '100%',
            sm: '100vh',
          },
          width: '100%',
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: {
                xs: '100%',
                sm: '100vh',
              },
              minWidth: '350px',
              maxWidth: '440px',
              maxHeight: {
                xs: '100%',
                sm: '690px',
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const CustomerLayout = ({ children }: { children: ReactNode }) => {
  const { isLoading } = useContext(AppContext);
  const endpointConfig = useAppSelector(selectEndpoint);

  if (isLoading) {
    return <OverlayLoader isLoading={isLoading} />;
  }
  return (
    <>
      <HostedView customerLogo={endpointConfig.customer.customerLogo}>
        {children}
      </HostedView>
      <OverlayLoader isLoading={isLoading} />
    </>
  );
};

export default CustomerLayout;
