import React, { useEffect } from 'react';

import { useAppSelector } from '@hooks/useAppSelector';
import { selectEndpoint } from '@features/endpoint/endpointSlice';
import { useNavigate } from 'react-router-dom';

const Alias = (): JSX.Element => {
  const endpointConfig = useAppSelector(selectEndpoint);
  const navigate = useNavigate();

  useEffect(() => {
    if (!endpointConfig.isLoading && endpointConfig.endpointLoaded) {
      navigate(`/v/${endpointConfig.endpointId}${window.location.search}`, {
        replace: true,
      });
    }
  }, [endpointConfig]);

  return null;
};

export default Alias;
