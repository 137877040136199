import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@config/store';

export type EsignSigner = {
  name: string;
  emailAddress: string;
};

export type W9EsignPayloadData = {
  businessType: string;
  businessName: string;
  businessAddress: string;
  taxIdentificationNumber: string;
  taxIdentificationNumberType: string;
  taxClassification?: string;
  disregardedEntityName?: string;
};

export type EsignPayloadData = W9EsignPayloadData;

export type EsignPayload = {
  documentType: string;
  documentReference: string;
  signer: EsignSigner;
  data?: EsignPayloadData;
  vsku: string;
};

export type EsignState = {
  isLoading: boolean;
  payload: EsignPayload;
  errorMessage: string;
  embedUrl: string;
  id: string;
  fileName: string;
};

export type EsignResponse = {
  id: string;
  url: string;
};

const initialState: EsignState = {
  isLoading: false,
  payload: {
    documentType: '',
    documentReference: '',
    signer: {
      name: '',
      emailAddress: '',
    },
    vsku: '',
  },
  errorMessage: '',
  embedUrl: '',
  id: '',
  fileName: '',
};

export const esignSlice = createSlice({
  name: 'esign',
  initialState,
  reducers: {
    startSigning: (state, action: PayloadAction<EsignPayload>): void => {
      state.isLoading = true;
      state.payload = action.payload;
      state.id = '';
      state.embedUrl = '';
      state.errorMessage = '';
      state.fileName = `${action.payload.documentType}-${action.payload.signer.name}.pdf`;
    },
    finishSigning: (state, action: PayloadAction<EsignResponse>): void => {
      state.isLoading = false;
      state.embedUrl = action.payload.url;
      state.id = action.payload.id;
      state.errorMessage = '';
    },
    errorSigning: (state, action: PayloadAction<string>): void => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { startSigning, finishSigning, errorSigning } = esignSlice.actions;

export const selectEsign = (state: RootState) => state.esign;

export default esignSlice.reducer;
