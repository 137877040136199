import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Link,
  Slide,
  Typography,
} from '@mui/material';
import { Mesh } from '@shared/icons';
import { FormattedMessage } from 'react-intl';
import {
  FooterParams,
  PreventAction,
  gotoNextStep,
  selectWizard,
} from '../wizardSlice';
import { useMemo } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import VerificationPoweredBy from '../../../../shared/components/VerificationPoweredBy';

export const nextButtonLabel = (
  <FormattedMessage
    description='Next button label for verification wizard'
    defaultMessage='Next'
  />
);
export const skipButtonLabel = (
  <FormattedMessage
    description='Skip button label for verification wizard'
    defaultMessage='Skip'
  />
);

const Footer = ({
  disableNextButton,
  hideNextButton,
  displayLinks,
  component,
  disclaimer,
  onNext,
  nextLoading,
  nextVariant = 'contained',
  nextLabel = nextButtonLabel,
  stepIndex,
  stepKey,
}: FooterParams & { hasNext: boolean; stepIndex: number; stepKey: string }) => {
  const dispatch = useDispatch();
  const { totalSteps, stepKeys } = useAppSelector(selectWizard);

  const goNext = useMemo(
    () => async () => {
      if (onNext) {
        const result = await onNext();
        if (result === PreventAction.yes) {
          return;
        }
      }
      dispatch(gotoNextStep({ stepKey }));
    },
    [onNext, stepIndex, stepKeys, totalSteps]
  );

  if (component) {
    return component;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {disclaimer !== undefined && (
        <Typography
          variant='caption'
          textAlign='center'
          color='text.secondary'
          pb='0.5rem'
        >
          {disclaimer}
        </Typography>
      )}
      <Slide
        direction='up'
        in={!hideNextButton}
        timeout={200}
        unmountOnExit={true}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Fade in={!hideNextButton} timeout={500} unmountOnExit={true}>
            <Button
              variant={nextVariant}
              color='emphasis'
              fullWidth
              size='large'
              onClick={goNext}
              disabled={disableNextButton || nextLoading}
            >
              {nextLoading ? (
                <CircularProgress color='primary' size={26.25} />
              ) : (
                nextLabel
              )}
            </Button>
          </Fade>
        </Box>
      </Slide>
      {displayLinks && (
        <Typography variant='body2' textAlign='center'>
          <FormattedMessage
            description='Footer links for verification wizard'
            defaultMessage='<privacyPrinciples>Privacy Principles</privacyPrinciples> | <privacyStatement>Privacy Statement</privacyStatement> | <termsOfService>Terms of Service</termsOfService>'
            values={{
              privacyPrinciples: val => (
                <Link
                  target='_blank'
                  href='https://www.mesh.id/mesh-privacy-principles'
                >
                  {val}
                </Link>
              ),
              privacyStatement: val => (
                <Link
                  target='_blank'
                  href='https://www.mesh.id/privacy-statement'
                >
                  {val}
                </Link>
              ),
              termsOfService: val => (
                <Link
                  target='_blank'
                  href='https://www.mesh.id/terms-of-service'
                >
                  {val}
                </Link>
              ),
            }}
          />
        </Typography>
      )}
      <VerificationPoweredBy />
    </Box>
  );
};

export default Footer;
