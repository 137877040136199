import * as React from 'react';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { useEffect } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  resendVerifyPhoneNumber,
  selectMobileNumber,
  startVerifyPhoneNumber,
} from './mobileNumberSlice';
import {
  PreventAction,
  removeStep,
  setStepData,
  setStepFooter,
} from '@features/verification/VerificationWizard/wizardSlice';
import { ContentProps } from '@features/verification/VerificationWizard/components/Step';
import { VSkuType } from '@features/endpoint/endpointSlice';
import { MeshOtpInput } from '@shared/components';

export const MobileChallenged = ({
  currentStepData,
  stepIndex,
  stepKey,
}: ContentProps) => {
  const dispatch = useAppDispatch();
  const { phoneNumber, errorMessage, isProcessing, verified } =
    useAppSelector(selectMobileNumber);

  const [otp, setOtp] = React.useState('');
  const intl = useIntl();
  const submitChallengeCode = React.useMemo(
    () => async (code: string) => {
      console.log('submitting', code);
      dispatch(startVerifyPhoneNumber(code));
      return PreventAction.yes;
    },
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(
      setStepFooter({
        stepKey,
        footer: {
          hideNextButton: otp?.length < 6,
          nextLoading: isProcessing,
          onNext: () => submitChallengeCode(otp),
        },
      })
    );
  }, [otp, isProcessing, submitChallengeCode]);

  useEffect(() => {
    if (verified) {
      if (currentStepData.stepParams?.stepKey) {
        const { vsku, vspecId } = currentStepData.stepParams;
        dispatch(
          setStepData({
            // Set the value associated with the VerificationMobile step
            stepKey: currentStepData.stepParams.stepKey,
            data: {
              vspecId,
              vsku: vsku.vsku,
              stepType: VSkuType.mobileVerification,
              primaryInputs: vsku.primaryInputs,
              inputValues: {
                mobileNumber: phoneNumber.replace(/ /g, ''),
                consentToMobileNumberVerification: true,
              },
              jurisdiction: vsku.jurisdiction,
            },
          })
        );
      }
      dispatch(removeStep({ stepIndex, gotoNext: true }));
    }
  }, [verified]);

  const handleChangeOtp = (newValue: string) => {
    setOtp(newValue);
    if (newValue.length >= 6) {
      submitChallengeCode(newValue);
    }
  };

  const handleResendOtpCode = () => {
    setOtp('');
    dispatch(resendVerifyPhoneNumber());
  };

  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
      }}
      noValidate
      onSubmit={e => {
        e.preventDefault();
        submitChallengeCode(otp);
      }}
    >
      <Typography color='emphasis.dark' variant='h3' textAlign='center'>
        <FormattedMessage
          description='Mobile Challenge Header'
          defaultMessage='Secure your account'
        />
      </Typography>
      <Typography variant='subtitle1' textAlign='center'>
        <FormattedMessage
          description='Phone verification code disclaimer'
          defaultMessage='Please, enter the code we just texted you at <b>{phoneNumber}</b>'
          values={{
            phoneNumber,
            b: val => <b style={{ whiteSpace: 'nowrap' }}>{val}</b>,
          }}
        />
      </Typography>
      <MeshOtpInput value={otp} onChange={handleChangeOtp} />
      {errorMessage && (
        <Typography variant='subtitle2' color='error'>
          {intl.formatMessage({ id: errorMessage })}
        </Typography>
      )}
      <Typography variant='subtitle2'>
        <FormattedMessage
          description='verification code text'
          defaultMessage='<resend>Resend</resend> verification code'
          values={{
            resend: val => (
              <Link component='button' onClick={handleResendOtpCode}>
                {val}
              </Link>
            ),
          }}
        />
      </Typography>
    </Box>
  );
};
