import * as React from 'react';
import { Loader } from '@shared/components';
import { createBrowserRouter, Params, RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { IntlProvider, MessageFormatElement } from 'react-intl';
import { CustomerLayout } from '@shared/layouts';
import { ThemeProvider } from '@mui/material';
import { MeshTheme } from '@shared/themes';
import AppProvider from '@context/app.context';
import AnalyticsProvider from '@context/analyticsContext';
import { EmbeddedTester } from '@features/embeddedTester/EmbeddedTester';
import { Experiment } from '@context/experimentContext';
import { Step } from '@features/verification/VerificationWizard/components/Step';
import Alias from '@features/endpoint/Alias';
import { StandAloneResult } from '@features/verification/steps/result/ResultStep';

const Endpoint = React.lazy(() => import('./features/endpoint/Endpoint'));
const NotFound = React.lazy(() => import('./features/not-found/NotFound'));

const VerificationWizard = React.lazy(
  () => import('@features/verification/VerificationWizard')
);
const InitializeWizard = React.lazy(
  () =>
    import(
      '@features/verification/VerificationWizard/components/InitializeWizard'
    )
);

type Messages = Record<string, string> | Record<string, MessageFormatElement[]>;

const loadLocaleData = (locale: string) => {
  if (locale.startsWith('en')) {
    return import('../compiled-lang/en.json');
  }
  // Fallback locale
  return import('../compiled-lang/en.json');
};

const router = createBrowserRouter([
  {
    path: `/a/*`,
    loader: ({ params }: { params: Params<string> }) => ({
      alias: params['*'],
    }),
    element: (
      <React.Suspense fallback={<Loader />}>
        <Endpoint>
          <CustomerLayout>
            <Alias />
          </CustomerLayout>
        </Endpoint>
      </React.Suspense>
    ),
  },
  {
    path: '/v/:endpoint',
    loader: ({ params }) => ({ endpoint: params.endpoint }),
    element: (
      <React.Suspense fallback={<Loader />}>
        <Endpoint>
          <CustomerLayout>
            <VerificationWizard />
          </CustomerLayout>
        </Endpoint>
      </React.Suspense>
    ),
    children: [
      {
        path: 'complete',
        element: <StandAloneResult />,
      },
      {
        path: ':step',
        element: <Step />,
      },
      {
        index: true,
        element: <InitializeWizard />,
      },
    ],
  },
  {
    path: '/tester',
    element: <EmbeddedTester />,
  },
  {
    path: '/*',
    element: <NotFound />,
  },
]);

const App: React.FC = () => {
  const [messages, setMessages] = React.useState<Messages>({});
  const languages = navigator.languages;
  console.log('found languages', languages);
  React.useEffect(() => {
    const loadMessages = async () => {
      const loaded = await loadLocaleData(languages[0]);
      console.log('got messages', loaded);
      // setMessages(loaded)
      setMessages(loaded.default);
    };
    loadMessages();
  }, [navigator.languages]);

  const tellParentClosed = () => {
    if (window.opener) {
      window.opener.postMessage({ event: 'page_closed', data: {} }, '*');
    }
  };
  React.useEffect(() => {
    window.addEventListener('beforeunload', tellParentClosed);
    return () => {
      window.removeEventListener('beforeunload', tellParentClosed);
    };
  });

  return (
    <ThemeProvider theme={MeshTheme}>
      <IntlProvider locale='en' messages={messages} defaultLocale='en'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppProvider>
            <Experiment>
              <AnalyticsProvider>
                <RouterProvider router={router} />
              </AnalyticsProvider>
            </Experiment>
          </AppProvider>
        </LocalizationProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
