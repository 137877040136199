import {
  Box,
  Grid,
  Button,
  TextField,
  Stack,
  Typography,
  CircularProgress,
  Fade,
} from '@mui/material';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useWizard } from '@shared/components';
import { useEffect } from 'react';
import {
  VerificationOrderCreated,
  VerificationOrderItem,
} from '@features/verification-order/types';
import { startCreateVerificationOrder } from '@features/verification-order/verificationOrderSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { AnalyticsContext } from '@context/analyticsContext';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectEndpoint } from '@features/endpoint/endpointSlice';
import { selectVerificationOrder } from '@features/verification-order/verificationOrderSlice';
import { ContentProps } from '@features/verification/VerificationWizard/components/Step';
import {
  resetWizard,
  selectWizard,
  setStepFooter,
  setStepHeader,
} from '@features/verification/VerificationWizard/wizardSlice';
import { StepValue } from '@shared/components/MeshWizard/types/types';
import { useNavigate } from 'react-router-dom';
import { ErrorIcon } from '@shared/icons';
import { v4 as uuid } from 'uuid';

const ADDRESS_CLAIM_NAMES = [
  'businessAddress',
  'insuranceRegisteredBusinessAddress',
];
const LABELS = [
  {
    label: (
      <FormattedMessage
        description='First label during progress spinner'
        defaultMessage='Helping you succeed... 🚀'
      />
    ),
    timing: 2000,
  },
  {
    timing: 500,
  },
  {
    label: (
      <FormattedMessage
        description='Second label during submission progress spinner'
        defaultMessage='...and win the day 😁'
      />
    ),
  },
];

const LabelTransitions = ({
  labels,
}: {
  labels: { label?: JSX.Element; timing?: number }[];
}): JSX.Element => {
  const [step, setStep] = React.useState(0);

  useEffect(() => {
    if (labels[step] && labels[step].timing) {
      setTimeout(() => {
        console.log('setting step', step + 1);
        setStep(step + 1);
      }, labels[step].timing);
    }
  }, [step, labels]);

  return (
    <>
      {labels.map(({ label }, index) => (
        <Fade
          key={`transition-${index}`}
          in={step === index}
          appear={index !== 0}
          unmountOnExit
        >
          <Typography variant='body1' textAlign='center'>
            {label}
          </Typography>
        </Fade>
      ))}
    </>
  );
};

export const VerificationReviewAndSubmit = ({
  stepKey,
  currentStepData,
}: ContentProps) => {
  const dispatch = useAppDispatch();
  const { stepData } = useAppSelector(selectWizard);
  const { logEvent } = React.useContext(AnalyticsContext);
  const intl = useIntl();
  const endpointConfig = useAppSelector(selectEndpoint);
  const orderConfig = useAppSelector(selectVerificationOrder);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const orderId = React.useRef(uuid());

  const { vowId, customerId } = currentStepData?.stepParams ?? {};

  const { isLoading, verificationOrderCreated, errorMessage } = orderConfig;
  const vspecs = Object.fromEntries(
    endpointConfig.vspecs.map(({ vspecId, vsku }) => [vspecId, vsku])
  );
  useEffect(() => {
    dispatch(
      setStepFooter({
        stepKey,
        footer: {
          hideNextButton: true,
        },
      })
    );
    dispatch(
      setStepHeader({
        stepKey,
        header: {
          hideBackButton: loading,
          hidePercentComplete: true,
        },
      })
    );
  }, [loading]);
  // Allow steps to use stepData without needing to be submitting claims in the order
  const stepValues = React.useMemo(
    () => Object.values(stepData).map(({ data }) => data) as StepValue[],
    [stepData]
  ).filter(val => val && val.vsku);

  const mapValue = (name: string, value: unknown): string => {
    if (
      ADDRESS_CLAIM_NAMES.includes(name) &&
      typeof value === 'object' &&
      'encodedAddress' in value
    ) {
      return value.encodedAddress as string;
    }
    return value as string;
  };

  const submitVerificationOrder = () => {
    const verificationOrderItems = stepValues
      .map(
        ({ inputValues, vspecId, vsku, jurisdiction }, index) => {
          console.log('mapping stepValues', vsku, inputValues);
          const claims = Object.entries(inputValues)
            .map(
              ([name, value]) =>
                ({
                  name,
                  value: mapValue(name, value),
                } as { name: string; value: string })
              // Filter out empty strings
            )
            .filter(({ value }) => {
              // If the value is boolean, don't filter it out
              if (!value && typeof value === 'boolean') {
                return true;
              }
              // If the value is a string, filter out strings that only contain whitespace
              if (typeof value === 'string') {
                return value.trim() !== '';
              }
              return !!value;
            });
          if (claims.length === 0) {
            return undefined;
          }
          const jurisdictionClaim = claims.find(
            ({ name }) => name === jurisdiction
          );
          // If the jurisdiction claim is not found, add it to the list of claims since it is required
          const finalClaims = jurisdictionClaim
            ? claims
            : [...claims, { name: jurisdiction, value: 'US' }];
          const verificationOrderItem: VerificationOrderItem = {
            itemIndex: index,
            vspecId,
            vsku,
            claims: finalClaims,
            // Hard-code the jurisdiction to 'US' if not defined.
            // TODO: Fix this to pull the value from the VSKU instead
            // https://app.asana.com/0/1203555120952574/1205467694294700/f
            jurisdiction: jurisdictionClaim?.value ?? 'US',
          };
          return verificationOrderItem;
        }
        // Filter out any undefined items
      )
      .filter(val => val);
    const verificationOrder: VerificationOrderCreated = {
      verifierId: customerId,
      vowId,
      items: verificationOrderItems,
      orderId: orderId.current,
    };
    dispatch(
      startCreateVerificationOrder({
        verificationOrder,
      })
    );
  };

  const handleSubmitButton = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitVerificationOrder();
  };

  const allInputClaims = React.useMemo(() => {
    const inputs: Record<string, string> = {};
    console.log('processing stepValues', stepValues);
    if (!stepValues) {
      return [];
    }
    for (const {
      inputValues,
      primaryInputs,
      vspecId,
      jurisdiction,
    } of stepValues) {
      const vskuName = intl.formatMessage({ id: vspecs[vspecId].nameId });
      const primaryValues = primaryInputs
        .map(fieldName => inputValues[fieldName])
        .join(' ');

      const outputValue = `${primaryValues} (${inputValues[jurisdiction]})`;
      if (inputs[vspecId]) {
        inputs[vspecId] = [inputs[vspecId], outputValue].join(', ');
      } else {
        inputs[vspecId] = `${vskuName}: ${outputValue}`;
      }
    }

    return Object.entries(inputs);
  }, [stepValues]);

  useEffect(() => {
    if (errorMessage) {
      setLoading(isLoading);
    } else {
      setLoading(true);
    }
  }, [isLoading, errorMessage]);

  useEffect(() => {
    if (!verificationOrderCreated) {
      submitVerificationOrder();
    }
  }, [verificationOrderCreated]);

  React.useEffect(() => {
    if (verificationOrderCreated) {
      logEvent({
        event: 'verification_completed',
        properties: { outcome: 'pending' },
      });
      dispatch(resetWizard());
      navigate('../complete');
    }
  }, [verificationOrderCreated, stepKey]);

  return (
    <ReviewAndSubmitBody
      loading={loading}
      handleSubmitButton={handleSubmitButton}
      errorMessage={errorMessage}
      allInputClaims={allInputClaims}
    />
  );
};

const ReviewAndSubmit = ({
  vowId,
  customerId,
}: {
  vowId: string;
  customerId: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { goToNextStep, stepValues, overrideButtonsController } = useWizard();
  const orderId = React.useRef(uuid());
  const { logEvent } = React.useContext(AnalyticsContext);
  const intl = useIntl();
  const endpointConfig = useAppSelector(selectEndpoint);
  const orderConfig = useAppSelector(selectVerificationOrder);
  const [loading, setLoading] = React.useState(true);

  const { isLoading, verificationOrderCreated, errorMessage } = orderConfig;
  const vspecs = Object.fromEntries(
    endpointConfig.vspecs.map(({ vspecId, vsku }) => [vspecId, vsku])
  );
  logEvent({ event: 'verification_completed' });
  useEffect(() => {
    overrideButtonsController({
      next: {
        isHidden: () => true,
      },
      previous: {
        isHidden: () => loading,
      },
    });
  }, [loading]);

  const submitVerificationOrder = () => {
    // TODO: Add submitting for additional VskuType values
    const stepData = Object.values(stepValues);
    const verificationOrderItems = stepData.map(
      ({ inputValues, vspecId, vsku, jurisdiction }, index) => {
        const claims = Object.entries(inputValues).map(([name, value]) => ({
          name,
          value,
        }));
        const jurisdictionClaim = claims.find(
          ({ name }) => name === jurisdiction
        );
        const verificationOrderItem: VerificationOrderItem = {
          itemIndex: index,
          vspecId,
          vsku,
          claims,
          // Hard-code the jurisdiction to 'US' if not defined.
          // TODO: Fix this to pull the value from the VSKU instead
          // https://app.asana.com/0/1203555120952574/1205467694294700/f
          jurisdiction: jurisdictionClaim?.value ?? 'US',
        };
        return verificationOrderItem;
      }
    );
    const verificationOrder: VerificationOrderCreated = {
      verifierId: customerId,
      vowId,
      items: verificationOrderItems,
      orderId: orderId.current,
    };
    dispatch(
      startCreateVerificationOrder({
        verificationOrder,
      })
    );
  };

  const handleSubmitButton = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitVerificationOrder();
  };

  const allInputClaims = React.useMemo(() => {
    const inputs: Record<string, string> = {};
    if (!stepValues) {
      return [];
    }
    for (const value of Object.values(stepValues)) {
      const { inputValues, primaryInputs, vspecId, jurisdiction } = value;
      const vskuName = intl.formatMessage({ id: vspecs[vspecId].nameId });
      const primaryValues = primaryInputs
        .map(fieldName => inputValues[fieldName])
        .join(' ');

      const outputValue = `${primaryValues} (${inputValues[jurisdiction]})`;
      if (inputs[vspecId]) {
        inputs[vspecId] = [inputs[vspecId], outputValue].join(', ');
      } else {
        inputs[vspecId] = `${vskuName}: ${outputValue}`;
      }
    }

    return Object.entries(inputs);
  }, [stepValues]);

  useEffect(() => {
    if (errorMessage) {
      setLoading(isLoading);
    } else {
      setLoading(true);
    }
  }, [isLoading, errorMessage]);

  useEffect(() => {
    submitVerificationOrder();
  }, []);

  React.useEffect(() => {
    if (verificationOrderCreated) {
      goToNextStep();
    }
  }, [verificationOrderCreated]);

  return (
    <ReviewAndSubmitBody
      loading={loading}
      handleSubmitButton={handleSubmitButton}
      errorMessage={errorMessage}
      allInputClaims={allInputClaims}
    />
  );
};
const ReviewAndSubmitBody = ({
  loading,
  handleSubmitButton,
  errorMessage,
  allInputClaims,
}: {
  loading: boolean;
  errorMessage: string;
  allInputClaims: [string, string][];
  handleSubmitButton: (event: React.FormEvent) => void;
}) => {
  if (loading) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress size='4rem' />
        <Box sx={{ mt: 6, width: '100%' }}>
          <LabelTransitions labels={LABELS} />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Stack direction='column' alignItems='center'>
          <Box mb={2}>
            <ErrorIcon />
          </Box>
          <Typography
            color='emphasis.dark'
            variant='h4'
            textAlign='center'
            paddingBottom='0.5rem'
          >
            <FormattedMessage
              defaultMessage='Oops!'
              description='Error header for reviewAndSubmit'
            />
          </Typography>
          <Typography variant='h6' textAlign='center'>
            <FormattedMessage
              description='Error text for reviewAndSubmit'
              defaultMessage="Something went wrong. It might just be a hiccup. <b>Please try again or contact us for assistance. We're happy to assist you.</b>"
              values={{
                b: val => <b>{val}</b>,
              }}
            />
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ flex: 'none', pt: '0.5rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant='outlined'
              color='emphasis'
              href='mailto:support@mesh.id'
              fullWidth
            >
              Contact Us
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              color='emphasis'
              onClick={handleSubmitButton}
              fullWidth
            >
              Try again
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReviewAndSubmit;
