import { combineReducers } from '@reduxjs/toolkit';
import { reducer } from 'redux-storage';
import authReducer from '../features/verification/steps/auth/authSlice';
import endpointReducer from '../features/endpoint/endpointSlice';
import mobileNumberReducer from '../features/verification/steps/mobile-number/mobileNumberSlice';
import createVerificationOrderReducer from '../features/verification-order/verificationOrderSlice';
import wizardReducer from '../features/verification/VerificationWizard/wizardSlice';
import esignReducer from '../features/esign/esignSlice';
import documentReducer from '../utils/document/documentSlice';
import addressReducer from '../shared/components/VerifyInput/fieldTypes/VerifyInputAddress/addressSlice';

const rootReducer = reducer(
  combineReducers({
    address: addressReducer,
    auth: authReducer,
    endpoint: endpointReducer,
    createVerificationOrder: createVerificationOrderReducer,
    document: documentReducer,
    mobileNumber: mobileNumberReducer,
    wizard: wizardReducer,
    esign: esignReducer,
  })
);
export default rootReducer;
