import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';

import {
  PreventAction,
  selectWizard,
  setStepFooter,
} from '@features/verification/VerificationWizard/wizardSlice';

import { Box } from '@mui/material';

import PreviewPanel from '@shared/components/PreviewPanel';
import { EsignPayload, startSigning } from '../esignSlice';
import { AddressDetails } from '@shared/components/VerifyInput/fieldTypes/VerifyInputAddress/addressSlice';

const handleBusinessAddress = (address: string | AddressDetails) => {
  if (typeof address === 'string') {
    return address;
  }
  return address?.encodedAddress;
};

export const W9SignPrepare = ({
  payload,
  stepKey,
  disclaimerId,
}: {
  payload: EsignPayload;
  stepKey: string;
  disclaimerId: string;
}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { claims } = useAppSelector(selectWizard);
  const {
    businessType,
    businessName,
    businessAddress,
    taxIdentificationNumber,
    taxIdentificationNumberType,
    taxClassification,
    disregardedEntityName,
  } = claims;

  const data = {
    businessType,
    businessName,
    businessAddress: handleBusinessAddress(businessAddress),
    taxIdentificationNumber,
    taxIdentificationNumberType,
    taxClassification,
    disregardedEntityName,
  };

  const dataForPreview = {
    businessType,
    taxClassification,
    businessName,
    disregardedEntityName,
    businessAddress,
    taxIdentificationNumber,
  };

  // Intl Id for Preview Panel title
  const titleId = getIntlId('previewPanelTitle');

  useEffect(() => {
    dispatch(
      setStepFooter({
        stepKey,
        footer: {
          disclaimer: <FormattedMessage id={disclaimerId} />,
          onNext: handleNext,
        },
      })
    );
  }, []);

  const handleNext = async () => {
    dispatch(
      startSigning({
        ...payload,
        data,
      })
    );

    return PreventAction.no;
  };

  const items = Object.entries(dataForPreview)
    .filter(([_, value]) => !!value)
    .map(([key, value]) => {
      const result = {
        checked: true,
        text: value,
      };

      if (key === 'businessType') {
        result.text = `
          ${intl.formatMessage({ id: getIntlId(value) })}
        `;
      }

      if (
        key === 'businessAddress' &&
        typeof value === 'object' &&
        value &&
        'fullAddress' in value
      ) {
        const address = value as unknown as AddressDetails;
        result.text = address.fullAddress;
      }

      if (key === 'taxIdentificationNumber') {
        result.text = `
          ${intl.formatMessage({ id: getIntlId(taxIdentificationNumberType) })}
          ${value}
        `;
      }

      if (key === 'taxClassification') {
        result.text = `
          ${intl.formatMessage({ id: getIntlId(key) })}
          ${intl.formatMessage({ id: getIntlId(value) })}
        `;
      }

      return result;
    });

  return (
    <Box sx={{ backgroundColor: 'grey.50', padding: '16px 20px' }}>
      <PreviewPanel title={intl.formatMessage({ id: titleId })} items={items} />
    </Box>
  );
};

// Build Intl Ids from keys or values
const getIntlId = (key: string) => {
  return `vsku.esignPrepare.w9.${key}`;
};

// Intl messages
defineMessages({
  w9ESignPrepareSoleProprietor: {
    id: 'vsku.esignPrepare.w9.soleProprietor',
    description: 'Value to show on soleProprietor',
    defaultMessage: 'Sole proprietor',
  },
  w9ESignPrepareSingleMemberLlc: {
    id: 'vsku.esignPrepare.w9.singleMemberLlc',
    description: 'Value to show on singleMemberLlc',
    defaultMessage: 'Single-member LLC',
  },
  w9ESignPrepareTrustOrEstate: {
    id: 'vsku.esignPrepare.w9.trustOrEstate',
    description: 'Value to show on trustOrEstate',
    defaultMessage: 'Trust or Estate',
  },
  w9ESignPrepareLimitedLiabilityCompany: {
    id: 'vsku.esignPrepare.w9.limitedLiabilityCompany',
    description: 'Value to show on limitedLiabilityCompany',
    defaultMessage: 'Limited Liability Company (LLC)',
  },
  w9ESignPreparePartnership: {
    id: 'vsku.esignPrepare.w9.partnership',
    description: 'Value to show on partnership',
    defaultMessage: 'Partnership',
  },
  w9ESignPrepareCCorporation: {
    id: 'vsku.esignPrepare.w9.cCorporation',
    description: 'Value to show on cCorporation',
    defaultMessage: 'C Corporation',
  },
  w9ESignPrepareSCorporation: {
    id: 'vsku.esignPrepare.w9.sCorporation',
    description: 'Value to show on sCorporation',
    defaultMessage: 'S Corporation',
  },
  w9ESignPrepareTaxClassification: {
    id: 'vsku.esignPrepare.w9.taxClassification',
    description: 'Label to show Tax Classification combined with the value',
    defaultMessage: 'Tax Classification: ',
  },
  w9ESignPrepareTaxClassificationLabelValue: {
    id: 'vsku.esignPrepare.w9.taxClassificationLabelValue',
    description: 'Label to show Tax Classification combined with the value',
    defaultMessage: 'Tax Classification: ',
  },
  w9ESignPrepareSocialSecurityNumber: {
    id: 'vsku.esignPrepare.w9.socialSecurityNumber',
    description: 'Label to show SSN combined with the value',
    defaultMessage: 'SSN: ',
  },
  w9ESignPrepareEmployerIdentificationNumber: {
    id: 'vsku.esignPrepare.w9.employerIdentificationNumber',
    description: 'Label to show EIN combined with the value',
    defaultMessage: 'EIN: ',
  },
  w9ESignPreparePreviewPanelTitle: {
    id: 'vsku.esignPrepare.w9.previewPanelTitle',
    description: 'Title shown on the fields preview',
    defaultMessage: 'Taxpayer Identification (Form W-9)',
  },
});
